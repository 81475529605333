<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.00001 7.29291L9.00001 10.2929L12 7.29291L12.7071 8.00001L9.00001 11.7071L5.29291 8.00001L6.00001 7.29291Z"
      fill="#232323"
    />
  </svg>
</template>
