import debounce from 'lodash.debounce'
import { DataStorage } from '@/global'
import Block from '@/models/editor/Block'
import EditorPage from '@/models/editor/EditorPage'
import { StorefrontPageOptions } from '@/models/editor/StorefrontPage'
import { normalizePath } from '@/util/normalize'
import ApiService from './ApiService'

const shortIdMap: Record<string, number> = {}
let lastShortBlockId = 0

export default {
  async getPages(storeId: string) {
    let pages: EditorPage[] = []
    try {
      const { data } = await ApiService.get(`/${storeId}/pages`, {
        headers: { 'store-id': storeId },
      })
      if (Array.isArray(data)) pages = data
    } catch {}

    for (const page of pages) {
      for (const block of page.blocks) {
        if (!shortIdMap[block.id]) shortIdMap[block.id] = ++lastShortBlockId
        block.shortId = shortIdMap[block.id]
      }
    }

    return pages
  },

  async getData(storeId: string) {
    let data: DataStorage = { products: [], collections: [] }
    try {
      const result = await ApiService.get(`/${storeId}/data`, {
        headers: { 'store-id': storeId },
      })

      if (result && result.data.products && result.data.collections) data = result.data
    } catch {}

    normalizeProductsImages(data.products)
    normalizeCollectionsImages(data.collections)
    return data
  },

  async addBlockOnPage(
    storeId: string,
    pageId: string,
    prevBlockId: string | null,
    blockSid: string
  ): Promise<EditorPage> {
    const response = await ApiService.post(
      `/${storeId}/pages/${pageId}/blocks`,
      {
        block: {
          content: {},
          options: {},
          page_id: pageId,
          sid: blockSid,
          prev_block_id: prevBlockId,
        },
      },
      {
        headers: { 'store-id': storeId },
      }
    )
    return response.data
  },

  async deleteBlockFromPage(storeId: string, pageId: string, blockId: string): Promise<EditorPage> {
    const response = await ApiService.delete(`/${storeId}/pages/${pageId}/blocks/${blockId}`, {
      headers: { 'store-id': storeId },
    })
    return response.data
  },

  requestBlockUpdate: debounce(async (storeId: string, pageId: string, block: Block) => {
    await ApiService.put(
      `/${storeId}/pages/${pageId}/blocks/${block.id}`,
      { block },
      {
        headers: { 'store-id': storeId },
      }
    )
  }, 1000),

  async updateBlockImmediate(storeId: string, pageId: string, block: Block) {
    await ApiService.delete(`/${storeId}/pages/${pageId}/blocks/${block.id}`, {
      headers: { 'store-id': storeId },
    })
    await ApiService.post(
      `/${storeId}/pages/${pageId}/blocks`,
      {
        block: {
          content: {},
          options: {},
          page_id: pageId,
          sid: block.sid,
          prev_block_id: block.prev_block_id,
        },
      },
      {
        headers: { 'store-id': storeId },
      }
    )
  },

  async addPage(
    storeId: string,
    name: string,
    path: string | null,
    layout_id: string | null,
    isLayout = false,
    options: StorefrontPageOptions = {}
  ): Promise<EditorPage> {
    const normalizedPath = name.toLowerCase() === 'home' ? '' : normalizePath(path)
    path = isLayout ? '' : normalizedPath.startsWith('/') ? normalizedPath : '/' + normalizedPath
    const type = isLayout ? 'layout' : 'page'
    if (isLayout) path = null

    const response = await ApiService.post(
      `/${storeId}/pages`,
      {
        page: {
          name,
          path,
          layout_id,
          type,
          options,
        },
      },
      {
        headers: { 'store-id': storeId },
      }
    )

    return response.data
  },

  async updatePage(storeId: string, pageId: string, payload: Partial<EditorPage>): Promise<EditorPage> {
    const response = await ApiService.put(
      `/${storeId}/pages/${pageId}`,
      { page: payload },
      { headers: { 'store-id': storeId } }
    )

    return response.data
  },

  async deletePage(storeId: string, pageId: string): Promise<any> {
    const response = await ApiService.delete(`/${storeId}/pages/${pageId}`, { headers: { 'store-id': storeId } })
    return response.data
  },
}

const normalizeProductsImages = (products: DataStorage['products']) => {
  products.forEach((p) => {
    if (p.images) p.images = p.images.map(addImageUrls)
  })
}

const normalizeCollectionsImages = (collections: DataStorage['collections']) => {
  collections.forEach((c: any) => {
    if (c.image) c.image = addImageUrls(c.image)
  })
}

const addImageUrls = (img: any) => {
  const rest = '.' + img.urlMaxRes.split('.').pop()
  img.url = img.urlMaxRes.replace(rest, '_480x' + rest)
  return img
}
