<script>
export default {
  name: 'WishlistIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66634 3.11108C3.37787 3.11108 2.33301 4.07755 2.33301 5.26994C2.33301 6.23248 2.74134 8.51695 6.76074 10.8263C6.83274 10.8672 6.9154 10.8889 6.99967 10.8889C7.08395 10.8889 7.16661 10.8672 7.23861 10.8263C11.258 8.51695 11.6663 6.23248 11.6663 5.26994C11.6663 4.07755 10.6215 3.11108 9.33301 3.11108C8.04454 3.11108 6.99967 4.41948 6.99967 4.41948C6.99967 4.41948 5.95481 3.11108 4.66634 3.11108Z"
      :stroke="`var(--${color})`"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
