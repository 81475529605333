<script>
export default {
  name: 'CounterIcon02',
  props: {
    color: String,
    colorCounter: String,
  },
}
</script>

<template>
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.07873 3.63H0.25V12.75H9.25V3.63H7.42127C7.36569 3.14686 7.2228 2.57314 6.93587 2.06304C6.5314 1.34398 5.8341 0.75 4.75 0.75C3.6659 0.75 2.9686 1.34398 2.56413 2.06304C2.2772 2.57314 2.13431 3.14686 2.07873 3.63ZM2.98733 3.63H6.51267C6.46137 3.2832 6.35321 2.89311 6.16413 2.55696C5.8936 2.07602 5.4659 1.71 4.75 1.71C4.0341 1.71 3.6064 2.07602 3.33587 2.55696C3.14679 2.89311 3.03863 3.2832 2.98733 3.63ZM1.15 4.59V11.79H8.35V4.59H1.15Z"
      :fill="`var(--${color})`"
    />
    <path
      d="M13.7503 10.7474C13.7503 13.148 11.8005 15.0941 9.39531 15.0941C6.99015 15.0941 5.04037 13.148 5.04037 10.7474C5.04037 8.34678 6.99015 6.4007 9.39531 6.4007C11.8005 6.4007 13.7503 8.34678 13.7503 10.7474Z"
      :fill="`var(--${color})`"
    />
    <path
      d="M7.77255 12.9207H10.9945V12.3774H8.61292V12.3604L9.7697 11.1685C10.6679 10.2482 10.9129 9.84407 10.9129
    9.30752V9.30073C10.9129 8.53327 10.2664 7.939 9.38183 7.939C8.47342 7.939 7.74193 8.56723 7.74193
    9.41619V9.43657H8.33393V9.41959C8.33393 8.89323 8.78643 8.47214 9.39204 8.47214C9.9228 8.47214 10.2971 8.8321
    10.3005 9.33129V9.33809C10.3005 9.74559 10.1508 10.0105 9.49071 10.6998L7.77255 12.4996V12.9207Z"
      :fill="`var(--${colorCounter})`"
    />
  </svg>
</template>
