<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4487 7.83892C12.4487 10.3848 10.3848 12.4486 7.83896 12.4486C5.29309 12.4486 3.22926 10.3848 3.22926 7.83892C3.22926 5.29307 5.29309 3.22925 7.83896 3.22925C10.3848 3.22925 12.4487 5.29307 12.4487 7.83892ZM11.5103 12.3795C10.5073 13.1915 9.22993 13.6778 7.83896 13.6778C4.61419 13.6778 2 11.0637 2 7.83892C2 4.61417 4.61419 2 7.83896 2C11.0637 2 13.6779 4.61417 13.6779 7.83892C13.6779 9.22993 13.1915 10.5073 12.3795 11.5103L16 15.1308L15.1308 16L11.5103 12.3795Z"
      fill="black"
    />
  </svg>
</template>
