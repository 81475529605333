import { computed } from 'vue'
import ColorsService from '@/api/ColorsService'
import ColorSet from '@/models/ColorSet'
import useStores from '../globalState/useStores'

export default function useColorsService() {
  const storesState = useStores()
  const storeId = computed(() => storesState.currentStoreId)

  return {
    getStoreColors: () => ColorsService.getStoreColors(storeId.value),
    setStoreColors: (colors: ColorSet) => ColorsService.setStoreColors(storeId.value, colors),
  }
}
