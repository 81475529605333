import { Component } from 'vue'
import AlignedGrid from './AlignedGrid.vue'
import Cart02 from './Cart02.vue'
import Cart03 from './Cart03.vue'
import Cart04 from './Cart04.vue'
import Cart05 from './Cart05.vue'
import Cart06 from './Cart06.vue'
import Counter01 from './Counter01.vue'
import Counter02 from './Counter02.vue'
import Counter03 from './Counter03.vue'
import DescriptionPositionBottom from './DescriptionPositionBottom.vue'
import DescriptionPositionLeft from './DescriptionPositionLeft.vue'
import DescriptionPositionRight from './DescriptionPositionRight.vue'
import DescriptionPositionTop from './DescriptionPositionTop.vue'
import Desktop from './Desktop.vue'
import DropdownIcon01 from './DropdownIcon01.vue'
import DropdownIcon02 from './DropdownIcon02.vue'
import HorizontalAlignCenter from './HorizontalAlignCenter.vue'
import HorizontalAlignLeft from './HorizontalAlignLeft.vue'
import HorizontalAlignRight from './HorizontalAlignRight.vue'
import HorizontalAlignSpaceBetween from './HorizontalAlignSpaceBetween.vue'
import ImageColumnsSingle from './ImageColumnsSingle.vue'
import ImageColumnsTwo from './ImageColumnsTwo.vue'
import ImageColumnsTwoWithHead from './ImageColumnsTwoWithHead.vue'
import InsideBottom from './InsideBottom.vue'
import InsideTop from './InsideTop.vue'
import Laptop from './Laptop.vue'
import LargeCardSize from './LargeCardSize.vue'
import LocaleIcon03 from './LocaleIcon03.vue'
import MediumCardSize from './MediumCardSize.vue'
import MenuIcon01 from './MenuIcon01.vue'
import MenuIcon02 from './MenuIcon02.vue'
import MenuIcon03 from './MenuIcon03.vue'
import Mobile from './Mobile.vue'
import OutsideBottom from './OutsideBottom.vue'
import OutsideTop from './OutsideTop.vue'
import ProductOptions01 from './ProductOptions01.vue'
import ProductOptions02 from './ProductOptions02.vue'
import ProductOptions03 from './ProductOptions03.vue'
import ShiftedGrid from './ShiftedGrid.vue'
import Tablet from './Tablet.vue'
import WidthFull from './WidthFull.vue'
import WidthNarrow from './WidthNarrow.vue'
import WidthNormal from './WidthNormal.vue'
import WidthWide from './WidthWide.vue'
import WishlistIcon02 from './WishlistIcon02.vue'
import WishlistIcon03 from './WishlistIcon03.vue'
import ZoomCursor02 from './ZoomCursor02.vue'
import ZoomCursor03 from './ZoomCursor03.vue'
import ZoomCursor04 from './ZoomCursor04.vue'

export default {
  AlignedGrid,
  ShiftedGrid,
  MediumCardSize,
  LargeCardSize,
  InsideBottom,
  InsideTop,
  OutsideBottom,
  OutsideTop,
  WidthFull,
  WidthWide,
  WidthNormal,
  WidthNarrow,
  HorizontalAlignSpaceBetween,
  HorizontalAlignLeft,
  HorizontalAlignCenter,
  HorizontalAlignRight,
  DescriptionPositionLeft,
  DescriptionPositionRight,
  DescriptionPositionTop,
  DescriptionPositionBottom,
  DropdownIcon01,
  DropdownIcon02,
  ZoomCursor02,
  ZoomCursor03,
  ZoomCursor04,
  WishlistIcon02,
  WishlistIcon03,
  LocaleIcon03,
  MenuIcon01,
  MenuIcon02,
  MenuIcon03,
  Desktop,
  Laptop,
  Tablet,
  Mobile,
  ProductOptions01,
  ProductOptions02,
  ProductOptions03,
  ImageColumnsSingle,
  ImageColumnsTwo,
  ImageColumnsTwoWithHead,
  Cart02,
  Cart03,
  Cart04,
  Cart05,
  Cart06,
  Counter01,
  Counter02,
  Counter03,
} as Record<string, Component>
