<script>
export default {
  name: 'FlexCollectionIcon',
  props: {
    color: String,
    size: {
      type: String,
      default: 'medium',
      validator: (size) => ['small', 'medium', 'large'].includes(size),
    },
  },
}
</script>

<template>
  <svg
    :class="`flex-collection-icon flex-collection-icon--size-${size}`"
    style="pointer-events: none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66536 14.6673H5.33203C3.85927 14.6673 2.66536 15.8612 2.66536 17.334V18.6673C2.66536 20.1401 3.85927 21.334 5.33203 21.334H6.66536C8.13812 21.334 9.33203 20.1401 9.33203 18.6673V17.334C9.33203 15.8612 8.13812 14.6673 6.66536 14.6673ZM5.33203 13.334C3.12289 13.334 1.33203 15.1248 1.33203 17.334V18.6673C1.33203 20.8765 3.12289 22.6673 5.33203 22.6673H6.66536C8.8745 22.6673 10.6654 20.8765 10.6654 18.6673V17.334C10.6654 15.1248 8.8745 13.334 6.66536 13.334H5.33203Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66536 2.66732H5.33203C3.85927 2.66732 2.66536 3.86123 2.66536 5.33398V6.66732C2.66536 8.14008 3.85927 9.33398 5.33203 9.33398H6.66536C8.13812 9.33398 9.33203 8.14008 9.33203 6.66732V5.33398C9.33203 3.86123 8.13812 2.66732 6.66536 2.66732ZM5.33203 1.33398C3.12289 1.33398 1.33203 3.12485 1.33203 5.33398V6.66732C1.33203 8.87646 3.12289 10.6673 5.33203 10.6673H6.66536C8.8745 10.6673 10.6654 8.87646 10.6654 6.66732V5.33398C10.6654 3.12485 8.8745 1.33398 6.66536 1.33398H5.33203Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.6654 14.6673H17.332C15.8593 14.6673 14.6654 15.8612 14.6654 17.334V18.6673C14.6654 20.1401 15.8593 21.334 17.332 21.334H18.6654C20.1381 21.334 21.332 20.1401 21.332 18.6673V17.334C21.332 15.8612 20.1381 14.6673 18.6654 14.6673ZM17.332 13.334C15.1229 13.334 13.332 15.1248 13.332 17.334V18.6673C13.332 20.8765 15.1229 22.6673 17.332 22.6673H18.6654C20.8745 22.6673 22.6654 20.8765 22.6654 18.6673V17.334C22.6654 15.1248 20.8745 13.334 18.6654 13.334H17.332Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.6654 2.66732H17.332C15.8593 2.66732 14.6654 3.86123 14.6654 5.33398V6.66732C14.6654 8.14008 15.8593 9.33398 17.332 9.33398H18.6654C20.1381 9.33398 21.332 8.14008 21.332 6.66732V5.33398C21.332 3.86123 20.1381 2.66732 18.6654 2.66732ZM17.332 1.33398C15.1229 1.33398 13.332 3.12485 13.332 5.33398V6.66732C13.332 8.87646 15.1229 10.6673 17.332 10.6673H18.6654C20.8745 10.6673 22.6654 8.87646 22.6654 6.66732V5.33398C22.6654 3.12485 20.8745 1.33398 18.6654 1.33398H17.332Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>

<style lang="scss">
.flex-text-icon--size-small {
  width: 18px;
  height: 18px;
}

.flex-text-icon--size-medium {
  width: 24px;
  height: 24px;
}

.flex-text-icon--size-large {
  width: 32px;
  height: 32px;
}
</style>
