import { computed } from 'vue'
import FontsService, { IUploadFontPayload } from '@/api/FontsService'
import Font from '@/models/fonts/Font'
import Typography from '@/models/fonts/Typography'
import useStores from '../globalState/useStores'

export default function useFontsService() {
  const storesState = useStores()
  const storeId = computed(() => storesState.currentStoreId)

  return {
    getStoreFonts: (query: string) => FontsService.getStoreFonts(storeId.value, query),
    getStoreTypography: () => FontsService.getStoreTypography(storeId.value),
    upload: (data: IUploadFontPayload) => FontsService.upload(storeId.value, data),
    update: (data: Font) => FontsService.update(storeId.value, data),
    delete: (data: Font) => FontsService.delete(storeId.value, data),
    updateStoreTypography: (newTypography: Typography) =>
      FontsService.updateStoreTypography(storeId.value, newTypography),
  }
}
