<script>
export default {
  name: 'CartIcon04',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 1.75L12.0909 0H1.90909L0 1.75L1.90909 10.5H12.0909L14 1.75ZM12.8185 1.45833L11.864 0.583333H2.136L1.18145 1.45833H12.8185ZM0.660693 2.04167L2.37887 9.91667H11.6211L13.3393 2.04167H0.660693Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.37223 5.83331H8.62741L9.87806 3.64469L9.37158 3.35527L8.28889 5.24998H5.71075L4.62806 3.35527L4.12158 3.64469L5.37223 5.83331Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
