const FontWeight: { [key: string]: string } = {
  Thin: 'Thin',
  ExtraLight: 'Extra-light',
  Light: 'Light',
  Regular: 'Regular',
  Medium: 'Medium',
  SemiBold: 'Semi-bold',
  Bold: 'Bold',
  ExtraBold: 'Extra-bold',
  Black: 'Black',
}

export default FontWeight
