<script>
export default {
  name: 'OverlayIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.83301 3.33325C2.83301 3.05711 3.05686 2.83325 3.33301 2.83325H9.92531C10.3112 2.83325 10.5516 3.25186 10.3572 3.58519L6.07942 10.9185C5.98981 11.0721 5.82536 11.1666 5.64753 11.1666H3.33301C3.05687 11.1666 2.83301 10.9427 2.83301 10.6666V3.33325Z"
      fill="#EAEAEA"
      :stroke="`var(--${color})`"
    />
  </svg>
</template>
