import Font from '@/models/fonts/Font'
import StoreFonts from '@/models/fonts/StoreFonts'
import Typography from '@/models/fonts/Typography'
import TypographyStyle from '@/models/fonts/TypographyStyle'

const FontUtils = {
  getAnyFont(storeFonts: StoreFonts): Font | null {
    if (!storeFonts) return null
    if (storeFonts.active_fonts.length) return storeFonts.active_fonts[0]
    if (storeFonts.available_fonts.length) return storeFonts.available_fonts[0]
    return null
  },

  generateNewTypographyStyle(storeFonts: StoreFonts, typography: Typography): TypographyStyle | null {
    const baseName = 'New style'
    const baseHandle = 'ts'
    let name = baseName
    let handle = baseHandle
    let counter = 1

    while (typography.styles.some((item) => item.name === name)) {
      name = `${baseName} ${counter}`
      counter++
    }

    while (typography.styles.some((item) => item.handle === handle)) {
      handle = `${baseHandle}-${counter}`
      counter++
    }

    const font = FontUtils.getAnyFont(storeFonts)
    if (!font) return null

    const newTypographyStyle: TypographyStyle = {
      font,
      settings: {
        sizes: {
          desktop: '16px',
          tablet: '16px',
          mobile: '16px',
        },
        style: 'normal',
        weight: 400,
        lineHeight: 110,
      },
      name,
      handle,
      type: 'custom',
    }

    return newTypographyStyle
  },

  generateBasicTypographyStyles(): TypographyStyle[] {
    const font: Font = {
      family: 'Jost',
      scripts: ['cyrillic', 'latin', 'latin-ext'],
      source: 'google',
      variants: [
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zPtBhPNqw79Ij1E865zBUv7myjJAVGPokMmuHL.ttf',
          style: 'normal',
          weight: 100,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zPtBhPNqw79Ij1E865zBUv7mwjJQVGPokMmuHL.ttf',
          style: 'normal',
          weight: 200,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zPtBhPNqw79Ij1E865zBUv7mz9JQVGPokMmuHL.ttf',
          style: 'normal',
          weight: 300,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zPtBhPNqw79Ij1E865zBUv7myjJQVGPokMmuHL.ttf',
          style: 'normal',
          weight: 400,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zPtBhPNqw79Ij1E865zBUv7myRJQVGPokMmuHL.ttf',
          style: 'normal',
          weight: 500,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zPtBhPNqw79Ij1E865zBUv7mx9IgVGPokMmuHL.ttf',
          style: 'normal',
          weight: 600,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zPtBhPNqw79Ij1E865zBUv7mxEIgVGPokMmuHL.ttf',
          style: 'normal',
          weight: 700,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zPtBhPNqw79Ij1E865zBUv7mwjIgVGPokMmuHL.ttf',
          style: 'normal',
          weight: 800,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zPtBhPNqw79Ij1E865zBUv7mwKIgVGPokMmuHL.ttf',
          style: 'normal',
          weight: 900,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zJtBhPNqw73oHH7BbQp4-B6XlrZu0ENI0un_HLMEo.ttf',
          style: 'italic',
          weight: 100,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zJtBhPNqw73oHH7BbQp4-B6XlrZm0FNI0un_HLMEo.ttf',
          style: 'italic',
          weight: 200,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zJtBhPNqw73oHH7BbQp4-B6XlrZrMFNI0un_HLMEo.ttf',
          style: 'italic',
          weight: 300,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zJtBhPNqw73oHH7BbQp4-B6XlrZu0FNI0un_HLMEo.ttf',
          style: 'italic',
          weight: 400,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zJtBhPNqw73oHH7BbQp4-B6XlrZt8FNI0un_HLMEo.ttf',
          style: 'italic',
          weight: 500,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zJtBhPNqw73oHH7BbQp4-B6XlrZjMCNI0un_HLMEo.ttf',
          style: 'italic',
          weight: 600,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zJtBhPNqw73oHH7BbQp4-B6XlrZgoCNI0un_HLMEo.ttf',
          style: 'italic',
          weight: 700,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zJtBhPNqw73oHH7BbQp4-B6XlrZm0CNI0un_HLMEo.ttf',
          style: 'italic',
          weight: 800,
        },
        {
          file_url: '//fonts.gstatic.com/s/jost/v13/92zJtBhPNqw73oHH7BbQp4-B6XlrZkQCNI0un_HLMEo.ttf',
          style: 'italic',
          weight: 900,
        },
      ],
    }

    return [
      {
        font,
        handle: 'font-header',
        name: 'Header',
        settings: {
          sizes: { desktop: '32px', mobile: '24px', tablet: '32px' },
          style: 'normal',
          weight: 400,
          lineHeight: 110,
        },
        type: 'basic',
      },
      {
        font,
        handle: 'font-subheader',
        name: 'Subheader',
        settings: {
          sizes: { desktop: '18px', mobile: '18px', tablet: '18px' },
          style: 'normal',
          weight: 400,
          lineHeight: 110,
        },
        type: 'basic',
      },
      {
        font,
        handle: 'font-body',
        name: 'Body',
        settings: {
          sizes: { desktop: '14px', mobile: '14px', tablet: '14px' },
          style: 'normal',
          weight: 400,
          lineHeight: 110,
        },
        type: 'basic',
      },
      {
        font,
        handle: 'font-ui',
        name: 'UI',
        settings: {
          sizes: { desktop: '12px', mobile: '12px', tablet: '12px' },
          style: 'normal',
          weight: 400,
          lineHeight: 110,
        },
        type: 'basic',
      },
    ]
  },
}

export default FontUtils
