<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 9.5C1 9.22386 1.22386 9 1.5 9H16.5C16.7761 9 17 9.22386 17 9.5V12.5C17 12.7761 16.7761 13 16.5 13H1.5C1.22386 13 1 12.7761 1 12.5V9.5Z"
      fill="#666666"
    />
    <path
      d="M1 4.5C1 4.22386 1.22386 4 1.5 4H4.5C4.77614 4 5 4.22386 5 4.5V7.5C5 7.77614 4.77614 8 4.5 8H1.5C1.22386 8 1 7.77614 1 7.5V4.5Z"
      fill="#308FFF"
    />
  </svg>
</template>
