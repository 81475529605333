import createDirective from './createDirective/createDirective'

export const vClickOutsideDropdown = createDirective('dropdowns')

export const vClickOutsideSubDropdown = createDirective('subDropdowns')
export const vClickOutsideModal = createDirective('modals')
const storeContainer = (document.querySelector('[data-store-container]') as HTMLElement) ?? document
export const vClickOutsideBlockOverlay = createDirective('overlay', storeContainer)
export const vClickOutsideContextElement = createDirective('contextElement', storeContainer, 'mousedown')
export const vClickOutsideContextDropdown = createDirective('contextDropdown', storeContainer)
