import getElementHorizontalAlignment from './getElementHorizontalAlignment'

export default function getElementAlignmentAndOffset(
  flexBlockWidth: number,
  flexElementX: number,
  flexElementY: number,
  flexElementWidth: number
) {
  const distanceToLeftEdge = flexElementX
  const distanceToCenter = Math.abs(flexBlockWidth / 2 - (flexElementX + flexElementWidth / 2))
  const distanceToRightEdge = flexBlockWidth - flexElementX - flexElementWidth

  const horizontalAlignment = getElementHorizontalAlignment(distanceToLeftEdge, distanceToCenter, distanceToRightEdge)

  let horizontalOffset = '0'
  const verticalOffset = flexElementY + 'px'

  if (horizontalAlignment === 'left') {
    if (distanceToLeftEdge > 200) {
      horizontalOffset = ((distanceToLeftEdge / flexBlockWidth) * 100).toFixed(2) + '%'
    } else {
      horizontalOffset = distanceToLeftEdge + 'px'
    }
  }

  if (horizontalAlignment === 'center') {
    if (distanceToCenter > 100) {
      horizontalOffset =
        (((flexElementX + flexElementWidth / 2 - flexBlockWidth / 2) / flexBlockWidth) * 100).toFixed(2) + '%'
    } else {
      horizontalOffset = flexElementX + flexElementWidth / 2 - flexBlockWidth / 2 + 'px'
    }
  }

  if (horizontalAlignment === 'right') {
    if (distanceToRightEdge > 200) {
      horizontalOffset = ((-distanceToRightEdge / flexBlockWidth) * 100).toFixed(2) + '%'
    } else {
      horizontalOffset = -distanceToRightEdge + 'px'
    }
  }

  return {
    horizontalAlignment,
    horizontalOffset,
    verticalOffset,
  }
}
