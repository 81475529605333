import { reactive, ref } from 'vue'

export default function useHoverStates() {
  const isHovered = ref(false)
  const onMouseEnter = () => (isHovered.value = true)
  const onMouseLeave = () => (isHovered.value = false)

  return reactive({
    isHovered,
    onMouseEnter,
    onMouseLeave,
  })
}
