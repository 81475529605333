<script>
export default {
  name: 'InsideTopIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1938_4922)">
      <path
        d="M1.36109 6.19898C1.56051 6.19898 1.72217 6.03731 1.72217 5.83789C1.72217 5.63847 1.56051 5.47681 1.36109 5.47681C1.16166 5.47681 1 5.63847 1 5.83789C1 6.03731 1.16166 6.19898 1.36109 6.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M2.59253 7.43787C2.79195 7.43787 2.95362 7.27621 2.95362 7.07678C2.95362 6.87736 2.79195 6.7157 2.59253 6.7157C2.39311 6.7157 2.23145 6.87736 2.23145 7.07678C2.23145 7.27621 2.39311 7.43787 2.59253 7.43787Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M1.36109 8.67371C1.56051 8.67371 1.72217 8.51205 1.72217 8.31262C1.72217 8.1132 1.56051 7.95154 1.36109 7.95154C1.16166 7.95154 1 8.1132 1 8.31262C1 8.51205 1.16166 8.67371 1.36109 8.67371Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M2.59253 9.9126C2.79195 9.9126 2.95362 9.75094 2.95362 9.55151C2.95362 9.35209 2.79195 9.19043 2.59253 9.19043C2.39311 9.19043 2.23145 9.35209 2.23145 9.55151C2.23145 9.75094 2.39311 9.9126 2.59253 9.9126Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M1.36109 11.1506C1.56051 11.1506 1.72217 10.989 1.72217 10.7896C1.72217 10.5901 1.56051 10.4285 1.36109 10.4285C1.16166 10.4285 1 10.5901 1 10.7896C1 10.989 1.16166 11.1506 1.36109 11.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M2.59253 12.3895C2.79195 12.3895 2.95362 12.2279 2.95362 12.0284C2.95362 11.829 2.79195 11.6674 2.59253 11.6674C2.39311 11.6674 2.23145 11.829 2.23145 12.0284C2.23145 12.2279 2.39311 12.3895 2.59253 12.3895Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M1.36109 13.6274C1.56051 13.6274 1.72217 13.4658 1.72217 13.2664C1.72217 13.0669 1.56051 12.9053 1.36109 12.9053C1.16166 12.9053 1 13.0669 1 13.2664C1 13.4658 1.16166 13.6274 1.36109 13.6274Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M3.84107 3.72217C4.04049 3.72217 4.20215 3.56051 4.20215 3.36109C4.20215 3.16166 4.04049 3 3.84107 3C3.64164 3 3.47998 3.16166 3.47998 3.36109C3.47998 3.56051 3.64164 3.72217 3.84107 3.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.07251 4.96106C5.27193 4.96106 5.4336 4.7994 5.4336 4.59998C5.4336 4.40056 5.27193 4.23889 5.07251 4.23889C4.87309 4.23889 4.71143 4.40056 4.71143 4.59998C4.71143 4.7994 4.87309 4.96106 5.07251 4.96106Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M1.36109 3.72217C1.56051 3.72217 1.72217 3.56051 1.72217 3.36109C1.72217 3.16166 1.56051 3 1.36109 3C1.16166 3 1 3.16166 1 3.36109C1 3.56051 1.16166 3.72217 1.36109 3.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M2.59253 4.96106C2.79195 4.96106 2.95362 4.7994 2.95362 4.59998C2.95362 4.40056 2.79195 4.23889 2.59253 4.23889C2.39311 4.23889 2.23145 4.40056 2.23145 4.59998C2.23145 4.7994 2.39311 4.96106 2.59253 4.96106Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M3.84107 6.19898C4.04049 6.19898 4.20215 6.03731 4.20215 5.83789C4.20215 5.63847 4.04049 5.47681 3.84107 5.47681C3.64164 5.47681 3.47998 5.63847 3.47998 5.83789C3.47998 6.03731 3.64164 6.19898 3.84107 6.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.07251 7.43787C5.27193 7.43787 5.4336 7.27621 5.4336 7.07678C5.4336 6.87736 5.27193 6.7157 5.07251 6.7157C4.87309 6.7157 4.71143 6.87736 4.71143 7.07678C4.71143 7.27621 4.87309 7.43787 5.07251 7.43787Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M3.84107 8.67371C4.04049 8.67371 4.20215 8.51205 4.20215 8.31262C4.20215 8.1132 4.04049 7.95154 3.84107 7.95154C3.64164 7.95154 3.47998 8.1132 3.47998 8.31262C3.47998 8.51205 3.64164 8.67371 3.84107 8.67371Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.07251 9.9126C5.27193 9.9126 5.4336 9.75094 5.4336 9.55151C5.4336 9.35209 5.27193 9.19043 5.07251 9.19043C4.87309 9.19043 4.71143 9.35209 4.71143 9.55151C4.71143 9.75094 4.87309 9.9126 5.07251 9.9126Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M3.84107 11.1506C4.04049 11.1506 4.20215 10.989 4.20215 10.7896C4.20215 10.5901 4.04049 10.4285 3.84107 10.4285C3.64164 10.4285 3.47998 10.5901 3.47998 10.7896C3.47998 10.989 3.64164 11.1506 3.84107 11.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.07251 12.3895C5.27193 12.3895 5.4336 12.2279 5.4336 12.0284C5.4336 11.829 5.27193 11.6674 5.07251 11.6674C4.87309 11.6674 4.71143 11.829 4.71143 12.0284C4.71143 12.2279 4.87309 12.3895 5.07251 12.3895Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M3.84107 13.6274C4.04049 13.6274 4.20215 13.4658 4.20215 13.2664C4.20215 13.0669 4.04049 12.9053 3.84107 12.9053C3.64164 12.9053 3.47998 13.0669 3.47998 13.2664C3.47998 13.4658 3.64164 13.6274 3.84107 13.6274Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.31275 3.72217C6.51217 3.72217 6.67383 3.56051 6.67383 3.36109C6.67383 3.16166 6.51217 3 6.31275 3C6.11332 3 5.95166 3.16166 5.95166 3.36109C5.95166 3.56051 6.11332 3.72217 6.31275 3.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M7.54419 4.96106C7.74361 4.96106 7.90528 4.7994 7.90528 4.59998C7.90528 4.40056 7.74361 4.23889 7.54419 4.23889C7.34477 4.23889 7.18311 4.40056 7.18311 4.59998C7.18311 4.7994 7.34477 4.96106 7.54419 4.96106Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.31275 6.19898C6.51217 6.19898 6.67383 6.03731 6.67383 5.83789C6.67383 5.63847 6.51217 5.47681 6.31275 5.47681C6.11332 5.47681 5.95166 5.63847 5.95166 5.83789C5.95166 6.03731 6.11332 6.19898 6.31275 6.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M7.54419 7.43787C7.74361 7.43787 7.90528 7.27621 7.90528 7.07678C7.90528 6.87736 7.74361 6.7157 7.54419 6.7157C7.34477 6.7157 7.18311 6.87736 7.18311 7.07678C7.18311 7.27621 7.34477 7.43787 7.54419 7.43787Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.31275 8.67371C6.51217 8.67371 6.67383 8.51205 6.67383 8.31262C6.67383 8.1132 6.51217 7.95154 6.31275 7.95154C6.11332 7.95154 5.95166 8.1132 5.95166 8.31262C5.95166 8.51205 6.11332 8.67371 6.31275 8.67371Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M7.54419 9.9126C7.74361 9.9126 7.90528 9.75094 7.90528 9.55151C7.90528 9.35209 7.74361 9.19043 7.54419 9.19043C7.34477 9.19043 7.18311 9.35209 7.18311 9.55151C7.18311 9.75094 7.34477 9.9126 7.54419 9.9126Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.31275 11.1506C6.51217 11.1506 6.67383 10.989 6.67383 10.7896C6.67383 10.5901 6.51217 10.4285 6.31275 10.4285C6.11332 10.4285 5.95166 10.5901 5.95166 10.7896C5.95166 10.989 6.11332 11.1506 6.31275 11.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M7.54419 12.3895C7.74361 12.3895 7.90528 12.2279 7.90528 12.0284C7.90528 11.829 7.74361 11.6674 7.54419 11.6674C7.34477 11.6674 7.18311 11.829 7.18311 12.0284C7.18311 12.2279 7.34477 12.3895 7.54419 12.3895Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.31275 13.6274C6.51217 13.6274 6.67383 13.4658 6.67383 13.2664C6.67383 13.0669 6.51217 12.9053 6.31275 12.9053C6.11332 12.9053 5.95166 13.0669 5.95166 13.2664C5.95166 13.4658 6.11332 13.6274 6.31275 13.6274Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M8.79273 3.72217C8.99215 3.72217 9.15381 3.56051 9.15381 3.36109C9.15381 3.16166 8.99215 3 8.79273 3C8.5933 3 8.43164 3.16166 8.43164 3.36109C8.43164 3.56051 8.5933 3.72217 8.79273 3.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.0242 4.96106C10.2236 4.96106 10.3853 4.7994 10.3853 4.59998C10.3853 4.40056 10.2236 4.23889 10.0242 4.23889C9.82475 4.23889 9.66309 4.40056 9.66309 4.59998C9.66309 4.7994 9.82475 4.96106 10.0242 4.96106Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M8.79273 6.19898C8.99215 6.19898 9.15381 6.03731 9.15381 5.83789C9.15381 5.63847 8.99215 5.47681 8.79273 5.47681C8.5933 5.47681 8.43164 5.63847 8.43164 5.83789C8.43164 6.03731 8.5933 6.19898 8.79273 6.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.0242 7.43787C10.2236 7.43787 10.3853 7.27621 10.3853 7.07678C10.3853 6.87736 10.2236 6.7157 10.0242 6.7157C9.82475 6.7157 9.66309 6.87736 9.66309 7.07678C9.66309 7.27621 9.82475 7.43787 10.0242 7.43787Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M8.79273 8.67371C8.99215 8.67371 9.15381 8.51205 9.15381 8.31262C9.15381 8.1132 8.99215 7.95154 8.79273 7.95154C8.5933 7.95154 8.43164 8.1132 8.43164 8.31262C8.43164 8.51205 8.5933 8.67371 8.79273 8.67371Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.0242 9.9126C10.2236 9.9126 10.3853 9.75094 10.3853 9.55151C10.3853 9.35209 10.2236 9.19043 10.0242 9.19043C9.82475 9.19043 9.66309 9.35209 9.66309 9.55151C9.66309 9.75094 9.82475 9.9126 10.0242 9.9126Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M8.79273 11.1506C8.99215 11.1506 9.15381 10.989 9.15381 10.7896C9.15381 10.5901 8.99215 10.4285 8.79273 10.4285C8.5933 10.4285 8.43164 10.5901 8.43164 10.7896C8.43164 10.989 8.5933 11.1506 8.79273 11.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.0242 12.3895C10.2236 12.3895 10.3853 12.2279 10.3853 12.0284C10.3853 11.829 10.2236 11.6674 10.0242 11.6674C9.82475 11.6674 9.66309 11.829 9.66309 12.0284C9.66309 12.2279 9.82475 12.3895 10.0242 12.3895Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M8.79273 13.6274C8.99215 13.6274 9.15381 13.4658 9.15381 13.2664C9.15381 13.0669 8.99215 12.9053 8.79273 12.9053C8.5933 12.9053 8.43164 13.0669 8.43164 13.2664C8.43164 13.4658 8.5933 13.6274 8.79273 13.6274Z"
        :fill="`var(--${color})`"
      />
    </g>
    <rect x="0.5" y="2.5" width="9" height="11" rx="1.5" :stroke="`var(--${color})`" />
    <path
      d="M6 1C6 0.447715 6.44772 0 7 0C7.55228 0 8 0.447715 8 1V7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7V1Z"
      :fill="`var(--${color})`"
    />
    <defs>
      <clipPath id="clip0_1938_4922">
        <rect y="2" width="10" height="12" rx="2" :fill="`var(--${color})`" />
      </clipPath>
    </defs>
  </svg>
</template>
