<template>
  <button class="color-add-button">
    <div class="color-add-button__plus-container">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.45455 0.454545C5.45455 0.203507 5.25104 2.7093e-08 5 0C4.74896 0 4.54545 0.203507 4.54545 0.454546V4.54545H0.454545C0.203507 4.54545 0 4.74896 0 5C0 5.25104 0.203507 5.45455 0.454546 5.45455H4.54545V9.54545C4.54545 9.79649 4.74896 10 5 10C5.25104 10 5.45455 9.79649 5.45455 9.54545V5.45455H9.54545C9.79649 5.45455 10 5.25104 10 5C10 4.74896 9.79649 4.54545 9.54545 4.54545H5.45455V0.454545Z"
          fill="#222222"
        />
      </svg>
    </div>

    Add custom color
  </button>
</template>

<style lang="scss" scoped>
@import '@/css/variables';
@import '@/css/mixins';

.color-add-button {
  @include font-control-content;
  @include tertiary-button-bg-states;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 4px;
  cursor: pointer;
  border: none;
  border-radius: 7px;
}

.color-add-button__plus-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  background-color: $color-bg-control-tertiary;
  border-radius: 50%;
}
</style>
