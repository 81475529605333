<script>
export default {
  name: 'CartIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="5.83366" cy="10.4999" rx="1.16667" ry="1.16667" :stroke="`var(--${color})`" />
    <circle cx="9.72233" cy="10.4999" r="1.16667" :stroke="`var(--${color})`" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.55566 3.49992C1.55566 2.85559 2.078 2.33325 2.72233 2.33325H3.11122H4.15525H5.44455H9.75649C11.0962 2.33325 12.0571 3.62472 11.6721 4.90795L11.0055 7.13017C10.7517 7.97614 9.97304 8.55547 9.08983 8.55547H6.46595C5.58273 8.55547 4.80409 7.97614 4.5503 7.13017L3.81122 4.66659H2.72233C2.078 4.66659 1.55566 4.14425 1.55566 3.49992ZM4.62192 3.88881H2.72233C2.50755 3.88881 2.33344 3.7147 2.33344 3.49992C2.33344 3.28514 2.50755 3.11103 2.72233 3.11103H5.05566C5.2108 3.11103 5.34471 3.20187 5.40713 3.33325H9.75649C10.4264 3.33325 10.9068 3.97899 10.7143 4.6206L10.0477 6.84282C9.92076 7.26581 9.53143 7.55547 9.08983 7.55547H6.46595C6.02434 7.55547 5.63502 7.26581 5.50812 6.84282L4.62192 3.88881Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
