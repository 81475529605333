import { reactive, Ref, watch } from 'vue'

export default function usePositionTracer(element: Ref<HTMLElement | null>, ...updateTriggers: Ref<any>[]) {
  const tracedPosition = reactive({ top: 0, left: 0 })

  const updatePosition = (prevTop?: number, prevLeft?: number) => {
    if (!element.value) return

    const { top, left } = element.value.getBoundingClientRect()
    tracedPosition.top = top
    tracedPosition.left = left

    if (top !== prevTop || left !== prevLeft || left < 0) requestAnimationFrame(() => updatePosition(top, left))
  }

  watch(
    [element, ...updateTriggers],
    () => {
      requestAnimationFrame(() => updatePosition())
    },
    { immediate: true }
  )

  return tracedPosition
}
