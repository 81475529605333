<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.59091 8.59091V3H9.40909V8.59091H15V9.40909H9.40909V15H8.59091V9.40909H3V8.59091H8.59091Z"
      fill="black"
    />
  </svg>
</template>
