<script>
export default {
  name: 'DeleteButton',
  props: {
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium'].includes(value),
    },
  },
}
</script>

<template>
  <button class="delete-button" :class="['delete-button--size-' + size]">
    <svg
      v-if="size === 'small'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.98214 12.75H10.5179C10.5889 12.75 10.657 12.7204 10.7073 12.6676C10.7575 12.6149 10.7857 12.5433 10.7857 12.4688V7.40625C10.7857 7.33166 10.7575 7.26012 10.7073 7.20738C10.657 7.15463 10.5889 7.125 10.5179 7.125H9.98214C9.9111 7.125 9.84297 7.15463 9.79274 7.20738C9.74251 7.26012 9.71429 7.33166 9.71429 7.40625V12.4688C9.71429 12.5433 9.74251 12.6149 9.79274 12.6676C9.84297 12.7204 9.9111 12.75 9.98214 12.75ZM13.6429 4.875H11.8033L11.0444 3.54609C10.9492 3.37946 10.8144 3.24157 10.6534 3.14586C10.4923 3.05016 10.3103 2.99991 10.1252 3H7.87478C7.68976 2.99999 7.5079 3.05028 7.3469 3.14598C7.18589 3.24168 7.05123 3.37952 6.95603 3.54609L6.19665 4.875H4.35714C4.26242 4.875 4.17158 4.91451 4.1046 4.98484C4.03763 5.05516 4 5.15054 4 5.25V5.625C4 5.72446 4.03763 5.81984 4.1046 5.89017C4.17158 5.96049 4.26242 6 4.35714 6H4.71429V13.875C4.71429 14.1734 4.82717 14.4595 5.0281 14.6705C5.22903 14.8815 5.50155 15 5.78571 15H12.2143C12.4984 15 12.771 14.8815 12.9719 14.6705C13.1728 14.4595 13.2857 14.1734 13.2857 13.875V6H13.6429C13.7376 6 13.8284 5.96049 13.8954 5.89017C13.9624 5.81984 14 5.72446 14 5.625V5.25C14 5.15054 13.9624 5.05516 13.8954 4.98484C13.8284 4.91451 13.7376 4.875 13.6429 4.875ZM7.83571 4.1932C7.84765 4.17234 7.86454 4.15509 7.88473 4.14314C7.90492 4.13118 7.92772 4.12493 7.95089 4.125H10.0491C10.0722 4.12497 10.095 4.13124 10.1151 4.14319C10.1353 4.15515 10.1521 4.17238 10.1641 4.1932L10.5538 4.875H7.44621L7.83571 4.1932ZM12.2143 13.875H5.78571V6H12.2143V13.875ZM7.48214 12.75H8.01786C8.0889 12.75 8.15703 12.7204 8.20726 12.6676C8.25749 12.6149 8.28571 12.5433 8.28571 12.4688V7.40625C8.28571 7.33166 8.25749 7.26012 8.20726 7.20738C8.15703 7.15463 8.0889 7.125 8.01786 7.125H7.48214C7.4111 7.125 7.34297 7.15463 7.29274 7.20738C7.24251 7.26012 7.21429 7.33166 7.21429 7.40625V12.4688C7.21429 12.5433 7.24251 12.6149 7.29274 12.6676C7.34297 12.7204 7.4111 12.75 7.48214 12.75Z"
        class="delete-button__icon-path"
      />
    </svg>
  </button>
</template>

<style lang="scss" scoped>
@import '@/css/variables';
@import '@/css/mixins';

.delete-button {
  padding: 0;
  text-align: center;
  cursor: pointer;
  background-color: $color-bg-control-tertiary;
  border: none;
  border-radius: 7px;
  transition: background-color 0.15s;

  &:hover {
    background-color: $color-text-danger;

    .delete-button__icon-path {
      fill: $color-text-contrast;
    }
  }
}

.delete-button--size-small {
  width: 26px;
  height: 26px;
}

.delete-button__icon-path {
  fill: $color-text-tertiary;
  transition: fill 0.1s;
}
</style>
