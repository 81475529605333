import { reactive, ref, watch } from 'vue'
import { ViewportName } from '@/global'

const localStorageViewportSizeKey = 'atlr-admin-viewport-size'

function createViewportState() {
  const currentViewport = ref<ViewportName>(
    (localStorage.getItem(localStorageViewportSizeKey) as ViewportName) ?? 'none'
  )

  watch(currentViewport, (newValue) => {
    localStorage.setItem(localStorageViewportSizeKey, newValue)
  })

  return reactive({
    currentViewport,
  })
}

export default createViewportState()
