<script>
export default {
  name: 'CloseButton',
  props: {
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium'].includes(value),
    },
  },
}
</script>

<template>
  <button class="close-button" :class="['close-button--size-' + size]">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1209_15864)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.36223 2.53719C3.13442 2.30939 2.76507 2.30939 2.53727 2.53719C2.30946 2.765 2.30946 3.13435 2.53727 3.36215L6.24958 7.07446L2.53727 10.7868C2.30946 11.0146 2.30946 11.3839 2.53727 11.6117C2.76507 11.8395 3.13442 11.8395 3.36223 11.6117L7.07454 7.89942L10.7868 11.6117C11.0147 11.8395 11.384 11.8395 11.6118 11.6117C11.8396 11.3839 11.8396 11.0146 11.6118 10.7868L7.89949 7.07446L11.6118 3.36215C11.8396 3.13435 11.8396 2.765 11.6118 2.53719C11.384 2.30939 11.0147 2.30939 10.7868 2.53719L7.07454 6.24951L3.36223 2.53719Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_1209_15864">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </button>
</template>

<style lang="scss" scoped>
@import '@/css/variables';

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  cursor: pointer;
  background-color: $color-bg-control-tertiary;
  border: none;
  border-radius: 7px;
  transition: background-color 0.15s;

  &:hover {
    background-color: $color-bg-control-tertiary-hover;
  }

  &:active {
    background-color: $color-bg-control-tertiary-active;
  }
}

.close-button--size-small {
  width: 26px;
  height: 26px;
}

.close-button--size-medium {
  width: 32px;
  height: 32px;
}
</style>
