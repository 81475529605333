<script>
export default {
  name: 'CountrySelectorIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5342 3.20436C10.4233 3.11907 10.295 3.06289 10.1599 3.04036C10.0247 3.01784 9.88654 3.02962 9.75647 3.07473C9.41335 3.17765 9.05798 3.22656 8.70161 3.21992C8.17495 3.18943 7.65857 3.05216 7.18008 2.81547C6.58898 2.52388 5.94961 2.35998 5.29883 2.33325C3.89397 2.33325 3.35439 2.85177 3.25717 2.92436C3.21065 2.973 3.17377 3.0311 3.14872 3.09524C3.12367 3.15937 3.11096 3.22822 3.11134 3.2977V11.1481C3.11134 11.2856 3.16255 11.4175 3.25372 11.5147C3.34488 11.612 3.46852 11.6666 3.59745 11.6666C3.72637 11.6666 3.85002 11.612 3.94118 11.5147C4.03234 11.4175 4.08356 11.2856 4.08356 11.1481V8.91844C4.4709 8.76025 4.88441 8.68791 5.29883 8.70585C5.82549 8.73634 6.34188 8.8736 6.82036 9.11029C7.41146 9.40189 8.05084 9.56578 8.70161 9.59251C9.28762 9.61612 9.87057 9.49176 10.403 9.22955C10.5465 9.15764 10.6682 9.04417 10.7541 8.90206C10.8401 8.75995 10.8868 8.59494 10.8891 8.42585V3.94584C10.8889 3.80116 10.8567 3.65858 10.795 3.52972C10.7334 3.40086 10.644 3.28937 10.5342 3.20436ZM9.91689 8.34288C9.5294 8.50051 9.116 8.57283 8.70161 8.55547C8.1743 8.52567 7.65749 8.38653 7.18008 8.14584C6.58785 7.85846 5.94895 7.69645 5.29883 7.66881C4.88929 7.66128 4.48062 7.71185 4.08356 7.81918V3.58807C4.47 3.42595 4.88394 3.35177 5.29883 3.37029C5.82614 3.40009 6.34295 3.53923 6.82036 3.77992C7.41259 4.06731 8.05149 4.22932 8.70161 4.25696C9.1114 4.26341 9.52016 4.21109 9.91689 4.1014V8.34288Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
