import Domain, { DomainEdit } from '@/models/storeSettings/Domain'
import Store, { StoreOptions } from '@/models/storeSettings/Store'
import { StoreBackend } from '@/models/storeSettings/StoreBackend'
import ApiService from './ApiService'
import AuthService from './AuthService'

export default {
  async getStores(): Promise<Store[]> {
    const accountId = AuthService.getAccountId() ?? ''
    const response = await ApiService.get('/account/stores', {
      headers: { 'account-id': accountId },
    })
    return response.data
  },

  async createStore(name: string, slug: string): Promise<Store> {
    const accountId = AuthService.getAccountId() ?? ''
    const store = { name, slug }
    const payload = JSON.stringify({ store })

    const response = await ApiService.post('/account/stores', payload, {
      headers: { 'account-id': accountId },
    })
    return response.data
  },

  async setStoreBackend(storeId: string, backend: StoreBackend): Promise<Store> {
    const accountId = AuthService.getAccountId() ?? ''
    const store = { backend }
    const payload = JSON.stringify({ store })
    const response = await ApiService.patch(`/account/stores/${storeId}`, payload, {
      headers: { 'account-id': accountId },
    })

    return response.data
  },

  async setStoreOptions(storeId: string, options: StoreOptions): Promise<Store> {
    const accountId = AuthService.getAccountId() ?? ''
    const store = { options }
    const payload = JSON.stringify({ store })
    const response = await ApiService.patch(`/account/stores/${storeId}`, payload, {
      headers: { 'account-id': accountId },
    })

    return response.data
  },

  async updateDomain(storeId: string, domainId: string, domain: DomainEdit): Promise<Domain> {
    const accountId = AuthService.getAccountId() ?? ''
    const payload = JSON.stringify({ domain })
    const response = await ApiService.patch(`/${storeId}/domains/${domainId}`, payload, {
      headers: { 'account-id': accountId, 'store-id': storeId },
    })

    return response.data
  },

  async addDomain(storeId: string, domain: DomainEdit) {
    const accountId = AuthService.getAccountId() ?? ''
    const payload = JSON.stringify({ domain })
    const response = await ApiService.post(`/${storeId}/domains`, payload, {
      headers: { 'account-id': accountId, 'store-id': storeId },
    })

    return response.data
  },

  async deleteStore(storeId: string) {
    const accountId = AuthService.getAccountId() ?? ''
    await ApiService.delete(`/account/stores/${storeId}`, {
      headers: { 'account-id': accountId, 'store-id': storeId },
    })
  },
}
