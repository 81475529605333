import ColorSet from '@/models/ColorSet'
import ApiService from './ApiService'

const ColorsService = {
  async getStoreColors(storeId: string): Promise<ColorSet> {
    const response = await ApiService.get(`/${storeId}/styles/colors`, {
      headers: { 'store-id': storeId },
    })
    return response.data
  },

  async setStoreColors(storeId: string, colors: ColorSet): Promise<ColorSet> {
    const response = await ApiService.put(`/${storeId}/styles/colors`, { colors }, { headers: { 'store-id': storeId } })
    return response.data
  },
}

export default ColorsService
