<script>
export default {
  name: 'ImageColumnsTwoWithHeadIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="10" width="6" height="6" rx="1" :fill="`var(--${color})`" />
    <rect x="2" y="2" width="14" height="7" rx="1" :fill="`var(--${color})`" />
    <rect x="10" y="10" width="6" height="6" rx="1" :fill="`var(--${color})`" />
  </svg>
</template>
