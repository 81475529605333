type AlignmentSide = 'left' | 'center' | 'right'
export default function getElementHorizontalAlignment(
  distanceToLeftEdge: number,
  distanceToCenter: number,
  distanceToRightEdge: number
): AlignmentSide {
  if (distanceToLeftEdge < distanceToCenter && distanceToLeftEdge < distanceToRightEdge) return 'left'
  if (distanceToCenter < distanceToLeftEdge && distanceToCenter < distanceToRightEdge) return 'center'
  return 'right'
}
