<script>
export default {
  name: 'ArrowIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="11" r="1" transform="rotate(-180 7 11)" :fill="`var(--${color})`" />
    <circle cx="7" cy="7" r="1" transform="rotate(-180 7 7)" :fill="`var(--${color})`" />
    <circle cx="7" cy="3" r="1" transform="rotate(-180 7 3)" :fill="`var(--${color})`" />
  </svg>
</template>
