<script>
export default {
  name: 'WidthFullIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.45912 3C1.65327 3 1 3.60389 1 4.34884V13.6512C1 14.3961 1.65327 15 2.45912 15H15.5409C16.3467 15 17 14.3961 17 13.6512V4.34884C17 3.6039 16.3467 3 15.5409 3H2.45912Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
