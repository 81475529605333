import { onMounted, Ref, ref } from 'vue'

export default function useInputAutofocus(isEnabled: Ref<boolean>) {
  const inputEl: Ref<HTMLInputElement | null> = ref(null)

  onMounted(() => {
    if (isEnabled.value) inputEl.value?.focus()
  })

  return inputEl
}
