<script>
export default {
  name: 'CartIcon02',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00016 12.25C10.2218 12.25 12.8335 9.63833 12.8335 6.41667C12.8335 3.19501 10.2218 0.583333 7.00016 0.583333C3.7785 0.583333 1.16683 3.19501 1.16683 6.41667C1.16683 9.63833 3.7785 12.25 7.00016 12.25ZM7.00016 12.8333C10.544 12.8333 13.4168 9.96049 13.4168 6.41667C13.4168 2.87284 10.544 0 7.00016 0C3.45634 0 0.583496 2.87284 0.583496 6.41667C0.583496 9.96049 3.45634 12.8333 7.00016 12.8333Z"
      :fill="`var(--${color})`"
    />
    <path
      d="M5.18457 7.35C5.29657 8.169 5.91257 8.82 6.93457 8.82C7.62057 8.82 8.11057 8.575 8.39757 8.078C8.68457 7.581 8.83157 6.993 8.83157 6.307C8.83157 5.579 8.67757 4.977 8.37657 4.501C8.07557 4.018 7.59957 3.78 6.93457 3.78C5.98957 3.78 5.28957 4.459 5.28957 5.46C5.28957 6.23 5.82157 7 6.86457 7C7.50157 7 7.93557 6.643 8.12457 6.09C8.13157 6.153 8.13157 6.244 8.13157 6.363C8.13157 7.462 7.72557 8.12 6.96957 8.12C6.36057 8.12 5.98257 7.791 5.91957 7.14L5.18457 7.35ZM7.91457 5.39C7.91457 5.95 7.52957 6.335 6.96957 6.335C6.40257 6.335 6.02457 5.978 6.02457 5.425C6.02457 4.858 6.41657 4.445 6.96957 4.445C7.24257 4.445 7.47357 4.536 7.64857 4.718C7.82357 4.9 7.91457 5.124 7.91457 5.39Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
