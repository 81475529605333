import { Component } from 'vue'
import ContextImageUploader from './ContextImageUploader.vue'
import ContextNullEditor from './ContextNullEditor.vue'
import ContextSizeEditor from './ContextSizeEditor.vue'
import ContextFLEXImageEditor from './FLEXImageEditor/ContextFLEXImageEditor.vue'
import ContextTextEditor from './textEditor/ContextTextEditor.vue'

const contextSettingEditors = {
  ImageUploader: ContextImageUploader,
  TextEditor: ContextTextEditor,
  SizeEditor: ContextSizeEditor,
  FLEXImageEditor: ContextFLEXImageEditor,
} as Record<string, Component>

export function resolveContextEditorComponent(type: string) {
  return contextSettingEditors[type] ?? ContextNullEditor
}
