<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 7.5C6 7.22386 6.22386 7 6.5 7H16.5C16.7761 7 17 7.22386 17 7.5V10.5C17 10.7761 16.7761 11 16.5 11H6.5C6.22386 11 6 10.7761 6 10.5V7.5Z"
      fill="#666666"
    />
    <path
      d="M1 7.5C1 7.22386 1.22386 7 1.5 7H4.5C4.77614 7 5 7.22386 5 7.5V10.5C5 10.7761 4.77614 11 4.5 11H1.5C1.22386 11 1 10.7761 1 10.5V7.5Z"
      fill="#308FFF"
    />
  </svg>
</template>
