import axios, { AxiosError, AxiosInstance } from 'axios'

type ErrorHandler = (error: AxiosError) => void

const errorHandlers = new Map<number, Set<ErrorHandler>>()

interface ApiService extends AxiosInstance {
  setAuthToken(token: string): void
  onError(code: number, callback: (error: AxiosError) => void): void
  offError(code: number, callback: (error: AxiosError) => void): void
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    //'admin-secret': process.env.VUE_APP_API_KEY,
  },
}) as ApiService

axiosInstance.setAuthToken = (token: string) => {
  axiosInstance.defaults.headers.common['authorization'] = token
}

axiosInstance.onError = (code, callback) => {
  if (!errorHandlers.has(code)) errorHandlers.set(code, new Set())

  const handlersSet = errorHandlers.get(code) as Set<ErrorHandler>
  handlersSet.add(callback)
}

axiosInstance.offError = (code, callback) => {
  if (!errorHandlers.has(code)) return

  const handlersSet = errorHandlers.get(code) as Set<ErrorHandler>
  handlersSet.delete(callback)
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (typeof error !== 'object' && !error.isAxiosError) return

    const axiosError = error as AxiosError
    const errorCode = axiosError.response?.status ?? 500

    const errorHandlersForCurrentCode = errorHandlers.get(errorCode) ?? new Set()

    for (const errorHandler of errorHandlersForCurrentCode) {
      errorHandler(axiosError)
    }
  }
)

export default axiosInstance
