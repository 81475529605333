import Font from '@/models/fonts/Font'
import StoreFonts from '@/models/fonts/StoreFonts'
import Typography from '@/models/fonts/Typography'
import ApiService from './ApiService'

export interface IUploadFontPayload {
  family: string
  weight: number | string
  style: string
  file: File
}

export interface IUploadFontPayload {
  family: string
  weight: number | string
  style: string
  file: File
}

export default {
  async getStoreFonts(storeId: string, query: string): Promise<StoreFonts> {
    const response = await ApiService.get(`/${storeId}/styles/fonts?query=${query}`, {
      headers: { 'store-id': storeId },
    })
    return response.data
  },

  async getStoreTypography(storeId: string): Promise<Typography> {
    const response = await ApiService.get(`/${storeId}/styles/typography`, {
      headers: { 'store-id': storeId },
    })
    return response.data
  },

  async updateStoreTypography(storeId: string, newTypography: Typography): Promise<Typography> {
    const response = await ApiService.put(
      `/${storeId}/styles/typography`,
      {
        typography: newTypography,
      },
      {
        headers: { 'store-id': storeId },
      }
    )
    return response.data
  },

  async upload(storeId: string, data: IUploadFontPayload): Promise<Font> {
    const formData = new FormData()
    formData.append('font[family]', data.family)
    formData.append('font[weight]', data.weight.toString())
    formData.append('font[style]', data.style)
    formData.append('font[file]', data.file)
    const response = await ApiService.post(`/${storeId}/styles/fonts`, formData, {
      headers: { 'store-id': storeId, 'Content-Type': 'multipart/form-data' },
    })
    return response.data
  },

  async update(storeId: string, font: Font): Promise<Font> {
    const response = await ApiService.put(
      `/${storeId}/styles/fonts/${font.id}`,
      { font },
      {
        headers: { 'store-id': storeId },
      }
    )
    return response.data
  },

  async delete(storeId: string, font: Font): Promise<boolean> {
    await ApiService.delete(`/${storeId}/styles/fonts/${font.id}`, {
      headers: { 'store-id': storeId },
    })
    return true
  },
}
