<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 7C1 6.44772 1.44772 6 2 6H16C16.5523 6 17 6.44772 17 7V11C17 11.5523 16.5523 12 16 12H2C1.44772 12 1 11.5523 1 11V7Z"
      fill="#666666"
    />
    <path
      d="M12 7.5C12 7.22386 12.2239 7 12.5 7H15.5C15.7761 7 16 7.22386 16 7.5V10.5C16 10.7761 15.7761 11 15.5 11H12.5C12.2239 11 12 10.7761 12 10.5V7.5Z"
      fill="#308FFF"
    />
  </svg>
</template>
