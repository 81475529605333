import { reactive, ref, Ref, toRaw } from 'vue'
import { BlockConfig, DataStorage } from '@/global'
import EditorPage from '@/models/editor/EditorPage'
import { unpackContextEditorsSnippets } from '@/util/data/BlockUtils'
import PageUtils from '@/util/data/PageUtils'

type BlockSid = string

export default function useStorefront(storeId: Ref<string>, onEditorPageSelect?: (e: MouseEvent) => void) {
  const blockConfigs: Ref<BlockConfig[]> = ref([])
  const blocksDefaultOptions: Ref<Record<BlockSid, any>> = ref({})
  const blocksDefaultContent: Ref<Record<BlockSid, any>> = ref({})

  const setPages = (storeSlug: string, pages: EditorPage[], data: DataStorage) => {
    pages = toRaw(pages)
    data = toRaw(data)
    const transformedPages = PageUtils.transformToStorefrontPages(storeSlug, pages)

    const blocks = window.startStorefront(
      storeSlug,
      storeId.value,
      transformedPages,
      data,
      `/${storeSlug}/editor`,
      onEditorPageSelect
    )

    blockConfigs.value = Object.values(blocks).map((block) => ({
      ...block.config,
      contextEditors: unpackContextEditorsSnippets(block.config.contextEditors),
    }))

    for (const block of Object.values(blocks)) {
      if (block.config.sid) {
        blocksDefaultOptions.value[block.config.sid] = block.defaultOptions
        blocksDefaultContent.value[block.config.sid] = block.defaultContent
      }
    }
  }

  const updateBlockContent = (blockId: string, settingVariableName: string, settingValue: any) => {
    window.setBlockContentOption(blockId, settingVariableName, settingValue)
  }

  const updateBlockOptions = (blockId: string, settingVariableName: string, settingValue: any) => {
    window.setBlockOption(blockId, settingVariableName, settingValue)
  }

  return reactive({
    blockConfigs,
    blocksDefaultOptions,
    blocksDefaultContent,
    setPages,
    updateBlockContent,
    updateBlockOptions,
  })
}
