import { computed, reactive, ref, Ref, watch } from 'vue'
import { AnyObject } from '@/global'

export default function useVerticalExpandAnimation(childrenEl: Ref<HTMLElement | null>, initialIsExpanded = false) {
  const isExpanded = ref(initialIsExpanded)
  const isAnimating = ref(false)

  let childLinksAnimationStopTimer: number | null = 0

  watch([isExpanded, isAnimating], () => {
    if (childLinksAnimationStopTimer) clearTimeout(childLinksAnimationStopTimer)
    childLinksAnimationStopTimer = setTimeout(() => (isAnimating.value = false), 250)
  })

  const style = computed(() => {
    const scrollHeight = (childrenEl.value?.scrollHeight ?? 0) + 'px'
    const styles: AnyObject = {}

    if (isAnimating.value) {
      styles.height = isExpanded.value ? scrollHeight : 0
      styles.overflow = 'hidden'
    } else {
      styles.height = isExpanded.value ? 'auto' : 0
      styles.overflow = isExpanded.value ? 'initial' : 'hidden'
    }

    return styles
  })

  const toggleExpand = (newValue: boolean) => {
    if (newValue === isExpanded.value) return
    isAnimating.value = true
    requestAnimationFrame(() => {
      isExpanded.value = newValue
    })
  }

  return reactive({
    style,
    isExpanded,
    toggleExpand,
  })
}
