<script>
export default {
  name: 'CounterIcon01',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.32873 3.61347H0.5V12.7724H9.5V3.61347H7.67127C7.61569 3.12826 7.4728 2.55209 7.18587 2.03981C6.7814 1.31768 6.0841 0.721161 5 0.721161C3.9159 0.721161 3.2186 1.31768 2.81413 2.03981C2.5272 2.55209 2.38431 3.12826 2.32873 3.61347ZM3.23733 3.61347H6.76267C6.71137 3.26519 6.60321 2.87343 6.41413 2.53584C6.1436 2.05285 5.7159 1.68526 5 1.68526C4.2841 1.68526 3.8564 2.05285 3.58587 2.53584C3.39679 2.87343 3.28863 3.26519 3.23733 3.61347ZM1.4 4.57757V11.8083H8.6V4.57757H1.4Z"
      :fill="`var(--${color})`"
    />
    <path
      d="M11.75 6.09171C11.75 5.59726 11.9199 5.18901 12.2598 4.86698C12.6022 4.54241 13.0343 4.38013 13.5563 4.38013C14.0564 4.38013 14.4728 4.5272 14.8054 4.82134C15.138 5.11548 15.3043 5.48061 15.3043 5.91675C15.3043 6.22357 15.2229 6.51517 15.0603 6.79156C14.8976 7.06542 14.6136 7.41915 14.2081 7.85275L12.8935 9.23343V9.25245H15.388V9.97131H11.7937V9.39318L13.691 7.3735C14.0115 7.02358 14.2263 6.7548 14.3356 6.56716C14.4448 6.37698 14.4995 6.17412 14.4995 5.95859C14.4995 5.71517 14.406 5.50851 14.2191 5.33862C14.0321 5.16873 13.8027 5.08378 13.5308 5.08378C13.2443 5.08378 13.0039 5.18014 12.8097 5.37285C12.6179 5.56302 12.522 5.80138 12.522 6.08791V6.09932H11.75V6.09171Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
