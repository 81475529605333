import { computed, reactive, Ref, ref } from 'vue'
import ColorSet from '@/models/ColorSet'
import Typography from '@/models/fonts/Typography'

function createStylesState() {
  const typography: Ref<Typography | null> = ref(null)
  const setTypography = (newTypography: Typography) => (typography.value = newTypography)

  const colors: Ref<ColorSet | null> = ref(null)
  const setColors = (newColors: ColorSet) => (colors.value = newColors)

  const basicColors = computed(() => colors.value?.styles.filter((item) => item.type === 'basic'))
  const customColors = computed(() => colors.value?.styles.filter((item) => item.type === 'custom'))

  const isInitialised = ref(false)
  const isInitialising = ref(false)

  return reactive({
    typography,
    setTypography,
    colors,
    setColors,
    basicColors,
    customColors,
    isInitialised,
    isInitialising,
  })
}

export default createStylesState()
