<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8465 4.28089L15.8463 4.28036C15.3317 3.25616 13.766 2.31824 11.8604 2.85748L11.8585 2.85802C10.9448 3.11401 10.1463 3.66334 9.59449 4.4175L8.99989 5.23011L8.40528 4.4175C7.85359 3.66354 7.05544 3.1143 6.14197 2.85822C4.22683 2.32607 2.66675 3.25881 2.15353 4.28036L2.15326 4.28089C1.37059 5.83547 1.63131 7.65698 3.24814 9.80747C4.5112 11.4849 6.32752 13.212 9.00243 15.2399C11.6752 13.2153 13.4937 11.5031 14.7547 9.81001L14.756 9.80826C16.3684 7.6575 16.6293 5.83571 15.8465 4.28089ZM2.65896 10.2178C0.948705 7.9431 0.558648 5.84296 1.49879 3.97563C2.16888 2.64183 4.09417 1.56023 6.3445 2.18591C7.17659 2.41902 7.92639 2.85905 8.52257 3.45688C8.69512 3.62991 8.8548 3.81616 8.99989 4.01443C9.14497 3.81616 9.30466 3.62991 9.47721 3.45688C10.0734 2.85905 10.8232 2.41902 11.6553 2.18591C13.9006 1.55053 15.8309 2.64183 16.501 3.97563C17.4411 5.84296 17.0511 7.9431 15.3458 10.2178C14.0056 12.0173 12.0853 13.807 9.30993 15.8974C9.22207 15.9639 9.11382 16 9.00239 16C8.89095 16 8.78271 15.9639 8.69484 15.8974C5.91444 13.8021 3.99915 11.9979 2.65896 10.2178Z"
      fill="black"
    />
  </svg>
</template>
