<script>
export default {
  name: 'UploadIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg
    class="context-image-uploader__icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 2.75H12.75M4.25 9.25L8.25 5.75M8.25 5.75L12.25 9.25M8.25 5.75V14.25"
      :stroke="`var(--${color})`"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
