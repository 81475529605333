<template>
  <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.40005C0 1.06868 0.268629 0.800049 0.6 0.800049H19.4C19.7314 0.800049 20 1.06868 20 1.40005C20 1.73142 19.7314 2.00005 19.4 2.00005H0.6C0.268629 2.00005 0 1.73142 0 1.40005Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 5.40005C0 5.06868 0.268629 4.80005 0.6 4.80005H19.4C19.7314 4.80005 20 5.06868 20 5.40005C20 5.73142 19.7314 6.00005 19.4 6.00005H0.6C0.268629 6.00005 0 5.73142 0 5.40005Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 9.40005C0 9.06868 0.268629 8.80005 0.6 8.80005H19.4C19.7314 8.80005 20 9.06868 20 9.40005C20 9.73142 19.7314 10 19.4 10H0.6C0.268629 10 0 9.73142 0 9.40005Z"
      fill="black"
    />
  </svg>
</template>
