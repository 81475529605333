import { reactive, ref, watch } from 'vue'

function getModalVisibilityLocalStorageKey(type: string) {
  return `atlr-admin-${type}-visibility`
}

function createModalToggle(type: string) {
  const localStorageKey = getModalVisibilityLocalStorageKey(type)
  const initialValue = false

  const isModalVisible = ref(initialValue)
  watch(isModalVisible, (isModalVisible) => {
    localStorage.setItem(localStorageKey, isModalVisible.toString())
  })

  return isModalVisible
}

function createModalsState() {
  const isCartShown = createModalToggle('cart')
  const isMenuShown = createModalToggle('menu')
  const isWishlistShown = createModalToggle('wishlist')
  const isSizeGuideShown = createModalToggle('size-guide')
  const isCountrySelectorShown = createModalToggle('country-selector')
  const isOverlayShown = createModalToggle('overlay')
  const isCookiesPopupShown = createModalToggle('cookies-popup')
  const isSubscriptionPopupShown = createModalToggle('subscription-popup')

  const modalState = reactive({
    isCartShown,
    isMenuShown,
    isWishlistShown,
    isSizeGuideShown,
    isCountrySelectorShown,
    isOverlayShown,
    isCookiesPopupShown,
    isSubscriptionPopupShown,
  })

  watch(
    modalState,
    (modalState) => {
      if ('patchModalState' in window) {
        return window.patchModalState({ ...modalState })
      }
    },
    { immediate: true, deep: true }
  )

  return modalState
}

export default createModalsState()
