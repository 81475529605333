<script>
export default {
  name: 'MenuIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.11133" y="3.11108" width="7.77778" height="1.55556" rx="0.777778" :stroke="`var(--${color})`" />
    <rect x="3.11133" y="6.22217" width="7.77778" height="1.55556" rx="0.777778" :stroke="`var(--${color})`" />
    <rect x="3.11133" y="9.33325" width="7.77778" height="1.55556" rx="0.777778" :stroke="`var(--${color})`" />
  </svg>
</template>
