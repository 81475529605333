<script>
export default {
  name: 'FlexProductIcon',
  props: {
    color: String,
    size: {
      type: String,
      default: 'medium',
      validator: (size) => ['small', 'medium', 'large'].includes(size),
    },
  },
}
</script>

<template>
  <svg
    :class="`flex-product-icon flex-product-icon--size-${size}`"
    style="pointer-events: none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.6654 2.66732H5.33203C3.85927 2.66732 2.66536 3.86122 2.66536 5.33398V18.6673C2.66536 20.1401 3.85927 21.334 5.33203 21.334H18.6654C20.1381 21.334 21.332 20.1401 21.332 18.6673V5.33398C21.332 3.86122 20.1381 2.66732 18.6654 2.66732ZM5.33203 1.33398C3.12289 1.33398 1.33203 3.12485 1.33203 5.33398V18.6673C1.33203 20.8765 3.12289 22.6673 5.33203 22.6673H18.6654C20.8745 22.6673 22.6654 20.8765 22.6654 18.6673V5.33398C22.6654 3.12485 20.8745 1.33398 18.6654 1.33398H5.33203Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>

<style lang="scss">
.flex-product-icon--size-small {
  width: 18px;
  height: 18px;
}

.flex-product-icon--size-medium {
  width: 24px;
  height: 24px;
}

.flex-product-icon--size-large {
  width: 32px;
  height: 32px;
}
</style>
