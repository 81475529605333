<script>
export default {
  name: 'SubscriptionIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.63522 4.36417L9.39023 3.6091C9.52353 3.48311 9.7006 3.41374 9.88413 3.41575C10.0691 3.41777 10.246 3.49214 10.3768 3.62294C10.5076 3.75374 10.5821 3.93057 10.5841 4.11556C10.5862 4.29907 10.5169 4.47614 10.3909 4.60948L9.63571 5.36465L8.63522 4.36417ZM7.99361 5.00583L3.84222 9.15756L3.52147 8.83684L3.84219 9.15759C3.77885 9.22092 3.73568 9.30159 3.71811 9.38942L3.7181 9.38943L3.49497 10.5051L4.6102 10.282L4.61022 10.282C4.69805 10.2644 4.77872 10.2213 4.84206 10.1579L4.84209 10.1579L8.99405 6.00626L7.99361 5.00583ZM3.20059 8.51593L3.20056 8.51595C3.01055 8.70596 2.88103 8.94796 2.82832 9.21145L2.82832 9.21146L2.47171 10.9945C2.44196 11.1433 2.48852 11.2971 2.59579 11.4043C2.70306 11.5116 2.85685 11.5581 3.0056 11.5284L4.78819 11.1718L4.7882 11.1718C5.0517 11.1191 5.29369 10.9896 5.48369 10.7995L5.48372 10.7995L11.0366 5.24711L11.0437 5.23988C11.3355 4.93478 11.4962 4.52755 11.4915 4.10539C11.4867 3.68323 11.3169 3.27971 11.0183 2.98122C10.7198 2.68273 10.3162 2.51301 9.89404 2.5084C9.47188 2.50378 9.0647 2.66464 8.75968 2.95654L8.75253 2.96353L3.20059 8.51593Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
