import VirtualViewportState from '@/globalState/VirtualViewportState'
import AllViewportsPosition from '@/models/flex/AllViewportsPosition'
import checkIsPositionSameOnAllViewports from './checkIsPositionSameOnAllViewports'
import getElementAlignmentAndOffset from './getElementAlignmentAndOffset'

export default function getAllViewportsPosition(
  initialPosition: AllViewportsPosition | undefined,
  initialBlockWidth: number,
  newFlexElementX: number,
  newFlexElementY: number,
  newFlexElementWidth: number
) {
  const isPositionsSameOnAllViewports = checkIsPositionSameOnAllViewports(initialPosition)

  const { horizontalAlignment, horizontalOffset, verticalOffset } = getElementAlignmentAndOffset(
    initialBlockWidth,
    newFlexElementX,
    newFlexElementY,
    newFlexElementWidth
  )

  const newPosition = { horizontalAlignment, horizontalOffset, verticalOffset }

  if ((isPositionsSameOnAllViewports && VirtualViewportState.currentViewport === 'none') || !initialPosition) {
    return {
      mobile: newPosition,
      tablet: newPosition,
      desktop: newPosition,
    }
  }

  const result: AllViewportsPosition = { ...initialPosition }
  if (VirtualViewportState.currentViewport === 'none') result.desktop = newPosition
  if (VirtualViewportState.currentViewport === 'tablet') result.tablet = newPosition
  if (VirtualViewportState.currentViewport === 'mobile') result.mobile = newPosition

  return result
}
