<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.92926 1.04896C8.95076 0.98368 9.04924 0.98368 9.07074 1.04896L10.9066 6.62287C10.9162 6.65198 10.9448 6.67183 10.9773 6.67183L16.9258 6.67183C16.9966 6.67183 17.027 6.75616 16.9708 6.7965L12.152 10.2503C12.1274 10.268 12.1171 10.2982 12.1262 10.326L13.9653 15.9097C13.9866 15.9743 13.9069 16.0265 13.8496 15.9854L9.04495 12.5417C9.01841 12.5227 8.98159 12.5227 8.95505 12.5417L4.15038 15.9854C4.09309 16.0265 4.01341 15.9743 4.03469 15.9097L5.87375 10.326C5.88291 10.2982 5.87262 10.268 5.84796 10.2503L1.02924 6.7965C0.972958 6.75617 1.00336 6.67183 1.07419 6.67183L7.02268 6.67183C7.05515 6.67183 7.08383 6.65198 7.09342 6.62287L8.92926 1.04896ZM9.07074 3.21565C9.04924 3.15037 8.95076 3.15037 8.92926 3.21565L7.58652 7.29242C7.57694 7.32152 7.54825 7.34138 7.51578 7.34138L3.16301 7.34138C3.09218 7.34138 3.06178 7.42571 3.11806 7.46605L6.64582 9.99454C6.67047 10.0122 6.68077 10.0424 6.67161 10.0703L5.32565 14.1568C5.30437 14.2214 5.38406 14.2736 5.44134 14.2325L8.95505 11.7141C8.98159 11.6951 9.01841 11.6951 9.04495 11.7141L12.5587 14.2325C12.6159 14.2736 12.6956 14.2214 12.6743 14.1568L11.3284 10.0703C11.3192 10.0424 11.3295 10.0122 11.3542 9.99454L14.8819 7.46605C14.9382 7.42571 14.9078 7.34138 14.837 7.34138L10.4842 7.34138C10.4517 7.34138 10.4231 7.32152 10.4135 7.29242L9.07074 3.21565Z"
      fill="black"
    />
  </svg>
</template>
