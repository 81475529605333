import { computed, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import StoresState from '@/globalState/StoresState'

export default function useStores() {
  const route = useRoute()
  const currentStoreSlug = computed(() => route.params.storeSlug as string)

  const currentStore = computed(() => StoresState.stores.find((item) => item.slug == currentStoreSlug.value) ?? null)
  const currentStoreId = computed(() => currentStore.value?.id ?? '')

  const { stores } = toRefs(StoresState)

  return reactive({
    currentStore,
    stores,
    currentStoreId,
    currentStoreSlug,
  })
}
