import { computed, ref, Ref, watch } from 'vue'
import AssetsService from '@/api/AssetsService'
import Asset from '@/models/editor/Asset'
import useStores from '../globalState/useStores'

export default function useAssetsService() {
  const storesState = useStores()
  const storeId = computed(() => storesState.currentStoreId)
  const storeAssets: Ref<Asset[] | null> = ref(null)

  const getStoreAssets = () => AssetsService.getStoreAssets(storeId.value)
  const createStoreAsset = (file: File) => AssetsService.createStoreAsset(storeId.value, file)
  const updateStoreAsset = (asset: Asset, file: File) => AssetsService.updateStoreAsset(storeId.value, asset, file)

  watch(storeId, async (storeId) => {
    if (!storeId) return
    storeAssets.value = await getStoreAssets()
  })

  const onAssetChange = async (
    prevAssetPath: string | null | undefined,
    file: File,
    callback: (assetUrl: string) => void
  ) => {
    let asset = storeAssets.value?.find((item) => item.file_path === prevAssetPath)
    // todo: asset replacing
    asset = await createStoreAsset(file)
    getStoreAssets().then((assets) => (storeAssets.value = assets))
    callback(asset.file_path)
  }

  return {
    createStoreAsset,
    getStoreAssets,
    updateStoreAsset,
    storeAssets,
    onAssetChange,
  }
}

export const getCdnUrl = (path: string) => process.env.VUE_APP_BASE_CDN_URL + path
