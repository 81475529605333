<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 9.5L5 9.5V8.5H8.5V5H9.5V8.5L13 8.5V9.5H9.5V13H8.5V9.5Z"
      fill="#232323"
    />
  </svg>
</template>
