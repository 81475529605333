import createSeededRNG from '../createSeededRNG'

function generateRandomNumber(rng: () => number, from: number, to: number) {
  const rawResult = from + (to - from) * rng()
  return Math.floor(rawResult)
}

function shuffleChannelValues(order: number, first: number, second: number, third: number) {
  switch (order) {
    case 0:
      return [first, second, third]
    case 1:
      return [first, third, second]
    case 2:
      return [second, first, third]
    case 3:
      return [second, third, first]
    case 4:
      return [third, first, second]
    default:
      return [third, second, first]
  }
}

export default function generateColorFromId(id: string) {
  const colorBrackets = [
    [96, 120],
    [140, 180],
    [160, 212],
  ]

  const rng = createSeededRNG(id)

  const channelColorValues = colorBrackets.map(([from, to]) => generateRandomNumber(rng, from, to)) as [
    number,
    number,
    number
  ]

  const order = generateRandomNumber(rng, 0, 6)
  const [r, g, b] = shuffleChannelValues(order, ...channelColorValues)

  return `rgb(${r}, ${g}, ${b})`
}
