<script>
export default {
  name: 'MobileIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.6 1.26001C4.74948 1.26001 4.06 1.94949 4.06 2.80001V10.64C4.06 11.4905 4.74948 12.18 5.6 12.18H8.96C9.81052 12.18 10.5 11.4905 10.5 10.64V2.80001C10.5 1.94949 9.81052 1.26001 8.96 1.26001H5.6ZM4.9 2.80001C4.9 2.41341 5.2134 2.10001 5.6 2.10001H6.17764C6.16612 2.14476 6.16 2.19167 6.16 2.24001C6.16 2.54929 6.41072 2.80001 6.72 2.80001H7.84C8.14928 2.80001 8.4 2.54929 8.4 2.24001C8.4 2.19167 8.39387 2.14476 8.38235 2.10001H8.96C9.3466 2.10001 9.66 2.41341 9.66 2.80001V10.64C9.66 11.0266 9.3466 11.34 8.96 11.34H5.6C5.2134 11.34 4.9 11.0266 4.9 10.64V2.80001Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
