<script>
export default {
  name: 'UnknownPageIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.21584 2.09075C8.52693 1.77013 8.95025 1.5 9.5 1.5C10.0497 1.5 10.4731 1.77013 10.7841 2.09075C11.0889 2.40482 11.3193 2.80026 11.4965 3.13566L16.9265 13.4082C17.1065 13.7487 17.291 14.1771 17.3454 14.6125C17.4013 15.0601 17.3226 15.5726 16.8996 15.9626C16.5219 16.3107 16.0179 16.5 15.5 16.5H3.5C2.9821 16.5 2.47804 16.3107 2.10043 15.9626C1.67735 15.5726 1.59866 15.0601 1.65457 14.6125C1.70895 14.1771 1.89353 13.7487 2.07351 13.4082L7.50344 3.13566C7.68073 2.80026 7.9111 2.40482 8.21584 2.09075ZM8.93353 2.78711C8.73291 2.99388 8.55662 3.2831 8.38753 3.60297L2.9576 13.8755C2.799 14.1755 2.67912 14.4781 2.64686 14.7364C2.61614 14.9824 2.66863 15.1263 2.77824 15.2274C2.96324 15.3979 3.22225 15.5 3.5 15.5H15.5C15.7777 15.5 16.0368 15.3979 16.2218 15.2274C16.3314 15.1263 16.3839 14.9824 16.3531 14.7364C16.3209 14.4781 16.201 14.1755 16.0424 13.8755L10.6125 3.60297C10.4434 3.2831 10.2671 2.99388 10.0665 2.78711C9.87218 2.58689 9.69143 2.5 9.5 2.5C9.30856 2.5 9.12781 2.58689 8.93353 2.78711Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
