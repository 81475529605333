import { computed } from '@vue/reactivity'
import { ComputedRef, onBeforeUnmount, reactive, Ref, StyleValue, watch } from 'vue'

interface TracerOptions {
  overflowPaddingRight?: number
  shrinkBox?: boolean
  considerTransform?: boolean
  doNotTraceMouseMove?: boolean
  doNotTraceScroll?: boolean
}

let lastCreatedTracerId = 0

const tracersToUpdateIds = new Set<number>()
const highPriorityTracersToUpdateIds = new Set<number>()
const tracerUpdateCallbacks = new Map<number, () => void>()

let updatesInCurrentFrameCount = 0
const maxUpdatesPerFrame = 20

let windowHeight = 0
let windowScrollY = 0

function runUpdates() {
  windowHeight = window.innerHeight
  windowScrollY = window.scrollY

  const runUpdateInSchedule = (schedule: Set<number>, ignoreUpdatePerFrameCap = false) => {
    for (const tracerId of schedule) {
      if (updatesInCurrentFrameCount >= maxUpdatesPerFrame && !ignoreUpdatePerFrameCap) break

      const callback = tracerUpdateCallbacks.get(tracerId)
      if (callback) {
        schedule.delete(tracerId)
        callback()
      }
    }
  }

  runUpdateInSchedule(highPriorityTracersToUpdateIds, true)
  runUpdateInSchedule(tracersToUpdateIds)

  updatesInCurrentFrameCount = 0

  requestAnimationFrame(runUpdates)
}
runUpdates()

export default function useSizeAndPositionTracer(
  blockNode: Ref<HTMLElement | null>,
  targetNode: Ref<HTMLElement | null>,
  options: TracerOptions = {}
) {
  const tracerId = ++lastCreatedTracerId
  let isInFrame = false

  const sizeAndPosition = reactive({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  })

  const blockSize = reactive({
    width: 0,
    height: 0,
  })

  const updateIsInFrame = (rect: DOMRect) => {
    isInFrame = true
    if (rect.bottom < windowScrollY) isInFrame = false
    if (rect.top > windowScrollY + windowHeight) isInFrame = false
  }

  const doUpdate = () => {
    if (!blockNode.value || !targetNode.value) return

    const initialTransform = targetNode.value.style.transform
    if (!options.considerTransform) targetNode.value.style.transform = ''

    const blockClientRect = blockNode.value.getBoundingClientRect()
    const targetClientRect = targetNode.value.getBoundingClientRect()

    updateIsInFrame(targetClientRect)

    if (!options.considerTransform) targetNode.value.style.transform = initialTransform

    const verticalOffset = targetClientRect.top - blockClientRect.top
    const horizontalOffset = targetClientRect.left - blockClientRect.left

    sizeAndPosition.top = verticalOffset
    sizeAndPosition.left = horizontalOffset
    sizeAndPosition.width = targetClientRect.width
    sizeAndPosition.height = targetClientRect.height

    blockSize.width = blockClientRect.width
    blockSize.height = blockClientRect.height

    updatesInCurrentFrameCount++
  }

  const update = () => {
    if (updatesInCurrentFrameCount < maxUpdatesPerFrame) {
      doUpdate()
    } else {
      if (isInFrame) highPriorityTracersToUpdateIds.add(tracerId)
      else tracersToUpdateIds.add(tracerId)

      tracerUpdateCallbacks.set(tracerId, doUpdate)
    }
  }

  const updateImmediate = () => {
    tracersToUpdateIds.delete(tracerId)
    doUpdate()
  }

  const absolutePositionStyle: ComputedRef<StyleValue> = computed(() => ({
    position: 'absolute',
    top: sizeAndPosition.top + sizeAndPosition.height / 2 + 'px',
    left: sizeAndPosition.left + sizeAndPosition.width / 2 + 'px',
    minHeight: sizeAndPosition.height - (options.shrinkBox ? 16 : 0) + 'px',
    minWidth: sizeAndPosition.width - (options.shrinkBox ? 16 : 0) + 'px',
    transform: 'translate(-50%, -50%)',
    display:
      !sizeAndPosition.top && !sizeAndPosition.left && !sizeAndPosition.height && !sizeAndPosition.width ? 'none' : '',
  }))

  const targetElementHorizontalOverflow = computed(() => {
    const overflowPaddingRight = options.overflowPaddingRight ?? 0
    if (sizeAndPosition.left + sizeAndPosition.width < blockSize.width - overflowPaddingRight) return 0
    return sizeAndPosition.left + sizeAndPosition.width - (blockSize.width - overflowPaddingRight)
  })

  const targetElementVerticalOverflow = computed(() => {
    if (sizeAndPosition.top + sizeAndPosition.height < blockSize.height) return 0
    return sizeAndPosition.left + sizeAndPosition.width - blockSize.height
  })

  let scrollingParents: HTMLElement[] = []

  const removeAllEventListeners = () => {
    window.removeEventListener('resize', update)
    window.removeEventListener('click', update)
    if (!options.doNotTraceMouseMove) window.removeEventListener('mousemove', update)

    if (!options.doNotTraceScroll) {
      for (const parent of scrollingParents) {
        parent.removeEventListener('scroll', update)
      }
    }
  }

  watch(
    targetNode,
    (targetNode, prevTargetNode) => {
      if (prevTargetNode) removeAllEventListeners()
      if (!targetNode) return

      window.addEventListener('resize', update)
      window.addEventListener('click', update)
      if (!options.doNotTraceMouseMove) window.addEventListener('mousemove', update)

      scrollingParents = []
      let currentNode = targetNode
      while (currentNode.parentElement) {
        currentNode = currentNode.parentElement
        if (currentNode.scrollHeight != currentNode.clientHeight) scrollingParents.push(currentNode)
      }

      if (!options.doNotTraceScroll) {
        for (const parent of scrollingParents) {
          parent.addEventListener('scroll', update)
        }
      }
    },
    { immediate: true }
  )

  onBeforeUnmount(() => {
    removeAllEventListeners()
  })

  const updateUntilChanged = () => {
    const currentSizeAndPosition = { ...sizeAndPosition }
    updateImmediate()
    if (
      currentSizeAndPosition.height === sizeAndPosition.height &&
      currentSizeAndPosition.width === sizeAndPosition.width
    )
      requestAnimationFrame(updateUntilChanged)
  }

  return reactive({
    sizeAndPosition,
    absolutePositionStyle,
    targetElementHorizontalOverflow,
    targetElementVerticalOverflow,
    update,
    updateImmediate,
    updateUntilChanged,
  })
}
