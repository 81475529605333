<script>
export default {
  name: 'LayoutIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="2" width="16" height="3" rx="1.5" :stroke="`var(--${color})`" />
    <rect x="1" y="13" width="16" height="3" rx="1.5" :stroke="`var(--${color})`" />
    <path
      d="M0.5 4.45833C0.5 4.73448 0.723858 4.95833 1 4.95833C1.27614 4.95833 1.5 4.73448 1.5 4.45833H0.5ZM1.5 6.29167C1.5 6.01552 1.27614 5.79167 1 5.79167C0.723858 5.79167 0.5 6.01552 0.5 6.29167H1.5ZM0.5 7.20833C0.5 7.48448 0.723858 7.70833 1 7.70833C1.27614 7.70833 1.5 7.48448 1.5 7.20833H0.5ZM1.5 9.04167C1.5 8.76552 1.27614 8.54167 1 8.54167C0.723858 8.54167 0.5 8.76552 0.5 9.04167H1.5ZM0.5 9.95833C0.5 10.2345 0.723858 10.4583 1 10.4583C1.27614 10.4583 1.5 10.2345 1.5 9.95833H0.5ZM1.5 11.7917C1.5 11.5155 1.27614 11.2917 1 11.2917C0.723858 11.2917 0.5 11.5155 0.5 11.7917H1.5ZM0.5 12.7083C0.5 12.9845 0.723858 13.2083 1 13.2083C1.27614 13.2083 1.5 12.9845 1.5 12.7083H0.5ZM1.5 14.5417C1.5 14.2655 1.27614 14.0417 1 14.0417C0.723858 14.0417 0.5 14.2655 0.5 14.5417H1.5ZM0.5 4V4.45833H1.5V4H0.5ZM0.5 6.29167V7.20833H1.5V6.29167H0.5ZM0.5 9.04167V9.95833H1.5V9.04167H0.5ZM0.5 11.7917V12.7083H1.5V11.7917H0.5ZM0.5 14.5417V15H1.5V14.5417H0.5Z"
      :fill="`var(--${color})`"
    />
    <path
      d="M17.5 3.5C17.5 3.22386 17.2761 3 17 3C16.7239 3 16.5 3.22386 16.5 3.5H17.5ZM16.5 14.5C16.5 14.7761 16.7239 15 17 15C17.2761 15 17.5 14.7761 17.5 14.5H16.5ZM16.5 3.95833C16.5 4.23448 16.7239 4.45833 17 4.45833C17.2761 4.45833 17.5 4.23448 17.5 3.95833H16.5ZM17.5 5.79167C17.5 5.51552 17.2761 5.29167 17 5.29167C16.7239 5.29167 16.5 5.51552 16.5 5.79167H17.5ZM16.5 6.70833C16.5 6.98448 16.7239 7.20833 17 7.20833C17.2761 7.20833 17.5 6.98448 17.5 6.70833H16.5ZM17.5 8.54167C17.5 8.26552 17.2761 8.04167 17 8.04167C16.7239 8.04167 16.5 8.26552 16.5 8.54167H17.5ZM16.5 9.45833C16.5 9.73448 16.7239 9.95833 17 9.95833C17.2761 9.95833 17.5 9.73448 17.5 9.45833H16.5ZM17.5 11.2917C17.5 11.0155 17.2761 10.7917 17 10.7917C16.7239 10.7917 16.5 11.0155 16.5 11.2917H17.5ZM16.5 12.2083C16.5 12.4845 16.7239 12.7083 17 12.7083C17.2761 12.7083 17.5 12.4845 17.5 12.2083H16.5ZM17.5 14.0417C17.5 13.7655 17.2761 13.5417 17 13.5417C16.7239 13.5417 16.5 13.7655 16.5 14.0417H17.5ZM16.5 3.5V3.95833H17.5V3.5H16.5ZM16.5 5.79167V6.70833H17.5V5.79167H16.5ZM16.5 8.54167V9.45833H17.5V8.54167H16.5ZM16.5 11.2917V12.2083H17.5V11.2917H16.5ZM16.5 14.0417V14.5H17.5V14.0417H16.5Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
