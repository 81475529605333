import { reactive, Ref, ref } from 'vue'
import Domain from '@/models/storeSettings/Domain'
import Store from '@/models/storeSettings/Store'

function createStoreState() {
  const stores: Ref<Store[]> = ref([])

  const setStores = async (newStores: Store[]) => {
    stores.value = newStores
  }

  const updateStore = (store: Store) => {
    const storeIndex = stores.value.findIndex((item) => item.id === store.id)
    if (storeIndex !== -1) {
      stores.value[storeIndex] = store
    }
  }

  const updateDomain = (storeId: string, domain: Domain) => {
    const store = findStore(storeId)
    if (domain.is_primary) {
      store.domains = store.domains.map((d) => ({ ...d, is_primary: false }))
    }
    const domainIndex = store.domains.findIndex((item) => item.id === domain.id)
    if (domainIndex !== -1) {
      store.domains[domainIndex] = domain
    }
  }

  const addDomain = (storeId: string, domain: Domain) => {
    const store = findStore(storeId)
    if (domain.is_primary) {
      store.domains = store.domains.map((d) => ({ ...d, is_primary: false }))
    }

    store.domains.push(domain)
  }

  const findStore = (id: string): Store => {
    return stores.value.find((item) => item.id === id) as Store
  }

  return reactive({
    stores,
    setStores,
    updateStore,
    addDomain,
    updateDomain,
  })
}

export default createStoreState()
