<script>
export default {
  name: 'DescriptionPositionRightIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="3" width="16" height="12" rx="2" fill="none" :stroke="`var(--${color})`" />
    <rect x="10" y="5" width="5" height="8" rx="1" :fill="`var(--${color})`" />
  </svg>
</template>
