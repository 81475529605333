interface UnparsedRGBA {
  r: string
  g: string
  b: string
  a: string
}

const removeHash = (hex: string) => (hex.charAt(0) === '#' ? hex.slice(1) : hex)

const parseHex = (nakedHex: string) => {
  const isShort = nakedHex.length === 3 || nakedHex.length === 4

  const twoDigitHexR = isShort ? `${nakedHex.slice(0, 1)}${nakedHex.slice(0, 1)}` : nakedHex.slice(0, 2)
  const twoDigitHexG = isShort ? `${nakedHex.slice(1, 2)}${nakedHex.slice(1, 2)}` : nakedHex.slice(2, 4)
  const twoDigitHexB = isShort ? `${nakedHex.slice(2, 3)}${nakedHex.slice(2, 3)}` : nakedHex.slice(4, 6)
  const twoDigitHexA = (isShort ? `${nakedHex.slice(3, 4)}${nakedHex.slice(3, 4)}` : nakedHex.slice(6, 8)) || 'ff'

  return {
    r: twoDigitHexR,
    g: twoDigitHexG,
    b: twoDigitHexB,
    a: twoDigitHexA,
  }
}

const hexToDecimal = (hex: string) => parseInt(hex, 16)

const hexesToDecimals = ({ r, g, b, a }: UnparsedRGBA) => ({
  r: hexToDecimal(r),
  g: hexToDecimal(g),
  b: hexToDecimal(b),
  a: +(hexToDecimal(a) / 255).toFixed(2),
})

export default function hexToRgba(hex: string) {
  const hashlessHex = removeHash(hex)
  const hexObject = parseHex(hashlessHex)
  return hexesToDecimals(hexObject)
}
