<script>
export default {
  name: 'OutsideBottomIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1233_20172)">
      <path
        d="M4.36109 7.19898C4.56051 7.19898 4.72217 7.03731 4.72217 6.83789C4.72217 6.63847 4.56051 6.47681 4.36109 6.47681C4.16166 6.47681 4 6.63847 4 6.83789C4 7.03731 4.16166 7.19898 4.36109 7.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.59253 8.43799C5.79195 8.43799 5.95362 8.27633 5.95362 8.07691C5.95362 7.87748 5.79195 7.71582 5.59253 7.71582C5.39311 7.71582 5.23145 7.87748 5.23145 8.07691C5.23145 8.27633 5.39311 8.43799 5.59253 8.43799Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M4.36109 9.67383C4.56051 9.67383 4.72217 9.51217 4.72217 9.31275C4.72217 9.11332 4.56051 8.95166 4.36109 8.95166C4.16166 8.95166 4 9.11332 4 9.31275C4 9.51217 4.16166 9.67383 4.36109 9.67383Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.59253 10.9128C5.79195 10.9128 5.95362 10.7512 5.95362 10.5518C5.95362 10.3523 5.79195 10.1907 5.59253 10.1907C5.39311 10.1907 5.23145 10.3523 5.23145 10.5518C5.23145 10.7512 5.39311 10.9128 5.59253 10.9128Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M4.36109 12.1506C4.56051 12.1506 4.72217 11.989 4.72217 11.7896C4.72217 11.5901 4.56051 11.4285 4.36109 11.4285C4.16166 11.4285 4 11.5901 4 11.7896C4 11.989 4.16166 12.1506 4.36109 12.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.59253 13.3897C5.79195 13.3897 5.95362 13.228 5.95362 13.0286C5.95362 12.8291 5.79195 12.6675 5.59253 12.6675C5.39311 12.6675 5.23145 12.8291 5.23145 13.0286C5.23145 13.228 5.39311 13.3897 5.59253 13.3897Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M4.36109 14.6274C4.56051 14.6274 4.72217 14.4658 4.72217 14.2664C4.72217 14.0669 4.56051 13.9053 4.36109 13.9053C4.16166 13.9053 4 14.0669 4 14.2664C4 14.4658 4.16166 14.6274 4.36109 14.6274Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.84058 4.72217C7.04 4.72217 7.20166 4.56051 7.20166 4.36109C7.20166 4.16166 7.04 4 6.84058 4C6.64116 4 6.47949 4.16166 6.47949 4.36109C6.47949 4.56051 6.64116 4.72217 6.84058 4.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M8.07202 5.96118C8.27145 5.96118 8.43311 5.79952 8.43311 5.6001C8.43311 5.40068 8.27145 5.23901 8.07202 5.23901C7.8726 5.23901 7.71094 5.40068 7.71094 5.6001C7.71094 5.79952 7.8726 5.96118 8.07202 5.96118Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M4.36109 4.72217C4.56051 4.72217 4.72217 4.56051 4.72217 4.36109C4.72217 4.16166 4.56051 4 4.36109 4C4.16166 4 4 4.16166 4 4.36109C4 4.56051 4.16166 4.72217 4.36109 4.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.59253 5.96118C5.79195 5.96118 5.95362 5.79952 5.95362 5.6001C5.95362 5.40068 5.79195 5.23901 5.59253 5.23901C5.39311 5.23901 5.23145 5.40068 5.23145 5.6001C5.23145 5.79952 5.39311 5.96118 5.59253 5.96118Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.84058 7.19898C7.04 7.19898 7.20166 7.03731 7.20166 6.83789C7.20166 6.63847 7.04 6.47681 6.84058 6.47681C6.64116 6.47681 6.47949 6.63847 6.47949 6.83789C6.47949 7.03731 6.64116 7.19898 6.84058 7.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M8.07202 8.43799C8.27145 8.43799 8.43311 8.27633 8.43311 8.07691C8.43311 7.87748 8.27145 7.71582 8.07202 7.71582C7.8726 7.71582 7.71094 7.87748 7.71094 8.07691C7.71094 8.27633 7.8726 8.43799 8.07202 8.43799Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.84058 9.67383C7.04 9.67383 7.20166 9.51217 7.20166 9.31275C7.20166 9.11332 7.04 8.95166 6.84058 8.95166C6.64116 8.95166 6.47949 9.11332 6.47949 9.31275C6.47949 9.51217 6.64116 9.67383 6.84058 9.67383Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M8.07202 10.9128C8.27145 10.9128 8.43311 10.7512 8.43311 10.5518C8.43311 10.3523 8.27145 10.1907 8.07202 10.1907C7.8726 10.1907 7.71094 10.3523 7.71094 10.5518C7.71094 10.7512 7.8726 10.9128 8.07202 10.9128Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.84058 12.1506C7.04 12.1506 7.20166 11.989 7.20166 11.7896C7.20166 11.5901 7.04 11.4285 6.84058 11.4285C6.64116 11.4285 6.47949 11.5901 6.47949 11.7896C6.47949 11.989 6.64116 12.1506 6.84058 12.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M8.07202 13.3897C8.27145 13.3897 8.43311 13.228 8.43311 13.0286C8.43311 12.8291 8.27145 12.6675 8.07202 12.6675C7.8726 12.6675 7.71094 12.8291 7.71094 13.0286C7.71094 13.228 7.8726 13.3897 8.07202 13.3897Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.84058 14.6274C7.04 14.6274 7.20166 14.4658 7.20166 14.2664C7.20166 14.0669 7.04 13.9053 6.84058 13.9053C6.64116 13.9053 6.47949 14.0669 6.47949 14.2664C6.47949 14.4658 6.64116 14.6274 6.84058 14.6274Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M9.31226 4.72217C9.51168 4.72217 9.67334 4.56051 9.67334 4.36109C9.67334 4.16166 9.51168 4 9.31226 4C9.11284 4 8.95117 4.16166 8.95117 4.36109C8.95117 4.56051 9.11284 4.72217 9.31226 4.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.5437 5.96118C10.7431 5.96118 10.9048 5.79952 10.9048 5.6001C10.9048 5.40068 10.7431 5.23901 10.5437 5.23901C10.3443 5.23901 10.1826 5.40068 10.1826 5.6001C10.1826 5.79952 10.3443 5.96118 10.5437 5.96118Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M9.31226 7.19898C9.51168 7.19898 9.67334 7.03731 9.67334 6.83789C9.67334 6.63847 9.51168 6.47681 9.31226 6.47681C9.11284 6.47681 8.95117 6.63847 8.95117 6.83789C8.95117 7.03731 9.11284 7.19898 9.31226 7.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.5437 8.43799C10.7431 8.43799 10.9048 8.27633 10.9048 8.07691C10.9048 7.87748 10.7431 7.71582 10.5437 7.71582C10.3443 7.71582 10.1826 7.87748 10.1826 8.07691C10.1826 8.27633 10.3443 8.43799 10.5437 8.43799Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M9.31226 9.67383C9.51168 9.67383 9.67334 9.51217 9.67334 9.31275C9.67334 9.11332 9.51168 8.95166 9.31226 8.95166C9.11284 8.95166 8.95117 9.11332 8.95117 9.31275C8.95117 9.51217 9.11284 9.67383 9.31226 9.67383Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.5437 10.9128C10.7431 10.9128 10.9048 10.7512 10.9048 10.5518C10.9048 10.3523 10.7431 10.1907 10.5437 10.1907C10.3443 10.1907 10.1826 10.3523 10.1826 10.5518C10.1826 10.7512 10.3443 10.9128 10.5437 10.9128Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M9.31226 12.1506C9.51168 12.1506 9.67334 11.989 9.67334 11.7896C9.67334 11.5901 9.51168 11.4285 9.31226 11.4285C9.11284 11.4285 8.95117 11.5901 8.95117 11.7896C8.95117 11.989 9.11284 12.1506 9.31226 12.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.5437 13.3897C10.7431 13.3897 10.9048 13.228 10.9048 13.0286C10.9048 12.8291 10.7431 12.6675 10.5437 12.6675C10.3443 12.6675 10.1826 12.8291 10.1826 13.0286C10.1826 13.228 10.3443 13.3897 10.5437 13.3897Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M9.31226 14.6274C9.51168 14.6274 9.67334 14.4658 9.67334 14.2664C9.67334 14.0669 9.51168 13.9053 9.31226 13.9053C9.11284 13.9053 8.95117 14.0669 8.95117 14.2664C8.95117 14.4658 9.11284 14.6274 9.31226 14.6274Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M11.7927 4.72217C11.9921 4.72217 12.1538 4.56051 12.1538 4.36109C12.1538 4.16166 11.9921 4 11.7927 4C11.5933 4 11.4316 4.16166 11.4316 4.36109C11.4316 4.56051 11.5933 4.72217 11.7927 4.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M13.0242 5.96118C13.2236 5.96118 13.3853 5.79952 13.3853 5.6001C13.3853 5.40068 13.2236 5.23901 13.0242 5.23901C12.8247 5.23901 12.6631 5.40068 12.6631 5.6001C12.6631 5.79952 12.8247 5.96118 13.0242 5.96118Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M11.7927 7.19898C11.9921 7.19898 12.1538 7.03731 12.1538 6.83789C12.1538 6.63847 11.9921 6.47681 11.7927 6.47681C11.5933 6.47681 11.4316 6.63847 11.4316 6.83789C11.4316 7.03731 11.5933 7.19898 11.7927 7.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M13.0242 8.43799C13.2236 8.43799 13.3853 8.27633 13.3853 8.07691C13.3853 7.87748 13.2236 7.71582 13.0242 7.71582C12.8247 7.71582 12.6631 7.87748 12.6631 8.07691C12.6631 8.27633 12.8247 8.43799 13.0242 8.43799Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M11.7927 9.67383C11.9921 9.67383 12.1538 9.51217 12.1538 9.31275C12.1538 9.11332 11.9921 8.95166 11.7927 8.95166C11.5933 8.95166 11.4316 9.11332 11.4316 9.31275C11.4316 9.51217 11.5933 9.67383 11.7927 9.67383Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M13.0242 10.9128C13.2236 10.9128 13.3853 10.7512 13.3853 10.5518C13.3853 10.3523 13.2236 10.1907 13.0242 10.1907C12.8247 10.1907 12.6631 10.3523 12.6631 10.5518C12.6631 10.7512 12.8247 10.9128 13.0242 10.9128Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M11.7927 12.1506C11.9921 12.1506 12.1538 11.989 12.1538 11.7896C12.1538 11.5901 11.9921 11.4285 11.7927 11.4285C11.5933 11.4285 11.4316 11.5901 11.4316 11.7896C11.4316 11.989 11.5933 12.1506 11.7927 12.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M13.0242 13.3897C13.2236 13.3897 13.3853 13.228 13.3853 13.0286C13.3853 12.8291 13.2236 12.6675 13.0242 12.6675C12.8247 12.6675 12.6631 12.8291 12.6631 13.0286C12.6631 13.228 12.8247 13.3897 13.0242 13.3897Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M11.7927 14.6274C11.9921 14.6274 12.1538 14.4658 12.1538 14.2664C12.1538 14.0669 11.9921 13.9053 11.7927 13.9053C11.5933 13.9053 11.4316 14.0669 11.4316 14.2664C11.4316 14.4658 11.5933 14.6274 11.7927 14.6274Z"
        :fill="`var(--${color})`"
      />
    </g>
    <rect x="3.5" y="3.5" width="9" height="11" rx="1.5" :stroke="`var(--${color})`" />
    <path
      d="M14 8C14 7.44772 14.4477 7 15 7C15.5523 7 16 7.44772 16 8V14C16 14.5523 15.5523 15 15 15C14.4477 15 14 14.5523 14 14V8Z"
      :fill="`var(--${color})`"
    />
    <defs>
      <clipPath id="clip0_1233_20172">
        <rect x="3" y="3" width="10" height="12" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
