import { computed } from '@vue/reactivity'
import debounce from 'lodash.debounce'
import { Ref, ref, onBeforeMount, reactive, watch } from 'vue'
import StylesState from '@/globalState/StylesState'
import Font from '@/models/fonts/Font'
import StoreFonts from '@/models/fonts/StoreFonts'
import Typography from '@/models/fonts/Typography'
import ITypographyStyle from '@/models/fonts/TypographyStyle'
import FontUtils from '@/util/data/FontUtils'
import useFontsService from '../api/useFontsService'
import useStores from '../globalState/useStores'

export default function useStoreTypography(preloadAvailableFonts = true) {
  const fontsService = useFontsService()
  const storesState = useStores()

  const storeFonts: Ref<StoreFonts | null> = ref(null)
  const fontFamilyQuery = ref('')

  const setFontFamilyQuery = debounce(
    (query: string) => {
      fontFamilyQuery.value = query
      fontsService.getStoreFonts(fontFamilyQuery.value).then((value) => (storeFonts.value = value))
    },
    500,
    {
      leading: true,
    }
  )

  onBeforeMount(() => {
    if (!StylesState.isInitialised && !StylesState.isInitialising) {
      StylesState.isInitialising = true
      fontsService.getStoreTypography().then((value) => {
        if (value.styles.length === 0) {
          fontsService
            .updateStoreTypography({ preset: 'custom', styles: FontUtils.generateBasicTypographyStyles() })
            .then(() => {
              fontsService.getStoreTypography().then((typography) => {
                StylesState.setTypography(typography)
                StylesState.isInitialised = true
                StylesState.isInitialising = false
              })
            })
            .catch(() => {
              StylesState.isInitialising = false
            })
        } else {
          StylesState.setTypography(value)
          StylesState.isInitialised = true
          StylesState.isInitialising = false
        }
      })
    }
    if (preloadAvailableFonts)
      fontsService.getStoreFonts(fontFamilyQuery.value).then((value) => (storeFonts.value = value))
  })

  watch(
    () => storesState.currentStoreId,
    () => (StylesState.isInitialised = false)
  )

  const addTypographyStyle = async () => {
    if (!StylesState.typography || !storeFonts.value) return

    const newTypographyStyle = FontUtils.generateNewTypographyStyle(storeFonts.value, StylesState.typography)
    if (!newTypographyStyle) return

    const newTypography: Typography = {
      preset: 'custom',
      styles: [...StylesState.typography.styles, newTypographyStyle],
    }

    await fontsService.updateStoreTypography(newTypography)
    fontsService.getStoreTypography().then(StylesState.setTypography)
    fontsService.getStoreFonts(fontFamilyQuery.value).then((value) => (storeFonts.value = value))
    window.updateStoreStyles({ typography: newTypography })
  }

  const updateTypographyStyleByHandle = async (
    handle: string,
    updater: (typographyStyle: ITypographyStyle) => void
  ) => {
    if (!StylesState.typography) return

    const newTypography: Typography = {
      preset: 'custom',
      styles: [...StylesState.typography.styles],
    }

    const newTypographyStyle = newTypography.styles.find((item) => item.handle === handle)
    if (!newTypographyStyle) return

    updater(newTypographyStyle)
    await fontsService.updateStoreTypography(newTypography)
    fontsService.getStoreTypography().then(StylesState.setTypography)
    fontsService.getStoreFonts(fontFamilyQuery.value).then((value) => (storeFonts.value = value))
    window.updateStoreStyles({ typography: newTypography })
  }

  const setTypographyStyleFont = (typographyStyle: ITypographyStyle, font: Font) => {
    updateTypographyStyleByHandle(typographyStyle.handle, (typographyStyle) => {
      typographyStyle.font = font
    })
  }

  const setTypographyStyleFontStyle = (typographyStyle: ITypographyStyle, style: string) => {
    updateTypographyStyleByHandle(typographyStyle.handle, (typographyStyle) => {
      typographyStyle.settings.style = style
    })
  }

  const setTypographyStyleFontWeight = (typographyStyle: ITypographyStyle, weight: number) => {
    updateTypographyStyleByHandle(typographyStyle.handle, (typographyStyle) => {
      typographyStyle.settings.weight = weight
    })
  }

  const setTypographyStyleLineHeight = (typographyStyle: ITypographyStyle, lineHeight: number) => {
    updateTypographyStyleByHandle(typographyStyle.handle, (typographyStyle) => {
      typographyStyle.settings.lineHeight = lineHeight
    })
  }

  const setTypographyStyleName = (typographyStyle: ITypographyStyle, name: string) => {
    updateTypographyStyleByHandle(typographyStyle.handle, (typographyStyle) => {
      typographyStyle.name = name
    })
  }

  const deleteTypographyStyle = async (typographyStyle: ITypographyStyle) => {
    if (!StylesState.typography) return

    const newTypography: Typography = {
      preset: 'custom',
      styles: [...StylesState.typography.styles.filter((item) => item.handle !== typographyStyle.handle)],
    }

    await fontsService.updateStoreTypography(newTypography)
    fontsService.getStoreTypography().then(StylesState.setTypography)
    fontsService.getStoreFonts(fontFamilyQuery.value).then((value) => (storeFonts.value = value))
  }

  const setTypographyStyleFontSize = (
    typographyStyle: ITypographyStyle,
    size: number,
    editingViewport: 'desktop' | 'tablet' | 'mobile'
  ) => {
    updateTypographyStyleByHandle(typographyStyle.handle, (typographyStyle) => {
      typographyStyle.settings.sizes[editingViewport] = size + 'px'
    })
  }

  const typography = computed(() => StylesState.typography)

  return reactive({
    typography,
    storeFonts,
    setFontFamilyQuery,
    addTypographyStyle,
    setTypographyStyleFont,
    setTypographyStyleFontStyle,
    setTypographyStyleFontWeight,
    setTypographyStyleLineHeight,
    setTypographyStyleName,
    deleteTypographyStyle,
    setTypographyStyleFontSize,
  })
}
