import { computed, reactive, Ref, ref } from 'vue'
import { useRoute } from 'vue-router'
import { DataStorage, StorefrontPage } from '@/global'
import EditorPage from '@/models/editor/EditorPage'
import { StorefrontPageOptions } from '@/models/editor/StorefrontPage'
import PageUtils from '@/util/data/PageUtils'
import { normalizePath } from '@/util/normalize'
import usePageService from '../api/usePageService'
import useStores from '../globalState/useStores'

export default function usePages() {
  const route = useRoute()
  const storesState = useStores()
  const storeSlug = computed(() => route.params.storeSlug as string)
  const storeId = computed(() => storesState.currentStore?.id ?? '')

  const pages: Ref<EditorPage[]> = ref([])
  const data: Ref<DataStorage> = ref({ products: [], collections: [] })
  const currentPathname = ref(window.location.pathname)
  const storefrontPages = computed(() => PageUtils.transformToStorefrontPages(storeSlug.value, pages.value))
  const currentPage = computed<StorefrontPage | null>(() =>
    PageUtils.getMatchingPage(storefrontPages.value, currentPathname.value)
  )
  const currentPageId = computed(() => currentPage.value?.id ?? 'unknown')
  const pageService = usePageService(storeId, currentPageId)

  const ensurePageAndLayoutExists = async () => {
    const isLayoutExists = pages.value.some((page) => page.type === 'layout')
    const isPageExists = pages.value.some((page) => page.type === 'page')

    if (!isLayoutExists) {
      const layout = await pageService.addPage('Main', 'Main', null, true)
      pages.value.push(layout)
    }

    if (!isPageExists) {
      const layout = pages.value.find((page) => page.type === 'layout') as EditorPage
      const page = await pageService.addPage('Home', 'Home', layout.id, false)
      pages.value.push(page)
    }
  }

  const fetchPages = async () => {
    const fetchedPages = await pageService.getPages()
    pages.value = fetchedPages
  }

  const fetchData = async () => {
    const fetchedData = await pageService.getData()
    data.value = fetchedData
  }

  const setPage = async (page: EditorPage) => {
    const newPagesValue = pages.value.filter((item) => item.id !== page.id)
    newPagesValue.push(page)
    pages.value = newPagesValue
  }

  const addPage = async (page: EditorPage) => {
    const newPage = await pageService.addPage(page.name, page.path || '/', page.layout_id)
    pages.value.push(newPage)
  }

  const addLayout = async (page: EditorPage) => {
    const newPage = await pageService.addPage(page.name, null, null, true)
    pages.value.push(newPage)
  }

  const deletePage = async (pageId: string) => {
    await pageService.deletePage(storeId.value, pageId)
    pages.value = pages.value.filter((page) => page.id !== pageId)
  }

  const updatePathname = () => {
    currentPathname.value = window.location.pathname
  }

  const updateOptions = async (options: StorefrontPageOptions) => {
    if (!currentPage.value) return
    const newOptions = { ...currentPage.value.options, ...options }
    const updatedData = await pageService.updatePage(storeId.value, currentPage.value.id, { options: newOptions })

    const page = pages.value.find((el) => el.id === currentPage.value?.id)
    if (!page) return

    page.options = updatedData.options
  }

  const updatePagePath = async (path: string) => {
    if (!currentPage.value) return

    let normalizedPath = normalizePath(path)
    if (!normalizedPath.startsWith('/')) normalizedPath = '/' + normalizedPath

    const updatedData = await pageService.updatePage(storeId.value, currentPage.value.id, { path: normalizedPath })

    const page = pages.value.find((el) => el.id === currentPage.value?.id)
    if (!page || page.path === updatedData.path) return

    page.path = updatedData.path

    const baseStoreUrl = `/${storeSlug.value}/editor`
    let newPath = baseStoreUrl + page?.path ?? baseStoreUrl
    if (path.endsWith('/')) newPath = path.substring(0, path.length - 1)

    window.location.pathname = newPath
  }

  const updateLayout = async (layoutId: string) => {
    if (!currentPage.value) return
    const updatedData = await pageService.updatePage(storeId.value, currentPage.value.id, { layout_id: layoutId })
    const page = pages.value.find((el) => el.id === currentPage.value?.id)

    if (!page) return

    page.layout_id = updatedData.layout_id
    window.location.reload()
  }

  return reactive({
    pages,
    data,
    storefrontPages,
    ensurePageAndLayoutExists,
    currentPage,
    fetchPages,
    fetchData,
    setPage,
    addPage,
    addLayout,
    updatePathname,
    updatePagePath,
    updateOptions,
    updateLayout,
    deletePage,
  })
}
