<script>
export default {
  name: 'LaptopIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.700012 3.54C0.700012 2.68948 1.38949 2 2.24001 2H11.76C12.6105 2 13.3 2.68948 13.3 3.54V9.7C13.3 10.5505 12.6105 11.24 11.76 11.24H2.24001C1.38949 11.24 0.700012 10.5505 0.700012 9.7V3.54ZM2.24001 2.84C1.85341 2.84 1.54001 3.1534 1.54001 3.54V9.7C1.54001 10.0866 1.85341 10.4 2.24001 10.4H11.76C12.1466 10.4 12.46 10.0866 12.46 9.7V3.54C12.46 3.1534 12.1466 2.84 11.76 2.84H2.24001Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
