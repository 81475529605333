import { Directive, DirectiveBinding } from 'vue'

const contextScopedDirectives = new Map<string, Directive>()

export default function createDirective(
  contextName: string,
  root: Document | HTMLElement = document,
  eventType = 'click'
) {
  const callbackKey = `__${eventType}OutsideDirective`
  const handlerKey = `__${eventType}OutsideDirectiveHandler`

  const createdDirective = contextScopedDirectives.get(contextName)
  if (createdDirective) return createdDirective

  const registeredElements = new Set<any>()

  const createSelfEventHandler = (el: any) => {
    return () => {
      for (const registeredElement of registeredElements) {
        if (registeredElement === el) continue
        registeredElement[callbackKey]()
      }
    }
  }

  const createRootEventHandler = (el: any, binding: DirectiveBinding, ignoreFirstEvent = false) => {
    let isFirstEvent = ignoreFirstEvent
    return (event?: MouseEvent) => {
      if (isFirstEvent) return (isFirstEvent = false)
      if (!event) return binding.value()
      if (!el.contains(event.target)) binding.value()
    }
  }

  const eventOutsideDirective: Directive = {
    mounted(el, binding) {
      registeredElements.add(el)
      el[handlerKey] = createSelfEventHandler(el)
      el[callbackKey] = createRootEventHandler(el, binding, true)
      el.addEventListener(eventType, el[handlerKey])
      root.addEventListener(eventType, el[callbackKey])
    },

    updated(el, binding) {
      registeredElements.add(el)
      if (el[callbackKey]) root.removeEventListener(eventType, el[callbackKey])
      el[callbackKey] = createRootEventHandler(el, binding)
      root.addEventListener(eventType, el[callbackKey])
    },

    beforeUnmount(el) {
      registeredElements.delete(el)
      if (el[callbackKey]) root.removeEventListener(eventType, el[callbackKey])
      if (el[handlerKey]) el.removeEventListener(eventType, el[handlerKey])
    },
  }

  return eventOutsideDirective
}
