import { Ref } from 'vue'
import PageService from '@/api/PageService'
import Block from '@/models/editor/Block'
import EditorPage from '@/models/editor/EditorPage'
import { StorefrontPageOptions } from '@/models/editor/StorefrontPage'

export default function usePageService(storeId: Ref<string>, pageId: Ref<string>) {
  return {
    getPages() {
      return PageService.getPages(storeId.value)
    },
    getData() {
      return PageService.getData(storeId.value)
    },
    addBlockOnPage(prevBlockId: string | null, blockSid: string) {
      return PageService.addBlockOnPage(storeId.value, pageId.value, prevBlockId, blockSid)
    },
    deleteBlockFromPage(blockId: string) {
      return PageService.deleteBlockFromPage(storeId.value, pageId.value, blockId)
    },
    addPage(
      name: string,
      path: string | null,
      layoutId: string | null,
      isLayout = false,
      options: StorefrontPageOptions = {}
    ) {
      return PageService.addPage(storeId.value, name, path, layoutId, isLayout, options)
    },
    updatePage(storeId: string, pageId: string, pageSettings: Partial<EditorPage>) {
      return PageService.updatePage(storeId, pageId, { ...pageSettings })
    },
    async deletePage(storeId: string, pageId: string) {
      return PageService.deletePage(storeId, pageId)
    },
    requestBlockUpdate(block: Block) {
      return PageService.requestBlockUpdate(storeId.value, pageId.value, block)
    },
    updateBlockImmediate(block: Block) {
      return PageService.updateBlockImmediate(storeId.value, pageId.value, block)
    },
  }
}

export type ScopedPageService = ReturnType<typeof usePageService>
