import { Component } from 'vue'
import AnyEditorOrContainer from '@/models/blockConfig/AnyEditorOrContainer'
import Checkbox from './Checkbox.vue'
import CompactSelect from './CompactSelect.vue'
import InlineMultiSelect from './InlineMultiSelect.vue'
import InlineRadio from './InlineRadio.vue'
import NullEditor from './NullEditor.vue'
import PRDG030GroupTypesPicker from './PRDG030GroupTypesPicker/PRDG030GroupTypesPicker.vue'
import SizeInput from './SizeInput.vue'
import SwitchInput from './SwitchInput.vue'
import TextInput from './TextInput.vue'
import MultipleColorPicker from './color/MultipleColorPicker.vue'
import SingleColorPicker from './color/SingleColorPicker.vue'
import Group from './containers/Group.vue'
import InlineSubgroup from './containers/InlineSubgroup.vue'
import Subgroup from './containers/Subgroup.vue'
import DescriptionEditor from './description/DescriptionEditor.vue'
import FLEXElements from './flex/FLEXElements.vue'
import FLEXLayers from './flex/FLEXLayers.vue'
import MenuEditor from './menu/MenuEditor.vue'
import TextStylePicker from './textStyle/TextStylePicker.vue'

const settingEditors = {
  Description: DescriptionEditor,
  Menu: MenuEditor,
  Group,
  InlineSubgroup,
  Subgroup,
  InlineRadio,
  CompactInlineRadio: InlineRadio,
  CompactSelect,
  InlineMultiSelect,
  MultipleColorPicker,
  SizeInput,
  SingleColorPicker,
  Checkbox,
  TextInput,
  Switch: SwitchInput,
  PRDG030GroupTypesPicker,
  FLEXElements,
  FLEXLayers,
  TextStylePicker,
} as Record<string, Component>

export default settingEditors

export function resolveEditorComponent(type: AnyEditorOrContainer['type']) {
  return settingEditors[type] ?? NullEditor
}
