import Asset from '@/models/editor/Asset'
import ApiService from './ApiService'

const AssetsService = {
  async getStoreAssets(storeId: string): Promise<Asset[]> {
    const response = await ApiService.get(`/${storeId}/assets`, {
      headers: { 'store-id': storeId },
    })
    return response.data
  },

  async createStoreAsset(storeId: string, file: File): Promise<Asset> {
    const payload = new FormData()
    payload.append('asset[file]', file, file.name)
    const response = await ApiService.post(`/${storeId}/assets`, payload, {
      headers: { 'store-id': storeId },
    })
    return response.data
  },

  async updateStoreAsset(storeId: string, asset: Asset, file: File): Promise<Asset> {
    const payload = new FormData()
    payload.append('asset[file]', file, file.name)
    //payload.append('asset[file_path]', asset.file_path)
    const response = await ApiService.put(`/${storeId}/assets/${asset.id}`, payload, {
      headers: { 'store-id': storeId },
    })
    return response.data
  },
}

export default AssetsService
