<script>
export default {
  name: 'CartIcon05',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 3.20833C0 2.88617 0.261168 2.625 0.583333 2.625H10.5C10.8222 2.625 11.0833 2.88617 11.0833 3.20833V13.125C11.0833 13.4472 10.8222 13.7083 10.5 13.7083H0.583333C0.261167 13.7083 0 13.4472 0 13.125V3.20833ZM10.5 3.20833H0.583333V13.125H10.5V3.20833Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.54168 0.611111C4.74455 0.611111 4.00854 1.05858 3.61305 1.78364L3.53619 1.92454C3.32136 2.31841 3.20835 2.7642 3.20835 3.21784L3.20833 4.375C3.20833 4.54375 3.07775 4.68056 2.91667 4.68056C2.75558 4.68056 2.625 4.54375 2.625 4.375L2.62502 3.21784C2.62502 2.65785 2.76451 2.10756 3.02972 1.62135L3.10658 1.48044C3.60592 0.564973 4.53522 0 5.54168 0C6.54815 0 7.47745 0.564973 7.97679 1.48044L8.05365 1.62135C8.31885 2.10756 8.45835 2.65785 8.45835 3.21784L8.45833 4.375C8.45833 4.54375 8.32775 4.68056 8.16667 4.68056C8.00558 4.68056 7.875 4.54375 7.875 4.375L7.87502 3.21784C7.87502 2.7642 7.76201 2.31841 7.54717 1.92454L7.47032 1.78364C7.07483 1.05858 6.33881 0.611111 5.54168 0.611111Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
