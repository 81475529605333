<script>
export default {
  name: 'MediumCardSizeIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1C0 0.447715 0.447715 0 1 0H5C5.55228 0 6 0.447715 6 1V7C6 7.55228 5.55228 8 5 8H1C0.447715 8 0 7.55228 0 7V1Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
