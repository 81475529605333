import StorefrontPage from '@/models/editor/StorefrontPage'
import CollectionsPageIcon from '../icons/CollectionsPage.vue'
import HomePageIcon from '../icons/HomePage.vue'
import LayoutIcon from '../icons/Layout.vue'
import ProductPageIcon from '../icons/ProductPage.vue'
import UnknownPageIcon from '../icons/UnknownPage.vue'

export default function getIconForPage(page: StorefrontPage | null) {
  if (!page) return UnknownPageIcon

  if (page.isLayout) return LayoutIcon
  if (page.name.toLowerCase() === 'home') return HomePageIcon
  if (page.name.toLowerCase() === 'collections') return CollectionsPageIcon
  if (page.name.toLowerCase() === 'product') return ProductPageIcon

  return UnknownPageIcon
}
