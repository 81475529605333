<script>
export default {
  name: 'FlexImageIcon',
  props: {
    color: String,
    size: {
      type: String,
      default: 'medium',
      validator: (size) => ['small', 'medium', 'large'].includes(size),
    },
  },
}
</script>

<template>
  <svg
    :class="`flex-image-icon flex-image-icon--size-${size}`"
    style="pointer-events: none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.6654 2.66732H5.33203C3.85927 2.66732 2.66536 3.86122 2.66536 5.33398V18.6673C2.66536 20.1401 3.85927 21.334 5.33203 21.334H18.6654C20.1381 21.334 21.332 20.1401 21.332 18.6673V5.33398C21.332 3.86122 20.1381 2.66732 18.6654 2.66732ZM5.33203 1.33398C3.12289 1.33398 1.33203 3.12485 1.33203 5.33398V18.6673C1.33203 20.8765 3.12289 22.6673 5.33203 22.6673H18.6654C20.8745 22.6673 22.6654 20.8765 22.6654 18.6673V5.33398C22.6654 3.12485 20.8745 1.33398 18.6654 1.33398H5.33203Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3839 7.33412C14.6424 7.33935 14.8746 7.4936 14.9796 7.72989L17.9426 14.3966C18.0921 14.733 17.9406 15.127 17.6041 15.2765C17.2677 15.4261 16.8737 15.2745 16.7242 14.9381L14.3386 9.57063L12.0052 14.2958C11.907 14.4947 11.7163 14.6317 11.4965 14.6613C11.2767 14.691 11.0565 14.6093 10.9092 14.4436L9.09513 12.4027L7.21002 15.0537C6.99665 15.3537 6.58043 15.424 6.28037 15.2106C5.98031 14.9972 5.91004 14.581 6.12342 14.281L8.49379 10.9476C8.61147 10.7821 8.79817 10.6793 9.00094 10.6683C9.20371 10.6573 9.40045 10.7393 9.53536 10.8911L11.248 12.8178L13.7727 7.70546C13.8872 7.47362 14.1254 7.32889 14.3839 7.33412Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>

<style lang="scss">
.flex-image-icon--size-small {
  width: 18px;
  height: 18px;
}

.flex-image-icon--size-medium {
  width: 24px;
  height: 24px;
}

.flex-image-icon--size-large {
  width: 32px;
  height: 32px;
}
</style>
