<script>
export default {
  name: 'SizeGuideIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3928 4.66675H2.60743C2.02735 4.66675 1.55566 5.08535 1.55566 5.60008V8.40008C1.55566 8.91481 2.02735 9.33341 2.60743 9.33341H11.3928C11.9729 9.33341 12.4446 8.91481 12.4446 8.40008V5.60008C12.4446 5.08535 11.9729 4.66675 11.3928 4.66675ZM11.3928 8.40008H2.60743C2.57922 8.40008 2.55992 8.39261 2.5515 8.39261C2.54804 8.39261 2.54606 8.39355 2.54556 8.39635L2.53962 5.62155C2.54309 5.61688 2.56536 5.60008 2.60743 5.60008H3.53546V7.00008H4.52536V5.60008H5.51526V7.46675H6.50516V5.60008H7.49506V7.00008H8.48496V5.60008H9.47486V7.46675H10.4648V5.60008H11.3928C11.4319 5.60055 11.4532 5.61315 11.4547 5.60381L11.4606 8.37861C11.4571 8.38328 11.4349 8.40008 11.3928 8.40008Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
