<script>
export default {
  name: 'DescriptionPositionBottomIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 3C2 2.44772 2.44772 2 3 2H15C15.5523 2 16 2.44772 16 3V5C16 5.55228 15.5523 6 15 6H3C2.44772 6 2 5.55228 2 5V3Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 8H3V15H15V8ZM3 7C2.44772 7 2 7.44772 2 8V15C2 15.5523 2.44772 16 3 16H15C15.5523 16 16 15.5523 16 15V8C16 7.44772 15.5523 7 15 7H3Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
