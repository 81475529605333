import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import StoreSelectView from '@/views/StoreSelectView.vue'
import EditorView from '../views/EditorView.vue'
import OnboardingView from '../views/OnboardingView.vue'
import StoreSettingsView from '../views/StoreSettingsView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:storeSlug/editor/:pagePath*',
    name: 'Editor',
    component: EditorView,
  },
  {
    path: '/new-store',
    name: 'New store',
    component: OnboardingView,
  },
  {
    path: '/test',
    name: 'Test',
    component: StoreSelectView,
  },
  {
    path: '/:storeSlug/settings',
    name: 'Store settings',
    component: StoreSettingsView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach((to) => {
  window.routeStorefront(to.fullPath)
})

export default router
