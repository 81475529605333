import { defineStore } from 'pinia'

interface LeftSidebarState {
  isStylesDrawerShowen: boolean
  isPageSettingsShowen: boolean
}

const useLeftSidebar = defineStore('leftSidebar', {
  state: (): LeftSidebarState => ({ isStylesDrawerShowen: false, isPageSettingsShowen: false }),
  getters: {
    isOpened: (state) => state.isPageSettingsShowen || state.isStylesDrawerShowen,
  },
  actions: {
    openStylesDrawer() {
      this.closePageSettings()
      this.isStylesDrawerShowen = true
    },
    closeStyleDrawer() {
      this.isStylesDrawerShowen = false
    },
    openPageSettings() {
      this.closeStyleDrawer()
      this.isPageSettingsShowen = true
    },
    closePageSettings() {
      this.isPageSettingsShowen = false
    },
    close() {
      this.closePageSettings()
      this.closeStyleDrawer()
    },
  },
})

export default useLeftSidebar
