<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2727 9C16.2727 13.0166 13.0166 16.2727 9 16.2727C4.98338 16.2727 1.72727 13.0166 1.72727 9C1.72727 4.98338 4.98338 1.72727 9 1.72727C13.0166 1.72727 16.2727 4.98338 16.2727 9ZM17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9ZM8.27273 5.36364V8.27273H5.36364V9.72727H8.27273V12.6364H9.72727V9.72727H12.6364V8.27273H9.72727V5.36364H8.27273Z"
      fill="black"
    />
  </svg>
</template>
