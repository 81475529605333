import ApiService from './ApiService'

const localStorageAuthTokenKey = 'atlr-admin-auth-token'
const localStorageAccountIdKey = 'atlr-admin-account-id'

export enum AuthStatus {
  LOGGED_IN,
  MISSING_TOKEN,
}

const AuthService = {
  init(): AuthStatus {
    const authToken = AuthService.getAuthToken()
    if (authToken) {
      ApiService.setAuthToken(authToken)
      return AuthStatus.LOGGED_IN
    } else {
      return AuthStatus.MISSING_TOKEN
    }
  },

  getAuthToken() {
    return localStorage.getItem(localStorageAuthTokenKey)
  },

  getAccountId() {
    return localStorage.getItem(localStorageAccountIdKey)
  },
}

export default AuthService
