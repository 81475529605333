<script>
export default {
  name: 'HorizontalAlignLeftIcon',
  props: {
    color: {
      type: String,
      default: 'color-text-emphasized',
    },
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 15.5V2.5" :stroke="`var(--${color})`" stroke-linecap="round" />
    <rect x="5" y="10.5" width="10" height="2" :fill="`var(--${color})`" />
    <rect x="5" y="6.5" width="6" height="2" :fill="`var(--${color})`" />
  </svg>
</template>
