import { computed, reactive, watch } from 'vue'
import StylesState from '@/globalState/StylesState'
import useColorsService from '@/hooks/api/useColorsSercice'
import Color from '@/models/Color'
import ColorUtils from '@/util/data/ColorUtils'
import useStores from '../globalState/useStores'

export default function useStoreColors() {
  const colorsService = useColorsService()
  const storesState = useStores()

  const basicColors = computed(() => StylesState.basicColors)
  const customColors = computed(() => StylesState.customColors)

  watch(
    () => storesState.currentStore,
    (currentStore) => {
      if (!currentStore) return

      colorsService.getStoreColors().then((value) => {
        if (!value.styles.some((item) => item.type === 'basic')) {
          colorsService
            .setStoreColors({
              preset: 'custom',
              styles: [...ColorUtils.generateBasicColors(), ...value.styles],
            })
            .then(colorsService.getStoreColors)
            .then(StylesState.setColors)
        } else {
          StylesState.setColors(value)
        }
      })
    },
    { immediate: true }
  )

  const changeColorName = async (colorHandle: string, name: string) => {
    if (!StylesState.colors) return
    const newColorSet = {
      preset: 'custom',
      styles: StylesState.colors.styles,
    }

    const color = newColorSet.styles.find((item) => item.handle === colorHandle)
    if (!color) return

    color.name = name
    colorsService.setStoreColors(newColorSet).then(colorsService.getStoreColors).then(StylesState.setColors)
  }

  const changeColorHex = async (colorHandle: string, hex: string) => {
    if (!StylesState.colors) return
    const newColorSet = {
      preset: 'custom',
      styles: StylesState.colors.styles,
    }

    const color = newColorSet.styles.find((item) => item.handle === colorHandle)
    if (!color) return

    color.hex = hex
    colorsService.setStoreColors(newColorSet).then(colorsService.getStoreColors).then(StylesState.setColors)
    window.updateStoreStyles({ colors: newColorSet })
  }

  const deleteColor = async (colorHandle: string) => {
    if (!StylesState.colors) return
    const newColorSet = {
      preset: 'custom',
      styles: StylesState.colors.styles.filter((item) => item.handle !== colorHandle),
    }

    colorsService.setStoreColors(newColorSet).then(colorsService.getStoreColors).then(StylesState.setColors)
    window.updateStoreStyles({ colors: newColorSet })
  }

  const addColor = async () => {
    if (!StylesState.colors) return
    const newColorSet = {
      preset: StylesState.colors.preset,
      styles: StylesState.colors.styles,
    }

    const newColor: Color = {
      handle: ColorUtils.getAvailableColorHandleName(StylesState.colors),
      name: 'New color',
      type: 'custom',
      hex: '#cccccc',
    }

    newColorSet.styles.push(newColor)
    colorsService.setStoreColors(newColorSet).then(colorsService.getStoreColors).then(StylesState.setColors)
    window.updateStoreStyles({ colors: newColorSet })
  }

  return reactive({
    basicColors,
    customColors,
    changeColorName,
    changeColorHex,
    deleteColor,
    addColor,
  })
}
