export default function useModalQuestion<T>() {
  let questionResolvers: ((value: T | null) => void)[] = []

  return {
    ask() {
      return new Promise<T | null>((resolve) => {
        questionResolvers.push(resolve)
      })
    },
    answer(result: T) {
      for (const questionResolver of questionResolvers) {
        questionResolver(result)
      }
      questionResolvers = []
    },
    reject() {
      for (const questionResolver of questionResolvers) {
        questionResolver(null)
      }
      questionResolvers = []
    },
  }
}
