<script>
export default {
  name: 'HomePageIcon',
  props: {
    color: String,
    size: {
      type: String,
      default: 'large',
    },
  },
}
</script>

<template>
  <svg
    v-if="size === 'small'"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8332 11.6667V7.10151C12.8332 6.78389 12.7684 6.46962 12.6427 6.17793C12.517 5.88625 12.3331 5.62329 12.1023 5.40517L7.80134 1.34051C7.58472 1.13571 7.29793 1.02161 6.99984 1.02161C6.70174 1.02161 6.41495 1.13571 6.19834 1.34051L1.89742 5.40517C1.66655 5.62329 1.48264 5.88625 1.35696 6.17793C1.23128 6.46962 1.16647 6.78389 1.1665 7.10151V11.6667C1.1665 11.9761 1.28942 12.2728 1.50821 12.4916C1.72701 12.7104 2.02375 12.8333 2.33317 12.8333H11.6665C11.9759 12.8333 12.2727 12.7104 12.4915 12.4916C12.7103 12.2728 12.8332 11.9761 12.8332 11.6667Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke="`var(--${color})`"
    />
  </svg>

  <svg
    v-if="size === 'large'"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 15V9.13049C16.5 8.72213 16.4167 8.31807 16.2551 7.94304C16.0935 7.56802 15.8571 7.22993 15.5602 6.94949L10.0305 1.72349C9.752 1.46019 9.38327 1.31348 9 1.31348C8.61673 1.31348 8.248 1.46019 7.9695 1.72349L2.43975 6.94949C2.14292 7.22993 1.90646 7.56802 1.74487 7.94304C1.58328 8.31807 1.49996 8.72213 1.5 9.13049V15C1.5 15.3978 1.65804 15.7793 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H15C15.3978 16.5 15.7794 16.342 16.0607 16.0607C16.342 15.7793 16.5 15.3978 16.5 15Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke="`var(--${color})`"
    />
  </svg>
</template>
