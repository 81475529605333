import { StorefrontPage } from '@/global'
import Block from '@/models/editor/Block'
import EditorPage from '@/models/editor/EditorPage'
import { slugify } from '../normalize'
import BlockUtils from './BlockUtils'

const PageUtils = {
  transformToStorefrontPages(storeSlug: string, editorPages: EditorPage[]): StorefrontPage[] {
    const layouts = editorPages.filter((page) => page.type === 'layout')
    const pages = editorPages.filter((page) => page.type === 'page')
    const baseStoreUrl = `/${storeSlug}/editor`

    const result: StorefrontPage[] = []

    for (const page of pages) {
      const pageLayout = layouts.find((layout) => layout.id === page.layout_id)
      let path = baseStoreUrl + page.path ?? baseStoreUrl
      if (path.endsWith('/')) path = path.substring(0, path.length - 1)

      const transformedPage: StorefrontPage = {
        blocks: [],
        name: page.name,
        path,
        isLayout: false,
        layoutId: page.layout_id,
        id: page.id,
        options: page.options,
      }

      const sortedPageLayoutBlocks = BlockUtils.sort(pageLayout?.blocks ?? [])
      const sortedPageBlocks = BlockUtils.sort(page.blocks)

      const lastSortedPageBlock = sortedPageBlocks[sortedPageBlocks.length - 1]
      if (lastSortedPageBlock) {
        if (!lastSortedPageBlock.meta) lastSortedPageBlock.meta = {}
        lastSortedPageBlock.meta.isLastPageBlock = true
      }

      if (!sortedPageBlocks.length) {
        sortedPageBlocks.push({
          content: {},
          id: '__SRV-CONTENT-PLACEHOLDER',
          options: {},
          order: 1,
          prev_block_id: null,
          sid: '__CONTENT',
          shortId: -1,
        } as Exclude<Block, 'objects'>)
      }

      for (const block of sortedPageLayoutBlocks) {
        if (block.sid === 'YIELD') {
          transformedPage.blocks.push(...sortedPageBlocks)
        } else {
          const transformedBlock = { ...block }
          if (!transformedBlock.meta) transformedBlock.meta = {}
          transformedBlock.meta.isLayoutBlock = true
          transformedPage.blocks.push(transformedBlock)
        }
      }

      const isYieldExists = sortedPageLayoutBlocks.some((item) => item.sid === 'YIELD')
      if (!isYieldExists) transformedPage.blocks.push(...sortedPageBlocks)

      result.push(transformedPage)
    }

    const usedLayoutPaths = new Set<string>()
    for (const layout of layouts) {
      const normalizedName = slugify(layout.name)
      let counter = 0
      while (usedLayoutPaths.has(normalizedName + counter)) counter++

      const layoutPath = counter === 0 ? normalizedName : normalizedName + counter
      usedLayoutPaths.add(layoutPath)

      const transformedLayout: StorefrontPage = {
        blocks: BlockUtils.sort(layout.blocks),
        name: layout.name,
        path: baseStoreUrl + '/_layouts/' + layoutPath,
        isLayout: true,
        layoutId: layout.id,
        id: layout.id,
        options: layout.options,
      }

      const lastSortedPageBlock = transformedLayout.blocks[transformedLayout.blocks.length - 1]
      if (lastSortedPageBlock) {
        if (!lastSortedPageBlock.meta) lastSortedPageBlock.meta = {}
        lastSortedPageBlock.meta.isLastPageBlock = true
      }

      result.push(transformedLayout)
    }

    return result
  },

  getMatchingPage(pages: StorefrontPage[], path: string) {
    const pathParts = path.substring(1).split('/')

    for (const page of pages) {
      const pagePathParts = page.path.substring(1).split('/')
      if (path === '/' && page.path === '/') return page
      if (pathParts.length !== pagePathParts.length) continue

      let isMissmatching = false

      for (let i = 0; i < pagePathParts.length; i++) {
        const pagePathPart = pagePathParts[i]
        const pathPart = pathParts[i]

        if (!pathPart) {
          isMissmatching = true
          break
        }

        if (pagePathPart.startsWith(':')) continue
        if (pagePathPart === pathPart) continue

        isMissmatching = true
        break
      }

      if (!isMissmatching) return page
    }

    return null
  },

  getBlockById(page: StorefrontPage, blockId: string) {
    for (const block of page.blocks) {
      if (block.id === blockId) return block
    }

    return null
  },

  getNextBlockId(page: StorefrontPage, targetBlock: Block) {
    for (const block of page.blocks) {
      if (block.prev_block_id === targetBlock.id) return block.id
    }
    return null
  },
}

export default PageUtils
