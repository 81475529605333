<script>
export default {
  name: 'CollectionsPageIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="10.5" width="6" height="6" rx="2.5" :stroke="`var(--${color})`" />
    <rect x="1.5" y="1.5" width="6" height="6" rx="2.5" :stroke="`var(--${color})`" />
    <rect x="10.5" y="10.5" width="6" height="6" rx="2.5" :stroke="`var(--${color})`" />
    <rect x="10.5" y="1.5" width="6" height="6" rx="2.5" :stroke="`var(--${color})`" />
  </svg>
</template>
