import { computed, nextTick, reactive, Ref, ref } from 'vue'
import { StorefrontPage } from '@/global'
import Block from '@/models/editor/Block'
import ContextEditableElement from '@/models/editor/ContextEditableElement'
import InlineContextEditableElement from '@/models/editor/InlineContextEditableElement'
import Overlay from '@/models/editor/Overlay'
import PageUtils from '@/util/data/PageUtils'

const debugIsOverlaysHiddenKey = 'debug-is-overlays-hidden'
let isOverlaysHidden = JSON.parse(localStorage.getItem(debugIsOverlaysHiddenKey) ?? 'false')

const overlayDisablingStylesheet = document.createElement('style')
overlayDisablingStylesheet.innerHTML = '.block-overlay { display: none !important }'
if (isOverlaysHidden) document.head.appendChild(overlayDisablingStylesheet)

export default function useBlockOverlays(currentPage: Ref<StorefrontPage | null>) {
  const nodes: Ref<HTMLElement[]> = ref([])

  const getNodes = () => Array.from(document.querySelectorAll('[data-editor-block-overlay-id]')) as HTMLElement[]

  const resizeNodes = (nodes: HTMLElement[]) => {
    for (const node of nodes) {
      node.style.minHeight = ''

      const parent = node.parentElement
      if (!parent) continue

      let maxNeighbourHeight = parent.clientHeight
      for (const neighbourNode of parent.childNodes) {
        if (neighbourNode instanceof HTMLElement && neighbourNode.hasAttribute('data-trace-size')) {
          maxNeighbourHeight = Math.max(maxNeighbourHeight, neighbourNode.clientHeight)
        }
      }

      node.style.minHeight = maxNeighbourHeight + 'px'
    }
  }

  const updateNodesUntilNotFound = () => {
    const newNodes = getNodes()
    resizeNodes(newNodes)
    if (newNodes.length > 0) return (nodes.value = newNodes)
    requestAnimationFrame(updateNodesUntilNotFound)
  }

  const isLoaded = ref(false)

  const load = () => {
    if (isOverlaysHidden) return
    nextTick(() => {
      updateNodesUntilNotFound()
      isLoaded.value = true
    })
  }

  const unload = () => {
    nodes.value = []
    focusedBlockId.value = null
    isLoaded.value = false
  }

  window.hideOverlayControls = () => {
    if (!isOverlaysHidden) document.head.appendChild(overlayDisablingStylesheet)
    isOverlaysHidden = true
    localStorage.setItem(debugIsOverlaysHiddenKey, 'true')
    unload()
  }

  window.showOverlayControls = () => {
    if (isOverlaysHidden) document.head.removeChild(overlayDisablingStylesheet)
    isOverlaysHidden = false
    localStorage.setItem(debugIsOverlaysHiddenKey, 'false')
    load()
  }

  window.onAfterBlockRerender = load

  const focusedBlockId: Ref<string | null> = ref(null)
  const focusBlock = (block: Block) => {
    focusedBlockId.value = block.id
  }

  const blurBlock = (block: Block) => {
    if (focusedBlockId.value === block.id || focusedBlockId.value === null) {
      focusedBlockId.value = null
      focusedBlockPosition.bottom = null
      focusedBlockPosition.top = null
    }
  }

  const blurAnyBlock = () => {
    focusedBlockId.value = null
    focusedBlockPosition.bottom = null
    focusedBlockPosition.top = null
  }

  const getContextEditableElements = (element: HTMLElement) => {
    const contextEditableNodes = element.querySelectorAll('[data-context-editable]')
    const result: ContextEditableElement[] = []

    for (const contextEditableNode of contextEditableNodes) {
      if (!(contextEditableNode instanceof HTMLElement)) continue
      const contextEditableAttributeValue = contextEditableNode.getAttribute('data-context-editable')
      if (!contextEditableAttributeValue) continue

      const tags = contextEditableAttributeValue
        .split(',')
        .map((item) => item.trim())
        .filter(Boolean)

      result.push({
        node: contextEditableNode,
        tags,
      })
    }

    return result
  }

  const getInlineContextEditableElements = (element: HTMLElement) => {
    const inlineContextEditableNodes = element.querySelectorAll('[data-inline-context-editable]')
    const result: InlineContextEditableElement[] = []

    for (const inlineContextEditableNode of inlineContextEditableNodes) {
      if (!(inlineContextEditableNode instanceof HTMLElement)) continue
      const contextEditableAttributeValue = inlineContextEditableNode.getAttribute('data-inline-context-editable')
      if (!contextEditableAttributeValue) continue

      try {
        const parsedValue = JSON.parse(contextEditableAttributeValue.replaceAll("'", '"'))
        result.push({
          node: inlineContextEditableNode,
          editor: parsedValue,
        })
      } catch {}
    }

    return result
  }

  const focusedBlockPosition: { top: number | null; bottom: number | null } = reactive({ top: null, bottom: null })

  const overlays = computed(() => {
    const result: Overlay[] = []

    for (let i = 0; i < nodes.value.length; i++) {
      const overlayNode = nodes.value[i]

      const blockId = overlayNode.getAttribute('data-editor-block-overlay-id') ?? ''
      const selector = `[data-editor-block-overlay-id="${blockId}"]`
      const block = currentPage.value ? PageUtils.getBlockById(currentPage.value, blockId) : null
      const blockNode = overlayNode.closest('div.block-root') as HTMLDivElement | null
      if (!blockNode) continue

      const contextEditableElements = getContextEditableElements(blockNode)
      const inlineContextEditableElements = getInlineContextEditableElements(blockNode)
      const isFocused = focusedBlockId.value === blockId

      if (!block) continue

      result.push({
        selector,
        props: {
          pageBlocks: currentPage.value?.blocks ?? [],
          block,
          overlayNode,
          blockNode,
          isFocused,
          contextEditableElements,
          inlineContextEditableElements,
          focusedBlockPosition,
        },
      })
    }

    for (let i = 0; i < result.length; i++) {
      const overlay = result[i]
      if (overlay.props.isFocused) {
        if (i > 0) result[i - 1].props.isNextOverlayFocused = true
        if (i < result.length - 1) result[i + 1].props.isPrevOverlayFocused = true
      }
    }

    return result
  })

  const focusedBlock = computed(() => {
    return currentPage.value?.blocks.find((item) => item.id === focusedBlockId.value) ?? null
  })

  const setFocusedBlockPosition = (top: number, bottom: number) => {
    if (!focusedBlock.value) return
    focusedBlockPosition.top = top
    focusedBlockPosition.bottom = bottom
  }

  return reactive({
    load,
    unload,
    isLoaded,
    overlays,
    focusBlock,
    blurBlock,
    blurAnyBlock,
    focusedBlock,
    focusedBlockPosition,
    setFocusedBlockPosition,
  })
}
