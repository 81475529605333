<script>
export default {
  name: 'CounterIcon03',
  props: {
    color: String,
    colorCounter: String,
  },
}
</script>

<template>
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.32873 5.63H0.5V14.75H9.5V5.63H7.67127C7.61569 5.14686 7.4728 4.57314 7.18587 4.06304C6.7814 3.34398 6.0841 2.75 5 2.75C3.9159 2.75 3.2186 3.34398 2.81413 4.06304C2.5272 4.57314 2.38431 5.14686 2.32873 5.63ZM3.23733 5.63H6.76267C6.71137 5.2832 6.60321 4.89311 6.41413 4.55696C6.1436 4.07602 5.7159 3.71 5 3.71C4.2841 3.71 3.8564 4.07602 3.58587 4.55696C3.39679 4.89311 3.28863 5.2832 3.23733 5.63ZM1.4 6.59V13.79H8.6V6.59H1.4Z"
      :fill="`var(--${color})`"
    />
    <path
      d="M16.2499 5.42311C16.2499 8.09847 14.1535 10.2673 11.5675 10.2673C8.98147 10.2673 6.88508 8.09847 6.88508 5.42311C6.88508 2.74775 8.98147 0.578949 11.5675 0.578949C14.1535 0.578949 16.2499 2.74775 16.2499 5.42311Z"
      :fill="`var(--${color})`"
    />
    <path
      class="ttt"
      d="M9.98089 7.625H13.1302V7.07453H10.8023V7.05732L11.933 5.84972C12.811 4.91736 13.0504 4.50795 13.0504 3.96435V3.95747C13.0504 3.17993 12.4185 2.57785 11.5539 2.57785C10.666 2.57785 9.95096 3.21433 9.95096 4.07445V4.09509H10.5296V4.07789C10.5296 3.54462 10.9719 3.118 11.5639 3.118C12.0827 3.118 12.4485 3.48269 12.4518 3.98844V3.99532C12.4518 4.40817 12.3055 4.67653 11.6603 5.37494L9.98089 7.19838V7.625Z"
      :fill="`var(--${colorCounter})`"
    />
  </svg>
</template>
