import AllViewportsPosition from '@/models/flex/AllViewportsPosition'
import getAllViewportsPosition from './getAllViewportsPosition'

export default function handleFlexElementMovement(
  mouseDownEvent: MouseEvent,
  flexElementEl: HTMLElement,
  initialPosition: AllViewportsPosition | undefined,
  callbacks: {
    onMoveStart?: () => void
    onMove?: (position: AllViewportsPosition) => void
    onMoveEnd?: (position: AllViewportsPosition) => void
  } = {}
) {
  const initialMouseX = mouseDownEvent.clientX
  const initialMouseY = mouseDownEvent.clientY

  let isMoving = false

  const flexElementAnchorEl = flexElementEl.closest('[data-element-anchor]')
  const blockEl = flexElementEl.closest('[data-block-root]')

  if (!(blockEl instanceof HTMLElement && flexElementAnchorEl instanceof HTMLElement)) return

  const blockClientRect = blockEl.getBoundingClientRect()
  const flexElementClientRect = flexElementEl.getBoundingClientRect()

  const initialFlexElementX = flexElementClientRect.x - blockClientRect.x
  const initialFlexElementY = flexElementClientRect.y - blockClientRect.y

  const initialFlexElementWidth = flexElementClientRect.width
  const flexElementHeight = flexElementClientRect.height

  const blockHeight = blockClientRect.height
  const initialBlockWidth = blockClientRect.width

  let lastPosition: AllViewportsPosition

  const onMouseMove = (event: MouseEvent) => {
    const deltaX = event.clientX - initialMouseX
    const deltaY = event.clientY - initialMouseY

    if ((Math.abs(deltaX) > 3 || Math.abs(deltaY) > 3) && !isMoving) {
      isMoving = true
      callbacks.onMoveStart?.()
    }

    if (!isMoving) return

    let newFlexElementX = initialFlexElementX + deltaX
    if (newFlexElementX < 0) newFlexElementX = 0
    if (newFlexElementX > initialBlockWidth - initialFlexElementWidth)
      newFlexElementX = initialBlockWidth - initialFlexElementWidth

    let newFlexElementY = initialFlexElementY + deltaY
    if (newFlexElementY < 0) newFlexElementY = 0
    if (newFlexElementY > blockHeight - flexElementHeight) newFlexElementY = blockHeight - flexElementHeight

    const position: AllViewportsPosition = getAllViewportsPosition(
      initialPosition,
      initialBlockWidth,
      newFlexElementX,
      newFlexElementY,
      initialFlexElementWidth
    )

    lastPosition = position

    flexElementEl.style.transform = `translate(${newFlexElementX - initialFlexElementX}px, ${
      newFlexElementY - initialFlexElementY
    }px)`

    callbacks.onMove?.(position)
  }

  const onMouseUp = () => {
    if (isMoving) window.enableSettingReactivity()

    flexElementEl.style.transform = ''

    if (lastPosition) callbacks.onMoveEnd?.(lastPosition)

    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
  }

  document.addEventListener('mousemove', onMouseMove)
  document.addEventListener('mouseup', onMouseUp)
}
