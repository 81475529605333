<script>
export default {
  name: 'InsideBottomIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1233_17764)">
      <path
        d="M5.36109 7.19898C5.56051 7.19898 5.72217 7.03731 5.72217 6.83789C5.72217 6.63847 5.56051 6.47681 5.36109 6.47681C5.16166 6.47681 5 6.63847 5 6.83789C5 7.03731 5.16166 7.19898 5.36109 7.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.59253 8.43799C6.79195 8.43799 6.95362 8.27633 6.95362 8.07691C6.95362 7.87748 6.79195 7.71582 6.59253 7.71582C6.39311 7.71582 6.23145 7.87748 6.23145 8.07691C6.23145 8.27633 6.39311 8.43799 6.59253 8.43799Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.36109 9.67383C5.56051 9.67383 5.72217 9.51217 5.72217 9.31275C5.72217 9.11332 5.56051 8.95166 5.36109 8.95166C5.16166 8.95166 5 9.11332 5 9.31275C5 9.51217 5.16166 9.67383 5.36109 9.67383Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.59253 10.9128C6.79195 10.9128 6.95362 10.7512 6.95362 10.5518C6.95362 10.3523 6.79195 10.1907 6.59253 10.1907C6.39311 10.1907 6.23145 10.3523 6.23145 10.5518C6.23145 10.7512 6.39311 10.9128 6.59253 10.9128Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.36109 12.1506C5.56051 12.1506 5.72217 11.989 5.72217 11.7896C5.72217 11.5901 5.56051 11.4285 5.36109 11.4285C5.16166 11.4285 5 11.5901 5 11.7896C5 11.989 5.16166 12.1506 5.36109 12.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.59253 13.3897C6.79195 13.3897 6.95362 13.228 6.95362 13.0286C6.95362 12.8291 6.79195 12.6675 6.59253 12.6675C6.39311 12.6675 6.23145 12.8291 6.23145 13.0286C6.23145 13.228 6.39311 13.3897 6.59253 13.3897Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.36109 14.6274C5.56051 14.6274 5.72217 14.4658 5.72217 14.2664C5.72217 14.0669 5.56051 13.9053 5.36109 13.9053C5.16166 13.9053 5 14.0669 5 14.2664C5 14.4658 5.16166 14.6274 5.36109 14.6274Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M7.84058 4.72217C8.04 4.72217 8.20166 4.56051 8.20166 4.36109C8.20166 4.16166 8.04 4 7.84058 4C7.64116 4 7.47949 4.16166 7.47949 4.36109C7.47949 4.56051 7.64116 4.72217 7.84058 4.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M9.07202 5.96118C9.27145 5.96118 9.43311 5.79952 9.43311 5.6001C9.43311 5.40068 9.27145 5.23901 9.07202 5.23901C8.8726 5.23901 8.71094 5.40068 8.71094 5.6001C8.71094 5.79952 8.8726 5.96118 9.07202 5.96118Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M5.36109 4.72217C5.56051 4.72217 5.72217 4.56051 5.72217 4.36109C5.72217 4.16166 5.56051 4 5.36109 4C5.16166 4 5 4.16166 5 4.36109C5 4.56051 5.16166 4.72217 5.36109 4.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M6.59253 5.96118C6.79195 5.96118 6.95362 5.79952 6.95362 5.6001C6.95362 5.40068 6.79195 5.23901 6.59253 5.23901C6.39311 5.23901 6.23145 5.40068 6.23145 5.6001C6.23145 5.79952 6.39311 5.96118 6.59253 5.96118Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M7.84058 7.19898C8.04 7.19898 8.20166 7.03731 8.20166 6.83789C8.20166 6.63847 8.04 6.47681 7.84058 6.47681C7.64116 6.47681 7.47949 6.63847 7.47949 6.83789C7.47949 7.03731 7.64116 7.19898 7.84058 7.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M9.07202 8.43799C9.27145 8.43799 9.43311 8.27633 9.43311 8.07691C9.43311 7.87748 9.27145 7.71582 9.07202 7.71582C8.8726 7.71582 8.71094 7.87748 8.71094 8.07691C8.71094 8.27633 8.8726 8.43799 9.07202 8.43799Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M7.84058 9.67383C8.04 9.67383 8.20166 9.51217 8.20166 9.31275C8.20166 9.11332 8.04 8.95166 7.84058 8.95166C7.64116 8.95166 7.47949 9.11332 7.47949 9.31275C7.47949 9.51217 7.64116 9.67383 7.84058 9.67383Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M9.07202 10.9128C9.27145 10.9128 9.43311 10.7512 9.43311 10.5518C9.43311 10.3523 9.27145 10.1907 9.07202 10.1907C8.8726 10.1907 8.71094 10.3523 8.71094 10.5518C8.71094 10.7512 8.8726 10.9128 9.07202 10.9128Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M7.84058 12.1506C8.04 12.1506 8.20166 11.989 8.20166 11.7896C8.20166 11.5901 8.04 11.4285 7.84058 11.4285C7.64116 11.4285 7.47949 11.5901 7.47949 11.7896C7.47949 11.989 7.64116 12.1506 7.84058 12.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M9.07202 13.3897C9.27145 13.3897 9.43311 13.228 9.43311 13.0286C9.43311 12.8291 9.27145 12.6675 9.07202 12.6675C8.8726 12.6675 8.71094 12.8291 8.71094 13.0286C8.71094 13.228 8.8726 13.3897 9.07202 13.3897Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M7.84058 14.6274C8.04 14.6274 8.20166 14.4658 8.20166 14.2664C8.20166 14.0669 8.04 13.9053 7.84058 13.9053C7.64116 13.9053 7.47949 14.0669 7.47949 14.2664C7.47949 14.4658 7.64116 14.6274 7.84058 14.6274Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.3123 4.72217C10.5117 4.72217 10.6733 4.56051 10.6733 4.36109C10.6733 4.16166 10.5117 4 10.3123 4C10.1128 4 9.95117 4.16166 9.95117 4.36109C9.95117 4.56051 10.1128 4.72217 10.3123 4.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M11.5437 5.96118C11.7431 5.96118 11.9048 5.79952 11.9048 5.6001C11.9048 5.40068 11.7431 5.23901 11.5437 5.23901C11.3443 5.23901 11.1826 5.40068 11.1826 5.6001C11.1826 5.79952 11.3443 5.96118 11.5437 5.96118Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.3123 7.19898C10.5117 7.19898 10.6733 7.03731 10.6733 6.83789C10.6733 6.63847 10.5117 6.47681 10.3123 6.47681C10.1128 6.47681 9.95117 6.63847 9.95117 6.83789C9.95117 7.03731 10.1128 7.19898 10.3123 7.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M11.5437 8.43799C11.7431 8.43799 11.9048 8.27633 11.9048 8.07691C11.9048 7.87748 11.7431 7.71582 11.5437 7.71582C11.3443 7.71582 11.1826 7.87748 11.1826 8.07691C11.1826 8.27633 11.3443 8.43799 11.5437 8.43799Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.3123 9.67383C10.5117 9.67383 10.6733 9.51217 10.6733 9.31275C10.6733 9.11332 10.5117 8.95166 10.3123 8.95166C10.1128 8.95166 9.95117 9.11332 9.95117 9.31275C9.95117 9.51217 10.1128 9.67383 10.3123 9.67383Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M11.5437 10.9128C11.7431 10.9128 11.9048 10.7512 11.9048 10.5518C11.9048 10.3523 11.7431 10.1907 11.5437 10.1907C11.3443 10.1907 11.1826 10.3523 11.1826 10.5518C11.1826 10.7512 11.3443 10.9128 11.5437 10.9128Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.3123 12.1506C10.5117 12.1506 10.6733 11.989 10.6733 11.7896C10.6733 11.5901 10.5117 11.4285 10.3123 11.4285C10.1128 11.4285 9.95117 11.5901 9.95117 11.7896C9.95117 11.989 10.1128 12.1506 10.3123 12.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M11.5437 13.3897C11.7431 13.3897 11.9048 13.228 11.9048 13.0286C11.9048 12.8291 11.7431 12.6675 11.5437 12.6675C11.3443 12.6675 11.1826 12.8291 11.1826 13.0286C11.1826 13.228 11.3443 13.3897 11.5437 13.3897Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M10.3123 14.6274C10.5117 14.6274 10.6733 14.4658 10.6733 14.2664C10.6733 14.0669 10.5117 13.9053 10.3123 13.9053C10.1128 13.9053 9.95117 14.0669 9.95117 14.2664C9.95117 14.4658 10.1128 14.6274 10.3123 14.6274Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M12.7927 4.72217C12.9921 4.72217 13.1538 4.56051 13.1538 4.36109C13.1538 4.16166 12.9921 4 12.7927 4C12.5933 4 12.4316 4.16166 12.4316 4.36109C12.4316 4.56051 12.5933 4.72217 12.7927 4.72217Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M14.0242 5.96118C14.2236 5.96118 14.3853 5.79952 14.3853 5.6001C14.3853 5.40068 14.2236 5.23901 14.0242 5.23901C13.8247 5.23901 13.6631 5.40068 13.6631 5.6001C13.6631 5.79952 13.8247 5.96118 14.0242 5.96118Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M12.7927 7.19898C12.9921 7.19898 13.1538 7.03731 13.1538 6.83789C13.1538 6.63847 12.9921 6.47681 12.7927 6.47681C12.5933 6.47681 12.4316 6.63847 12.4316 6.83789C12.4316 7.03731 12.5933 7.19898 12.7927 7.19898Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M14.0242 8.43799C14.2236 8.43799 14.3853 8.27633 14.3853 8.07691C14.3853 7.87748 14.2236 7.71582 14.0242 7.71582C13.8247 7.71582 13.6631 7.87748 13.6631 8.07691C13.6631 8.27633 13.8247 8.43799 14.0242 8.43799Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M12.7927 9.67383C12.9921 9.67383 13.1538 9.51217 13.1538 9.31275C13.1538 9.11332 12.9921 8.95166 12.7927 8.95166C12.5933 8.95166 12.4316 9.11332 12.4316 9.31275C12.4316 9.51217 12.5933 9.67383 12.7927 9.67383Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M14.0242 10.9128C14.2236 10.9128 14.3853 10.7512 14.3853 10.5518C14.3853 10.3523 14.2236 10.1907 14.0242 10.1907C13.8247 10.1907 13.6631 10.3523 13.6631 10.5518C13.6631 10.7512 13.8247 10.9128 14.0242 10.9128Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M12.7927 12.1506C12.9921 12.1506 13.1538 11.989 13.1538 11.7896C13.1538 11.5901 12.9921 11.4285 12.7927 11.4285C12.5933 11.4285 12.4316 11.5901 12.4316 11.7896C12.4316 11.989 12.5933 12.1506 12.7927 12.1506Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M14.0242 13.3897C14.2236 13.3897 14.3853 13.228 14.3853 13.0286C14.3853 12.8291 14.2236 12.6675 14.0242 12.6675C13.8247 12.6675 13.6631 12.8291 13.6631 13.0286C13.6631 13.228 13.8247 13.3897 14.0242 13.3897Z"
        :fill="`var(--${color})`"
      />
      <path
        d="M12.7927 14.6274C12.9921 14.6274 13.1538 14.4658 13.1538 14.2664C13.1538 14.0669 12.9921 13.9053 12.7927 13.9053C12.5933 13.9053 12.4316 14.0669 12.4316 14.2664C12.4316 14.4658 12.5933 14.6274 12.7927 14.6274Z"
        :fill="`var(--${color})`"
      />
    </g>
    <rect x="4.5" y="3.5" width="9" height="11" rx="1.5" :stroke="`var(--${color})`" />
    <path
      d="M10 10C10 9.44772 10.4477 9 11 9C11.5523 9 12 9.44772 12 10V16C12 16.5523 11.5523 17 11 17C10.4477 17 10 16.5523 10 16V10Z"
      :fill="`var(--${color})`"
    />
    <defs>
      <clipPath id="clip0_1233_17764">
        <rect x="4" y="3" width="10" height="12" rx="2" fill="black" />
      </clipPath>
    </defs>
  </svg>
</template>
