<script>
export default {
  name: 'ImageColumnsSingleIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="2" width="10" height="14" rx="1" :fill="`var(--${color})`" />
  </svg>
</template>
