<script>
export default {
  name: 'ArrowIcon',
  props: {
    color: String,
    direction: String,
  },
}
</script>

<template>
  <svg
    v-if="direction === 'up'"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.69763 7.34915C6.85714 7.16496 7.14286 7.16496 7.30237 7.34915L9.02489 9.33814C9.24924 9.5972 9.06522 10 8.72252 10H5.27748C4.93478 10 4.75076 9.5972 4.97511 9.33814L6.69763 7.34915Z"
      :fill="`var(--${color})`"
    />
  </svg>

  <svg v-else width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.69763 9.65085C6.85714 9.83504 7.14286 9.83504 7.30237 9.65085L9.02489 7.66186C9.24924 7.4028 9.06522 7 8.72252 7H5.27748C4.93478 7 4.75076 7.4028 4.97511 7.66186L6.69763 9.65085Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
