import Color from '@/models/Color'
import ColorSet from '@/models/ColorSet'

export default {
  getAvailableColorHandleName(colorSet: ColorSet) {
    const handlePrefix = 'color-'
    let count = 1
    let handle = handlePrefix + count

    while (colorSet.styles.find((item) => item.handle === handle)) {
      count++
      handle = handlePrefix + count
    }

    return handle
  },

  generateBasicColors(): Color[] {
    return [
      {
        handle: 'color-primary',
        name: 'Primary',
        hex: '#1c1c1c',
        type: 'basic',
      },
      {
        handle: 'color-secondary',
        name: 'Secondary',
        hex: '#5b5b5b',
        type: 'basic',
      },
      {
        handle: 'color-ui',
        name: 'UI',
        hex: '#ababab',
        type: 'basic',
      },
      {
        handle: 'color-background',
        name: 'Background',
        hex: '#ffffff',
        type: 'basic',
      },
    ]
  },
}
