import { onBeforeUnmount, onMounted } from 'vue'

export default function useHotkey(key: string, onPress: () => void) {
  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === key) onPress()
  }

  onMounted(() => {
    window.addEventListener('keydown', onKeyPress)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', onKeyPress)
  })
}
