<script>
export default {
  name: 'TooltipPointerIcon',
  props: {
    color: {
      type: String,
      default: 'color-bg-contrast',
    },
  },
}
</script>

<template>
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1008_187)">
      <path
        d="M4.43301 4.25C4.24056 4.58333 3.75944 4.58333 3.56699 4.25L0.968911 -0.250001C0.776461 -0.583334 1.01702 -1 1.40192 -1L6.59808 -1C6.98298 -1 7.22354 -0.583333 7.03109 -0.25L4.43301 4.25Z"
        :fill="`var(--${color})`"
      />
    </g>
    <defs>
      <clipPath id="clip0_1008_187">
        <rect width="8" height="5" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
