<script>
export default {
  name: 'FlexTextIcon',
  props: {
    color: String,
    size: {
      type: String,
      default: 'medium',
      validator: (size) => ['small', 'medium', 'large'].includes(size),
    },
  },
}
</script>

<template>
  <svg
    :class="`flex-text-icon flex-text-icon--size-${size}`"
    style="pointer-events: none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 3.5145C4 3.0459 4.39797 2.66602 4.88889 2.66602H19.1111C19.602 2.66602 20 3.0459 20 3.5145C20 3.98311 19.602 4.36299 19.1111 4.36299H12.8889V20.4842C12.8889 20.9528 12.4909 21.3327 12 21.3327C11.5091 21.3327 11.1111 20.9528 11.1111 20.4842V4.36299H4.88889C4.39797 4.36299 4 3.98311 4 3.5145Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>

<style lang="scss">
.flex-text-icon--size-small {
  width: 18px;
  height: 18px;
}

.flex-text-icon--size-medium {
  width: 24px;
  height: 24px;
}

.flex-text-icon--size-large {
  width: 32px;
  height: 32px;
}
</style>
