<script>
export default {
  name: 'CookiesIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.77702" cy="7.77775" r="4.16667" fill="white" :stroke="`var(--${color})`" />
    <circle cx="6.99967" cy="6.99992" r="4.16667" fill="white" :stroke="`var(--${color})`" />
    <ellipse cx="7.38889" cy="5.05539" rx="0.388889" ry="0.388889" :fill="`var(--${color})`" />
    <circle cx="5.0549" cy="5.05539" r="0.388889" :fill="`var(--${color})`" />
    <circle cx="7.38889" cy="7.38889" r="0.388889" :fill="`var(--${color})`" />
    <ellipse cx="5.44379" cy="6.99995" rx="0.777778" ry="0.777778" :fill="`var(--${color})`" />
    <circle cx="5.83322" cy="9.72214" r="0.388889" :fill="`var(--${color})`" />
    <circle cx="9.33344" cy="8.55537" r="0.777778" :fill="`var(--${color})`" />
  </svg>
</template>
