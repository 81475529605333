<script>
export default {
  name: 'LargeCardSizeIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55228 0 10 0.447715 10 1V11C10 11.5523 9.55228 12 9 12H1C0.447716 12 0 11.5523 0 11V1Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
