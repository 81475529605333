import AllViewportsPosition from '@/models/flex/AllViewportsPosition'
import AllViewportsSize from '@/models/flex/AllViewportsSize'
import getAllViewportsPosition from './getAllViewportsPosition'
import getAllViewportsSize from './getAllViewportsSize'
import getElementAlignmentAndOffset from './getElementAlignmentAndOffset'

export default function handleFlexElementResize(
  mouseDownEvent: MouseEvent,
  dragControl: { verticalPosition: string; horizontalPosition: string },
  flexElementEl: HTMLElement,
  initialSize: AllViewportsSize | undefined,
  initialPosition: AllViewportsPosition | undefined,
  callbacks: {
    onMoveStart?: () => void
    onMove?: (position: AllViewportsPosition, size: AllViewportsSize) => void
    onMoveEnd?: (position: AllViewportsPosition, size: AllViewportsSize) => void
  }
) {
  const initialMouseX = mouseDownEvent.clientX
  const initialMouseY = mouseDownEvent.clientY

  const flexElementAnchorEl = flexElementEl.closest('[data-element-anchor]')
  const flexElementWrapEl = flexElementEl.closest('[data-element-wrap]')
  const blockEl = flexElementEl.closest('[data-block-root]')

  if (
    !(
      blockEl instanceof HTMLElement &&
      flexElementAnchorEl instanceof HTMLElement &&
      flexElementWrapEl instanceof HTMLElement
    )
  )
    return

  const blockClientRect = blockEl.getBoundingClientRect()
  const flexElementClientRect = flexElementEl.getBoundingClientRect()

  const initialFlexElementX = flexElementClientRect.x - blockClientRect.x
  const initialFlexElementY = flexElementClientRect.y - blockClientRect.y

  const initialFlexElementWidth = flexElementClientRect.width
  const initialFlexElementHeight = flexElementClientRect.height

  const initialBlockHeight = blockClientRect.height
  const initialBlockWidth = blockClientRect.width

  const { horizontalAlignment: initialHorizontalAlignment } = getElementAlignmentAndOffset(
    initialBlockWidth,
    initialFlexElementX,
    initialFlexElementY,
    initialFlexElementWidth
  )

  let lastPosition: AllViewportsPosition
  let lastSize: AllViewportsSize

  window.disableSettingsReactivity()

  const onMouseMove = (event: MouseEvent) => {
    const deltaX = event.clientX - initialMouseX
    const deltaY = event.clientY - initialMouseY

    let normalizedDeltaX = deltaX
    let normalizedDeltaY = deltaY

    if (dragControl.horizontalPosition === 'left' && deltaX < -initialFlexElementX) {
      normalizedDeltaX = -initialFlexElementX
    }

    if (
      dragControl.horizontalPosition === 'right' &&
      deltaX > initialBlockWidth - initialFlexElementX - initialFlexElementWidth
    ) {
      normalizedDeltaX = initialBlockWidth - initialFlexElementX - initialFlexElementWidth
    }

    if (dragControl.horizontalPosition === 'left' && deltaX > initialFlexElementWidth - 10) {
      normalizedDeltaX = initialFlexElementWidth - 10
    }

    if (dragControl.horizontalPosition === 'right' && -deltaX > initialFlexElementWidth - 10) {
      normalizedDeltaX = -initialFlexElementWidth + 10
    }

    if (dragControl.verticalPosition === 'top' && deltaY < -initialFlexElementY) {
      normalizedDeltaY = -initialFlexElementY
    }

    if (
      dragControl.verticalPosition === 'bottom' &&
      deltaY > initialBlockHeight - initialFlexElementY - initialFlexElementHeight
    ) {
      normalizedDeltaY = initialBlockHeight - initialFlexElementY - initialFlexElementHeight
    }

    if (dragControl.verticalPosition === 'top' && deltaY > initialFlexElementHeight - 10) {
      normalizedDeltaY = initialFlexElementHeight - 10
    }

    if (dragControl.verticalPosition === 'bottom' && -deltaY > initialFlexElementHeight - 10) {
      normalizedDeltaY = -initialFlexElementHeight + 10
    }

    let newFlexElementX = initialFlexElementX
    let newFlexElementY = initialFlexElementY
    let newFlexElementWidth = initialFlexElementWidth
    let newFlexElementHeight = initialFlexElementHeight

    if (dragControl.horizontalPosition === 'left') newFlexElementX += normalizedDeltaX
    if (dragControl.verticalPosition === 'top') newFlexElementY += normalizedDeltaY

    if (dragControl.horizontalPosition === 'left') newFlexElementWidth -= normalizedDeltaX
    if (dragControl.horizontalPosition === 'right') newFlexElementWidth += normalizedDeltaX

    if (dragControl.verticalPosition === 'top') newFlexElementHeight -= normalizedDeltaY
    if (dragControl.verticalPosition === 'bottom') newFlexElementHeight += normalizedDeltaY

    const position = getAllViewportsPosition(
      initialPosition,
      initialBlockWidth,
      newFlexElementX,
      newFlexElementY,
      newFlexElementWidth
    )
    lastPosition = position

    const size = getAllViewportsSize(initialSize, newFlexElementWidth, newFlexElementHeight, initialBlockWidth)
    lastSize = size

    flexElementEl.style.transform = `translate(${newFlexElementX - initialFlexElementX}px, ${
      newFlexElementY - initialFlexElementY
    }px)`

    flexElementAnchorEl.style.width = newFlexElementWidth + 'px'
    flexElementAnchorEl.style.height = newFlexElementHeight + 'px'

    if (initialHorizontalAlignment === 'center')
      flexElementWrapEl.style.transform = `translate(${-initialFlexElementWidth / 2}px)`

    callbacks.onMove?.(position, size)
  }

  const onMouseUp = () => {
    window.enableSettingReactivity()

    flexElementEl.style.transform = ''
    flexElementAnchorEl.style.width = ''
    flexElementAnchorEl.style.height = ''
    flexElementWrapEl.style.transform = ''

    if (lastPosition && lastSize) callbacks.onMoveEnd?.(lastPosition, lastSize)

    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
  }

  document.addEventListener('mousemove', onMouseMove)
  document.addEventListener('mouseup', onMouseUp)
}
