import { transliterate, slugify as slug } from 'transliteration'

export const normalizePath = (path: string | null, separator = '_') => {
  if (!path) return ''
  const trimmedName = path.trim()

  const alphanumericParts: string[][] = [[]]

  for (const letter of trimmedName) {
    const lastAlphanumericPart = alphanumericParts[alphanumericParts.length - 1]

    const isAllowedChar = true
    // TODO: Find a better rule
    // (letter >= 'a' && letter <= 'z') || (letter >= 'A' && letter <= 'Z') || (letter >= '0' && letter <= '9')

    if (isAllowedChar) {
      lastAlphanumericPart.push(letter)
    } else if (lastAlphanumericPart.length !== 0) {
      alphanumericParts.push([])
    }
  }

  const words = alphanumericParts.map((item) => item.join(''))
  const normalizedName = words.join(separator).toLowerCase()
  return normalizedName
}

export const slugify = (str: string) => slug(transliterate(str)).replace(/[-]+/, '-')
