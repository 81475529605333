import VirtualViewportState from '@/globalState/VirtualViewportState'
import AllViewportsSize from '@/models/flex/AllViewportsSize'
import checkIsSizeSameOnAllViewports from './checkIsSizeSameOnAllViewports'

export default function getAllViewportsSize(
  initialSize: AllViewportsSize | undefined,
  newFlexElementWidth: number,
  newFlexElementHeight: number,
  initialBlockWidth: number
): AllViewportsSize {
  const isSizesSameOnAllViewports = checkIsSizeSameOnAllViewports(initialSize)

  const newFlexElementWidthWithUnit =
    newFlexElementWidth < 300 ? newFlexElementWidth + 'px' : (newFlexElementWidth / initialBlockWidth) * 100 + '%'

  const newSize = {
    height: newFlexElementHeight + 'px',
    width: newFlexElementWidthWithUnit,
  }

  if ((isSizesSameOnAllViewports && VirtualViewportState.currentViewport === 'none') || !initialSize) {
    return {
      mobile: newSize,
      tablet: newSize,
      desktop: newSize,
    }
  }

  const result: AllViewportsSize = { ...initialSize }
  if (VirtualViewportState.currentViewport === 'none') result.desktop = newSize
  if (VirtualViewportState.currentViewport === 'tablet') result.tablet = newSize
  if (VirtualViewportState.currentViewport === 'mobile') result.mobile = newSize

  return result
}
