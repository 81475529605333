<script>
export default {
  name: 'DesktopIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.700012 2.80001C0.700012 1.94949 1.38949 1.26001 2.24001 1.26001H11.76C12.6105 1.26001 13.3 1.94949 13.3 2.80001V8.96001C13.3 9.81053 12.6105 10.5 11.76 10.5H2.24001C1.38949 10.5 0.700012 9.81053 0.700012 8.96001V2.80001ZM2.24001 2.10001C1.85341 2.10001 1.54001 2.41341 1.54001 2.80001V8.96001C1.54001 9.34661 1.85341 9.66001 2.24001 9.66001H11.76C12.1466 9.66001 12.46 9.34661 12.46 8.96001V2.80001C12.46 2.41341 12.1466 2.10001 11.76 2.10001H2.24001Z"
      :fill="`var(--${color})`"
    />
    <path
      d="M4.48001 11.76C4.48001 11.4507 4.73073 11.2 5.04001 11.2H8.96001C9.26929 11.2 9.52001 11.4507 9.52001 11.76C9.52001 12.0693 9.26929 12.32 8.96001 12.32H5.04001C4.73073 12.32 4.48001 12.0693 4.48001 11.76Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
