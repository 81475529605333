<script>
export default {
  name: 'CartIcon03',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 0.583333C6.31166 0.583333 5.68026 0.965561 5.36113 1.57545L5.22459 1.83638C5.04969 2.17063 4.95833 2.54226 4.95833 2.9195V4.08333C4.95833 4.24442 4.82775 4.375 4.66667 4.375C4.50558 4.375 4.375 4.24442 4.375 4.08333V2.9195C4.375 2.44806 4.48917 1.98364 4.70774 1.56593L4.84428 1.305C5.26406 0.502771 6.09458 0 7 0C7.90542 0 8.73594 0.502771 9.15572 1.305L9.29226 1.56593C9.51083 1.98364 9.625 2.44806 9.625 2.9195V4.08333C9.625 4.24442 9.49442 4.375 9.33333 4.375C9.17225 4.375 9.04167 4.24442 9.04167 4.08333V2.9195C9.04167 2.54226 8.95031 2.17063 8.77541 1.83638L8.63887 1.57545C8.31974 0.965561 7.68834 0.583333 7 0.583333Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.366995 4.48939C0.294803 4.12843 0.570889 3.79166 0.939 3.79166H13.0608C13.4289 3.79166 13.705 4.12843 13.6328 4.48939L12.2339 11.4837C12.0431 12.4381 11.2051 13.125 10.2319 13.125H3.76788C2.79466 13.125 1.95673 12.4381 1.76586 11.4837L0.366995 4.48939ZM13.0608 4.37499H0.939L2.33787 11.3693C2.4742 12.051 3.07272 12.5417 3.76788 12.5417H10.2319C10.9271 12.5417 11.5256 12.051 11.6619 11.3693L13.0608 4.37499Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.66667 6.125C4.82775 6.125 4.95833 6.25558 4.95833 6.41667V7.58333C4.95833 7.74442 4.82775 7.875 4.66667 7.875C4.50558 7.875 4.375 7.74442 4.375 7.58333V6.41667C4.375 6.25558 4.50558 6.125 4.66667 6.125Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.33317 6.125C9.49425 6.125 9.62484 6.25558 9.62484 6.41667V7.58333C9.62484 7.74442 9.49425 7.875 9.33317 7.875C9.17209 7.875 9.0415 7.74442 9.0415 7.58333V6.41667C9.0415 6.25558 9.17209 6.125 9.33317 6.125Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
