import { defineStore } from 'pinia'
import VirtualViewportState from '@/globalState/VirtualViewportState'

interface ResizedSidebarState {
  isSidebarPositionUnderPageView: boolean
}

const storeContainer = document.getElementById('atlr-store')

const useResizedSidebar = defineStore('resizedSidebar', {
  state: (): ResizedSidebarState => ({ isSidebarPositionUnderPageView: false }),
  getters: {
    isSidebarResized: (state) => state.isSidebarPositionUnderPageView,
  },
  actions: {
    resizeSidebar() {
      this.isSidebarPositionUnderPageView = !this.isSidebarPositionUnderPageView
      if (VirtualViewportState.currentViewport === 'none' && storeContainer) {
        if (this.isSidebarPositionUnderPageView) {
          storeContainer.style.width = 'calc(100vw - 240px)'
          storeContainer.style.marginLeft = '0'
        } else {
          storeContainer.style.width = '100%'
        }
      }
    },
  },
})

export default useResizedSidebar
