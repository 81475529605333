<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="4" width="5" height="5" rx="1" :fill="`var(--${color})`" />
    <rect x="2" y="10" width="5" height="5" rx="1" :fill="`var(--${color})`" />
    <rect x="10" y="3" width="5" height="5" rx="1" :fill="`var(--${color})`" />
    <rect x="8" y="11" width="5" height="5" rx="1" :fill="`var(--${color})`" />
  </svg>
</template>
<script>
export default {
  name: 'ShiftedGridIcon',
  props: {
    color: {
      type: String,
      default: 'color-text-emphasized',
    },
  },
}
</script>
