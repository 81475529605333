import { AnyContextTextEditorItem } from '@/models/blockConfig/ContextTextEditor'
import DeleteButton from '../common/DeleteButton.vue'
import AlignInlineRadioInput from './AlignInlineRadioInput.vue'
import ColorSelect from './ColorSelect.vue'
import TransformInlineRadioInput from './TransformInlineRadioInput.vue'
import TypographyStyleSelect from './TypographyStyleSelect.vue'

export default function resolveSubeditor(editorSubtype: AnyContextTextEditorItem['type']) {
  if (editorSubtype === 'TypographyStyle') return TypographyStyleSelect
  if (editorSubtype === 'Color') return ColorSelect
  if (editorSubtype === 'Align') return AlignInlineRadioInput
  if (editorSubtype === 'Transform') return TransformInlineRadioInput
  if (editorSubtype === 'FlexDeleteElement') return DeleteButton
}
