<script>
export default {
  name: 'HorizontalAlignSpaceBetweenIcon',
  props: {
    color: {
      type: String,
      default: 'color-text-emphasized',
    },
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 15V2" :stroke="`var(--${color})`" stroke-linecap="round" />
    <path d="M16 15V2" :stroke="`var(--${color})`" stroke-linecap="round" />
    <rect x="8" y="4" width="2" height="9" :fill="`var(--${color})`" />
    <rect x="12" y="7" width="2" height="3" :fill="`var(--${color})`" />
    <rect x="4" y="7" width="2" height="3" :fill="`var(--${color})`" />
  </svg>
</template>
