import Coloris from '@melloware/coloris'
import '@melloware/coloris/dist/coloris.css'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import ApiService from './api/ApiService'
import AuthService, { AuthStatus } from './api/AuthService'
import StoreService from './api/StoreService'
import './css/global.scss'
import StoresState from './globalState/StoresState'
import router from './router'
import './storefrontBundle/main.css'
import './storefrontBundle/main.js'

function redirectToAuth() {
  window.location.replace('/login.html')
}

function initApiService() {
  ApiService.onError(401, redirectToAuth)
}

function initAuthService() {
  const authStatus = AuthService.init()
  if (authStatus !== AuthStatus.LOGGED_IN) redirectToAuth()
}

async function loadStores() {
  const stores = await StoreService.getStores()
  StoresState.setStores(stores)

  if (window.location.pathname === '/') {
    if (stores.length === 0) await router.push('/new-store')
    else await router.push(`/${stores[0].slug}/editor`)
  }
}

async function main() {
  initApiService()
  initAuthService()
  Coloris.init()
  createApp(App).use(createPinia()).use(router).mount('#atlr-editor-app')

  await loadStores()
}

main()
