import TeamMember from '@/models/storeSettings/TeamMember'
import ApiService from './ApiService'
import AuthService from './AuthService'

export default {
  async getTeam(storeId: string): Promise<TeamMember[]> {
    const accountId = AuthService.getAccountId() ?? ''

    const response = await ApiService.get(`/${storeId}/account_stores`, {
      headers: { 'account-id': accountId, 'store-id': storeId },
    })

    return response.data
  },

  async addTeamMember(storeId: string, email: string, role: string) {
    const accountId = AuthService.getAccountId() ?? ''

    await ApiService.post(
      `/${storeId}/account_stores`,
      {
        account_store: {
          email,
          role,
        },
      },
      {
        headers: { 'account-id': accountId, 'store-id': storeId },
      }
    )
  },

  async setTeamMemberRole(storeId: string, teamMemberId: string, role: string) {
    const accountId = AuthService.getAccountId() ?? ''

    await ApiService.patch(
      `/${storeId}/account_stores/${teamMemberId}`,
      {
        account_store: {
          role,
        },
      },
      {
        headers: { 'account-id': accountId, 'store-id': storeId },
      }
    )
  },

  async deleteTeamMember(storeId: string, teamMemberId: string) {
    const accountId = AuthService.getAccountId() ?? ''

    await ApiService.delete(`/${storeId}/account_stores/${teamMemberId}`, {
      headers: { 'account-id': accountId, 'store-id': storeId },
    })
  },
}
