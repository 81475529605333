<script>
export default {
  name: 'TabletIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.48 1.26001C3.62948 1.26001 2.94 1.94949 2.94 2.80001V10.64C2.94
    11.4905 3.62948 12.18 4.48 12.18H10.08C10.9305 12.18 11.62 11.4905 11.62 10.64V2.80001C11.62 1.94949 10.9305 1.26001
    10.08 1.26001H4.48ZM3.78 2.80001C3.78 2.41341 4.0934 2.10001 4.48 2.10001H10.08C10.4666 2.10001 10.78 2.41341 10.78
    2.80001V10.64C10.78 11.0266 10.4666 11.34 10.08 11.34H4.48C4.0934 11.34 3.78 11.0266 3.78 10.64V2.80001ZM7.28
    9.52001C6.97072 9.52001 6.72 9.77073 6.72 10.08C6.72 10.3893 6.97072 10.64 7.28 10.64C7.58928 10.64 7.84 10.3893
    7.84 10.08C7.84 9.77073 7.58928 9.52001 7.28 9.52001Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
