import { v4 as generateUuid } from 'uuid'
import { Component } from 'vue'
import { resolveEditorComponent } from '@/components/block-settings-drawer/settings-editors'
import { resolveContextEditorComponent } from '@/components/workspace/blockOverlay/contextEditors'
import { AnyObject, BlockConfig } from '@/global'
import BlocksCategory from '@/models/BlocksCategory'
import AnyContextEditor from '@/models/blockConfig/AnyContextEditor'
import AnyEditorOrContainer from '@/models/blockConfig/AnyEditorOrContainer'
import ContextSizeEditor from '@/models/blockConfig/ContextSizeEditor'
import ContextSnippetEditor from '@/models/blockConfig/ContextSnippetEditor'
import Block, { SectionName } from '@/models/editor/Block'
import ContextEditableElement from '@/models/editor/ContextEditableElement'
import InlineContextEditableElement from '@/models/editor/InlineContextEditableElement'

type EditorWithProps = { component: Component; props: AnyObject }

export default {
  transformConfigsToCategories(blockConfigs: BlockConfig[]) {
    const categories: BlocksCategory[] = []

    for (const blockConfig of blockConfigs) {
      const blockCategory = blockConfig.category ?? 'Unknown'
      const blockGroup = blockConfig.group ?? 'Unknown'
      const blockSid = blockConfig.sid ?? 'Unknown'

      let category = categories.find((item) => item.name === blockCategory)
      if (!category) {
        category = {
          name: blockCategory,
          groups: [],
          uuid: generateUuid(),
        }
        categories.push(category)
      }

      let group = category.groups.find((item) => item.name === blockGroup)
      if (!group) {
        group = {
          name: blockGroup,
          blocks: [],
          uuid: generateUuid(),
        }
        category.groups.push(group)
      }

      group.blocks.push({ sid: blockSid })
    }

    return categories
  },

  sort(blocks: Block[]) {
    const result: Block[] = []

    while (true) {
      const lastSortedBlockId = result[result.length - 1]?.id ?? null
      const nextBlock = blocks.find((item) => item.prev_block_id === lastSortedBlockId)
      if (nextBlock) result.push(nextBlock)
      else break
    }

    for (const block of blocks) {
      if (!result.includes(block)) result.push(block)
    }

    return result
  },

  getSettingValue(sectionValues: AnyObject | undefined, variable: string): string | number | object | undefined {
    if (!sectionValues) return

    const variableParts = variable.split('.')
    let parentObject = sectionValues

    for (let i = 0; i < variableParts.length - 1; i++) {
      const variablePart = variableParts[i]
      parentObject = parentObject[variablePart]
      if (!parentObject) return
    }

    return parentObject[variableParts[variableParts.length - 1]]
  },

  setSettingValue(sectionValues: AnyObject, variable: string, value: any) {
    const variableParts = variable.split('.')
    let parentObject = sectionValues

    for (let i = 0; i < variableParts.length - 1; i++) {
      const variablePart = variableParts[i]
      if (!parentObject[variablePart]) parentObject[variablePart] = {}
      parentObject = parentObject[variablePart]
    }

    parentObject[variableParts[variableParts.length - 1]] = value
  },

  getEditorsWithProps(
    block: Block,
    section: SectionName,
    editorConfigs: AnyEditorOrContainer[],
    sectionDefaults: AnyObject = {}
  ) {
    if (section === 'objects') section = 'options'
    const sectionValues = block[section]

    return editorConfigs.map((editorConfig) => {
      const component = resolveEditorComponent(editorConfig.type)

      const editingSettingValue =
        'variable' in editorConfig
          ? this.getSettingValue(sectionValues, editorConfig.variable) ??
            this.getSettingValue(sectionDefaults, editorConfig.variable)
          : undefined

      return {
        component,
        props: {
          editingSettingVariableName: editorConfig.name,
          editingSettingValue,
          editingBlock: block,
          editorConfig,
          section,
          sectionDefaults,
        },
      }
    })
  },

  getContextEditorWithProps(
    block: Block,
    blockConfig: BlockConfig | undefined,
    blockNode: HTMLElement,
    contextEditableElementNode: HTMLElement,
    editorConfig: AnyContextEditor,
    blocksDefaultOptions: AnyObject,
    blocksDefaultContent: AnyObject
  ) {
    const component = resolveContextEditorComponent(editorConfig.type)

    const editorWithProps: EditorWithProps = {
      component,
      props: {
        editorConfig,
        editingElementNode: contextEditableElementNode,
        editorType: editorConfig.type,
        blockNode,
        blockConfig,
        block,
      },
    }

    if ('section' in editorConfig && 'variable' in editorConfig) {
      const sectionValues = block[editorConfig.section]
      const sectionDefaults =
        editorConfig.section === 'options' ? blocksDefaultOptions[block.sid] : blocksDefaultContent[block.sid]

      editorWithProps.props.editingSettingSection = editorConfig.section
      editorWithProps.props.editingSettingVariableName = editorConfig.variable
      editorWithProps.props.editingSettingValue =
        this.getSettingValue(sectionValues, editorConfig.variable) ??
        this.getSettingValue(sectionDefaults, editorConfig.variable)
    }

    return editorWithProps
  },

  getContextEditorsWithProps(
    block: Block,
    blockConfig: BlockConfig | undefined,
    blockNode: HTMLElement,
    contextEditableElements: ContextEditableElement[],
    inlineContextEditableElements: InlineContextEditableElement[],
    blocksDefaultOptions: AnyObject,
    blocksDefaultContent: AnyObject
  ) {
    if (!blockConfig) return []

    const result: EditorWithProps[] = []

    for (const contextEditableElement of contextEditableElements) {
      for (const tag of contextEditableElement.tags) {
        const editorConfig = blockConfig?.contextEditors?.find((item) => item.tag === tag)
        if (!editorConfig) continue
        result.push(
          this.getContextEditorWithProps(
            block,
            blockConfig,
            blockNode,
            contextEditableElement.node,
            editorConfig,
            blocksDefaultOptions,
            blocksDefaultContent
          )
        )
      }
    }

    for (const inlineContextEditableElement of inlineContextEditableElements) {
      result.push(
        this.getContextEditorWithProps(
          block,
          blockConfig,
          blockNode,
          inlineContextEditableElement.node,
          inlineContextEditableElement.editor,
          blocksDefaultOptions,
          blocksDefaultContent
        )
      )
    }

    return result
  },

  getBlockContextEditablesWithTag(blockNode: HTMLElement, tag: string) {
    const contextEditableNodes = blockNode.querySelectorAll('[data-context-editable]')
    return Array.from(contextEditableNodes).filter((item) => {
      const tags = item.getAttribute('data-context-editable')
      if (!tags) return false

      const parsedTags = tags
        .split(',')
        .map((item) => item.trim())
        .filter(Boolean)

      return parsedTags.includes(tag)
    })
  },
}

export const unpackContextEditorsSnippets = (list: AnyContextEditor[] | ContextSnippetEditor[] | undefined) => {
  if (!list) return []

  return list.flatMap((item: any) => {
    const size = item as ContextSizeEditor
    const snippet = item as ContextSnippetEditor
    if (!snippet.snippet) return item

    const names: string[] = (snippet.name && (Array.isArray(snippet.name) ? snippet.name : [snippet.name])) || []
    const tags = (snippet.tag && (Array.isArray(snippet.tag) ? snippet.tag : [snippet.tag])) || []

    if (snippet.snippet === 'padding.all') {
      return names.flatMap((name) => [
        {
          type: 'SizeEditor',
          tag: name + 'Top',
          section: 'options',
          variable: name + 'Top',
          styleProperty: 'paddingTop',
          editorPosition: 'insideTop',
          extensionDirection: 'bottom',
          min: size.min || 0,
          max: size.max || 100,
        },
        {
          type: 'SizeEditor',
          tag: name + 'Bottom',
          section: 'options',
          variable: name + 'Bottom',
          styleProperty: 'paddingBottom',
          editorPosition: 'insideBottom',
          extensionDirection: 'bottom',
          min: size.min || 0,
          max: size.max || 100,
        },
        {
          type: 'SizeEditor',
          tag: name + 'Left',
          section: 'options',
          variable: name + 'Left',
          styleProperty: 'paddingLeft',
          editorPosition: 'insideLeft',
          extensionDirection: 'right',
          min: size.min || 0,
          max: size.max || 100,
        },
        {
          type: 'SizeEditor',
          tag: name + 'Right',
          section: 'options',
          variable: name + 'Right',
          styleProperty: 'paddingRight',
          editorPosition: 'insideRight',
          extensionDirection: 'left',
          min: size.min || 0,
          max: size.max || 100,
        },
      ])
    }

    if (snippet.snippet === 'padding.left') {
      return names.flatMap((name) => [
        {
          type: 'SizeEditor',
          tag: name + 'Left',
          section: 'options',
          variable: name + 'Left',
          styleProperty: 'paddingLeft',
          editorPosition: 'insideLeft',
          extensionDirection: 'right',
          min: size.min || 0,
          max: size.max || 100,
        },
      ])
    }

    if (snippet.snippet === 'padding.right.gap') {
      return names.flatMap((name) => [
        {
          type: 'SizeEditor',
          tag: name + 'Gap',
          section: 'options',
          variable: name + 'Gap',
          styleProperty: 'paddingRight',
          editorPosition: 'insideRight',
          extensionDirection: 'right',
          min: size.min || 0,
          max: size.max || 100,
        },
      ])
    }

    if (snippet.snippet.startsWith('padding.horizontal')) {
      const platforms = snippet.snippet.endsWith('.platforms') ? ['', 'Tablet', 'Mobile'] : ['']
      return names.flatMap((name) =>
        platforms.flatMap((platform) => [
          {
            type: 'SizeEditor',
            tag: name + 'Horizontal' + platform,
            section: 'options',
            variable: name + 'Horizontal' + platform,
            styleProperty: 'paddingHorizontal',
            editorPosition: 'insideRight',
            extensionDirection: 'left',
            min: size.min || 0,
            max: size.max || 100,
          },
          {
            type: 'SizeEditor',
            tag: name + 'Horizontal' + platform + 'Mirror',
            section: 'options',
            variable: name + 'Horizontal' + platform,
            styleProperty: 'paddingHorizontal',
            editorPosition: 'insideLeft',
            extensionDirection: 'right',
            min: size.min || 0,
            max: size.max || 100,
          },
        ])
      )
    }

    if (snippet.snippet === 'margin.bottom') {
      return names.flatMap((name) => [
        {
          type: 'SizeEditor',
          tag: name + 'Bottom',
          section: 'options',
          variable: name + 'Bottom',
          styleProperty: 'marginBottom',
          editorPosition: 'outsideBottom',
          extensionDirection: 'bottom',
          min: size.min || 0,
          max: size.max || 100,
        },
      ])
    }

    if (snippet.snippet === 'margin.top') {
      return names.flatMap((name) => [
        {
          type: 'SizeEditor',
          tag: name + 'Top',
          section: 'options',
          variable: name + 'Top',
          styleProperty: 'marginTop',
          editorPosition: 'outsideTop',
          extensionDirection: 'bottom',
          min: size.min || 0,
          max: size.max || 100,
        },
      ])
    }

    if (snippet.snippet === 'margin.all') {
      return names.flatMap((name) => [
        {
          type: 'SizeEditor',
          tag: name + 'Bottom',
          section: 'options',
          variable: name + 'Bottom',
          styleProperty: 'marginBottom',
          editorPosition: 'outsideBottom',
          extensionDirection: 'bottom',
          min: size.min || 0,
          max: size.max || 100,
        },
        {
          type: 'SizeEditor',
          tag: name + 'Top',
          section: 'options',
          variable: name + 'Top',
          styleProperty: 'marginTop',
          editorPosition: 'outsideTop',
          extensionDirection: 'bottom',
          min: size.min || 0,
          max: size.max || 100,
        },
        {
          type: 'SizeEditor',
          tag: name + 'Left',
          section: 'options',
          variable: name + 'Left',
          styleProperty: 'marginLeft',
          editorPosition: 'outsideLeft',
          extensionDirection: 'right',
          min: size.min || 0,
          max: size.max || 100,
        },
        {
          type: 'SizeEditor',
          tag: name + 'Right',
          section: 'options',
          variable: name + 'Right',
          styleProperty: 'marginRight',
          editorPosition: 'outsideRight',
          extensionDirection: 'left',
          min: size.min || 0,
          max: size.max || 100,
        },
      ])
    }

    if (snippet.snippet.startsWith('text.')) {
      return tags.flatMap((tag) => [
        {
          type: 'TextEditor',
          tag,
          items: snippet.snippet
            .split('.')
            .slice(1, 10)
            .map((preset) => getTextPreset(preset, tag))
            .filter((el) => el),
        },
      ])
    }

    return item
  }) as AnyContextEditor[]
}

const getTextPreset = (preset: string, tag: string) => {
  if (preset === 'typography') {
    return {
      type: 'TypographyStyle',
      section: 'options',
      variable: tag + 'Style.typographyStyleName',
    }
  }
  if (preset === 'color') {
    return {
      type: 'Color',
      section: 'options',
      variable: tag + 'Style.colorName',
    }
  }
  if (preset === 'transform') {
    return {
      type: 'Transform',
      section: 'options',
      variable: tag + 'Style.transform',
    }
  }
  if (preset === 'align') {
    return {
      type: 'Align',
      section: 'options',
      variable: tag + 'Alignment',
    }
  }
  if (preset === 'content') {
    return {
      type: 'Content',
      section: 'content',
      variable: tag,
    }
  }
  return null
}
