<script>
export default {
  name: 'CartIcon06',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 3.51391C0 3.19174 0.261167 2.93057 0.583333 2.93057H9.33333C9.6555 2.93057 9.91667 3.19174 9.91667 3.51391V12.2639C9.91667 12.5861 9.6555 12.8472 9.33333 12.8472H0.583333C0.261167 12.8472 0 12.5861 0 12.2639V3.51391ZM9.33333 3.51391H0.583333V12.2639H9.33333V3.51391Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.95868 0.611111C4.16155 0.611111 3.42553 0.897919 3.03004 1.62298L2.95319 1.76389C2.73835 2.15776 2.62534 2.7642 2.62534 3.21784L2.62533 2.91666C2.62533 3.08542 2.49474 3.22222 2.33366 3.22222C2.17258 3.22222 2.04199 3.08542 2.04199 2.91666L2.04201 3.21784C2.04201 2.65785 2.18151 1.9469 2.44671 1.46069L2.52357 1.31978C3.02292 0.404316 3.95221 0 4.95868 0C5.96514 0 6.89444 0.404316 7.39378 1.31978L7.47064 1.46069C7.73585 1.9469 7.87534 2.65785 7.87534 3.21784L7.87532 2.91666C7.87532 3.08542 7.74474 3.22222 7.58366 3.22222C7.42257 3.22222 7.29199 3.08542 7.29199 2.91666L7.29201 3.21784C7.29201 2.7642 7.17901 2.15776 6.96417 1.76389L6.88731 1.62298C6.49182 0.897919 5.75581 0.611111 4.95868 0.611111Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
