import { defineStore } from 'pinia'

interface FontSettingsState {
  uploadFormShowen: boolean
}

const useFontSettings = defineStore('fontSettings', {
  state: (): FontSettingsState => ({ uploadFormShowen: false }),
  getters: {},
  actions: {
    showUploadForm() {
      this.uploadFormShowen = true
    },
    hideUploadForm() {
      this.uploadFormShowen = false
    },
  },
})

export default useFontSettings
