import { Ref } from 'vue'

export default function useImageUploader(fileInput: Ref<HTMLInputElement | null>, cb: (image: File) => void) {
  const allowedTypes = process.env.VUE_APP_ALLOWED_IMAGE_TYPES

  const handleFiles = (files?: FileList | null) => {
    if (!files) return
    const allowedTypesList = allowedTypes.split(',')
    const file = files.item(0)

    if (!file) return
    if (!allowedTypesList.includes(file.type)) return

    cb(file)
  }

  const onFileInput = () => {
    if (!fileInput.value) return
    const files = fileInput.value.files
    handleFiles(files)
    fileInput.value.value = ''
  }

  const openFileChooseDialog = () => {
    fileInput.value?.click()
  }

  return {
    onFileInput,
    openFileChooseDialog,
    allowedTypes,
  }
}
