import { computed, reactive, Ref, ref } from 'vue'
import { StorefrontPage } from '@/global'
import Block from '@/models/editor/Block'
import BlockUtils from '@/util/data/BlockUtils'
import usePageService from '../api/usePageService'
import useModalQuestion from '../useModalQuestion'

export default function useBlocks(currentStoreId: Ref<string>, currentPage: Ref<StorefrontPage | null>) {
  const isAddBlockModalShown = ref(false)
  const defaultCategoryName: Ref<string | null> = ref(null)
  const defaultGroupName: Ref<string | null> = ref(null)
  const addingBlockSidQuestion = useModalQuestion<string>()

  const currentPageId = computed(() => currentPage.value?.id ?? '')

  const pageService = usePageService(currentStoreId, currentPageId)

  const addBlock = async (
    targetBlock: Block | null,
    position: 'before' | 'after',
    newDefaultCategoryName?: string,
    newDefaultGroupName?: string
  ) => {
    if (!currentPage.value) return

    defaultCategoryName.value = newDefaultCategoryName ?? null
    defaultGroupName.value = newDefaultGroupName ?? null

    isAddBlockModalShown.value = true
    const addingBlockSid = await addingBlockSidQuestion.ask()
    if (!addingBlockSid) return

    if (targetBlock === null) {
      return await pageService.addBlockOnPage(null, addingBlockSid)
    }

    const prevBlockId = targetBlock.prev_block_id
    const targetBlockId = targetBlock.id

    if (position == 'before') {
      return await pageService.addBlockOnPage(prevBlockId, addingBlockSid)
    }

    if (position === 'after') {
      return await pageService.addBlockOnPage(targetBlockId, addingBlockSid)
    }
  }

  const selectBlock = (blockSid: string) => {
    addingBlockSidQuestion.answer(blockSid)
  }

  const deleteBlock = async (targetBlock: Block) => {
    if (!currentPage.value) return
    return await pageService.deleteBlockFromPage(targetBlock.id)
  }

  const replaceBlock = async (targetBlock: Block, sid: string) => {
    targetBlock.sid = sid
    await pageService.updateBlockImmediate(targetBlock)
  }

  const closeAddBlockModal = () => {
    isAddBlockModalShown.value = false
  }

  const updateBlockContent = async (targetBlockId: string, variable: string, value: any) => {
    const targetBlock = currentPage.value?.blocks.find((item) => item.id === targetBlockId)
    if (!targetBlock) return
    BlockUtils.setSettingValue(targetBlock.content, variable, value)

    await pageService.requestBlockUpdate(targetBlock)
  }

  const updateBlockOptions = async (targetBlockId: string, variable: string, value: any) => {
    const targetBlock = currentPage.value?.blocks.find((item) => item.id === targetBlockId)
    if (!targetBlock) return
    BlockUtils.setSettingValue(targetBlock.options, variable, value)

    await pageService.requestBlockUpdate(targetBlock)
  }

  return reactive({
    addBlock,
    selectBlock,
    deleteBlock,
    replaceBlock,
    isAddBlockModalShown,
    closeAddBlockModal,
    updateBlockContent,
    updateBlockOptions,
    defaultCategoryName,
    defaultGroupName,
  })
}
