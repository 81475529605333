import AllViewportsPosition from '@/models/flex/AllViewportsPosition'

export default function checkIsPositionSameOnAllViewports(position?: AllViewportsPosition) {
  if (!position) return true
  if (
    position.desktop.horizontalOffset !== position.tablet.horizontalOffset ||
    position.tablet.horizontalOffset !== position.mobile.horizontalOffset
  ) {
    return false
  }

  if (
    position.desktop.verticalOffset !== position.tablet.verticalOffset ||
    position.tablet.verticalOffset !== position.mobile.verticalOffset
  ) {
    return false
  }

  return true
}
