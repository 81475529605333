<script>
export default {
  name: 'DescriptionPositionTopIcon',
  props: {
    color: String,
  },
}
</script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 13C2 12.4477 2.44772 12 3 12H15C15.5523 12 16 12.4477 16 13V15C16 15.5523 15.5523 16 15 16H3C2.44772 16 2 15.5523 2 15V13Z"
      :fill="`var(--${color})`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 3H3V10H15V3ZM3 2C2.44772 2 2 2.44772 2 3V10C2 10.5523 2.44772 11 3 11H15C15.5523 11 16 10.5523 16 10V3C16 2.44772 15.5523 2 15 2H3Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
