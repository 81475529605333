<script>
export default {
  name: 'GotoIcon',
  props: {
    color: String,
    size: {
      type: String,
      default: 'medium',
    },
  },
}
</script>

<template>
  <svg
    v-if="size === 'medium'"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.46967 1.46967C4.76256 1.17678 5.23744 1.17678 5.53033 1.46967L11.0607 7L5.53033 12.5303C5.23744 12.8232 4.76256 12.8232 4.46967 12.5303C4.17678 12.2374 4.17678 11.7626 4.46967 11.4697L8.93934 7L4.46967 2.53033C4.17678 2.23744 4.17678 1.76256 4.46967 1.46967Z"
      :fill="`var(--${color})`"
    />
  </svg>

  <svg v-else width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.19255 1.04973C3.40176 0.84052 3.74096 0.84052 3.95017 1.04973L7.9004 4.99996L3.95017 8.9502C3.74096 9.15941 3.40176 9.15941 3.19255 8.9502C2.98334 8.74099 2.98334 8.4018 3.19255 8.19259L6.38517 4.99996L3.19255 1.80734C2.98334 1.59813 2.98334 1.25894 3.19255 1.04973Z"
      :fill="`var(--${color})`"
    />
  </svg>
</template>
