import FontStyle from '@/models/fonts/FontStyle'
import FontWeight from '@/models/fonts/FontWeight'

export const fontWeightNames: { [key: number]: string } = {
  100: FontWeight.Thin,
  200: FontWeight.ExtraLight,
  300: FontWeight.Light,
  400: FontWeight.Regular,
  500: FontWeight.Medium,
  600: FontWeight.SemiBold,
  700: FontWeight.Bold,
  800: FontWeight.ExtraBold,
  900: FontWeight.Black,
}

export const fontWeightValues: { [key: string]: number } = {
  [FontWeight.Thin]: 100,
  [FontWeight.ExtraLight]: 200,
  [FontWeight.Light]: 300,
  [FontWeight.Regular]: 400,
  [FontWeight.Medium]: 500,
  [FontWeight.SemiBold]: 600,
  [FontWeight.Bold]: 700,
  [FontWeight.ExtraBold]: 800,
  [FontWeight.Black]: 900,
}

export const fontWeightOptions = Object.keys(FontWeight).map((key) => ({ name: key, value: key }))

export const fontStyleOptions = Object.keys(FontStyle).map((key) => ({ name: key, value: FontStyle[key] }))
